import React, { useEffect, useState } from "react";
import {
  Container,
  TextInput,
  Collapsible,
  CollapsibleItem,
  Icon,
} from "react-materialize";
import TopHeader from "../TopHeader";
import Banner from '../img/Handbook-header.jpg';
import MobileBanner from '../img/Handbook-header-mobile.jpg';
import Thumbnail1 from '../img/how to upload to Google drive.png';
import Thumbnail2 from '../img/how to submit videos.png';
import Thumbnail3 from '../img/timestamp comments.png';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import styles from './ClientHandbook.module.scss';
import './ClientHandbook.scss';
import { isMobile } from "../../api/Util";

function ClientHandbook() {
  const [searchQuery, setSearchQuery] = useState();
  const [videosList, setVideosList] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState();

  let videos = [
    {
      url: "587366859",
      title: "Uploading footage to us",
      thumbnail: Thumbnail1,
      description: "We provide free storage to our customers via google drive.",
    },
    {
      url: "641797810",
      title: "Submitting a new project",
      thumbnail: Thumbnail2,
      description: "Submitting and managing your projects is easy with our portal.",
    },
    {
      url: "735645330",
      title: "Leaving time stamped comments",
      thumbnail: Thumbnail3,
      description: "Our portal support timestamp comments so you can provide feedback to editor.",
    },
  ];

  useEffect(() => {
    const filterList = videos?.filter((item) =>
      item?.title?.toLowerCase()?.match(searchQuery?.toLowerCase())
    );
    setVideosList(filterList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    setVideosList(videos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className={styles.container}>
      <div className="aside-right-top-bar">
        <div className={`aside-right-top-bar ythelper-dashboard-topbar ${styles.innerContainer}`}>
          <div className={styles.imgDiv}>
            <img src={isMobile ? MobileBanner : Banner} alt="Banner" />
          </div>
          <div style={{ textAlign: 'center' }}>
          <div className={`top-bar-left ${styles.inputAndDivWrapper}`}>
            <div className="top_bar">
              <div className={`input-submit-btn ${styles.textInputDiv}`} style={{ maxWidth: '90%', marginLeft: "6%" }}>
                <TextInput
                  value={searchQuery}
                  placeholder={"Search Videos"}
                  onKeyPress={(e) => setSearchQuery(e.target.value)}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
          </div>
          </div>
          <div className="top-bar-right" style={{ position: 'absolute', top: '16px', right: 0 }}>
            <TopHeader />
          </div>
        </div>
      </div>
      <div className={`video-card-container ${styles.videoCardContainer}`}>
        <h5 className="headerText">Videos</h5>
        <ModalVideo channel='vimeo' autoplay isOpen={isOpen} videoId={currentVideoId} onClose={() => setOpen(false)} />
        <div className={styles.videoCardInnerContainer}>
          {videosList?.map((item, i) => {
            return (
                <div className={`custom-card ${styles.customCard}`} key={i}>
                  <img src={item.thumbnail} className="video-card-thumbnail" alt={`Thumbnail ${i+1}`} />
                  <h5 className="video-card-title">
                    {item.title}
                  </h5>
                  <p
                  className="video-card-description">
                    {item.description}
                  </p>
                  <button className="video-card-play-button" onClick={() => {setCurrentVideoId(item.url);setOpen(true)}}>
                    Watch Video
                  </button>
                </div>
            );
          })}
        </div>
      </div>
      <div className={`collapsible-container ${styles.collapsibleContainer}`}
        style={{ width: "100%", textAlign: "-webkit-center", marginTop: "8%" }}
      >
        <h5 className="headerText">FAQs</h5>
        <Collapsible accordion className={`custom-collapsible ${styles.collapsibleStyle}`}>
          <CollapsibleItem expanded={false}
            header={
              <div className={styles.iconAndTitleDiv}>
                <p>Cut off times</p>
                <span><Icon>expand_more</Icon></span>
              </div>
            } node="div">
            <ol type="1">
              <li>
                On a day a video is due it’s very possible you wake up to the
                completed edit in the morning, but you can expect the video back
                no later than 5pm EST that day at the latest. This is the cutoff
                we give our editors.
              </li>
              <li>
                The cutoff time for you to submit a video is 8pm EST. So what
                that means is that if you want a video back in two days then you
                must submit before that 8pm EST deadline. If your video is
                submitted after that it could get pushed to the next day.
              </li>
            </ol>
          </CollapsibleItem>
          <CollapsibleItem
            expanded={false}
            header={
              <div className={styles.iconAndTitleDiv}>
                <p>What we offer in repurposing</p>
                <span><Icon>expand_more</Icon></span>
              </div>
            }
            node="div"
          >
            <ul>
              <li className={styles.listStyle}>
                Resizing vs. Optimizing
                <ol type="1" style={{ fontWeight: "normal" }}>
                  <li>
                    Our repurposing does have some limits. We won’t be doing a
                    complete re-edit on these. We will take your videos and
                    resize them to fit for other social media platforms, add the
                    headlines, and create a good background.. We will be taking
                    your already completed video and cutting it up to your
                    liking, it’s great! Please make sure to include the time
                    markers you would like for the shorter, repurposed version.
                    I only mention this now because we can’t do complete
                    re-edits of the video. If you need extensive editing on
                    repurposed videos please reach out and we can upgrade your
                    account.
                  </li>
                  <li>
                    The sizes we recommend that you repurpose your videos to are
                    vertical (9:16), and square (1:1). This will allow you to
                    post to Tik Tok, IG Reels, YouTube shorts, Facebook,
                    LinkedIn, and Twitter. It’s a good idea to provide your
                    editor with a headline they can use on top that will catch
                    users attention and get them to watch the video. Try to
                    spark curiosity in that headline.
                  </li>
                </ol>
              </li>
              <li className={styles.listStyle}>
                Captions vs. Titles
              </li>
              <li>
                If you want captions on your repurposed videos you can sign up
                for that here for an additional $95/month. We will use tools
                like rev.com to caption your videos and will even have a human
                review them for accuracy. We are able to transcribe the video
                and import the .srt file and automatically burn them into your
                video. Sometimes people request captions that can only be done
                using titles. There is an example below. These are manually done
                word for word and are not included in the captioning plan
                mentioned above. If you need these types of titles please reach
                out to us (vidchops@gmail.com) and we will take a look at your
                request and see if you would like to upgrade your account for
                those.
              </li>
            </ul>
          </CollapsibleItem>
          <CollapsibleItem
            expanded={false}
            header={
              <div className={styles.iconAndTitleDiv}>
                <p>Common Client mistakes</p>
                <span><Icon>expand_more</Icon></span>
              </div>
            }
            node="div"
          >
            <ul>
              <li className={styles.listStyle}>Not reviewing their videos in a timely manner.</li>
                <li>
                  This will slow down your workflow. Please stay on top of
                  reviewing and make sure to hit the “Mark As Done Button” or
                  the “Request Revision” button when you receive your video
                  back.
                </li>
            </ul>
            <ul>
              <li className={styles.listStyle}>Not clicking the “Repurposing Button.”</li>
                <li>
                  If you don’t see your project in the “Done Column” it is in
                  the “Repurposing Column.” Make sure you check the box to
                  display that column.
                </li>
            </ul>
            <ul>
              <li className={styles.listStyle}>Not submitting your video request before 8pm est.</li>
                <li>
                  If you miss this deadline your video’s return will most likely
                  be pushed another day. Please plan accordingly.
                </li>
            </ul>
            <ul>
              <li className={styles.listStyle}>Not reviewing their videos quickly.</li>
                <li>
                  Be prompt when you receive the email notification that your
                  video is complete because missing just one day could push your
                  final delivery back as well. Once we deliver a video we are
                  waiting on you to approve it or ask for a revision.
                </li>
            </ul>
            <ul>
              <li className={styles.listStyle}>Not providing enough info on your branding etc.</li>
                <li>
                  When you first sign up you should spend some serious time
                  giving us everything we need to complete great edits. We need
                  more than just a few video examples. We want to know
                  everything about your brand. We’re looking for font names, hex
                  color codes, websites, background story, logo, etc. Please do
                  be diligent in providing us as much as possible.
                </li>
            </ul>
            <ul>
              <li className={styles.listStyle}>
                Not giving their editor grace in the first revisions. They will
                learn.
              </li>
                <li>
                  You should expect to have some revisions on the first few
                  videos. Your editor is learning all about your preferences. Be
                  good at giving feedback in these early stages. Your editor
                  will learn what you want and dial it in from there. Be
                  patient.
                </li>
            </ul>
          </CollapsibleItem>
        </Collapsible>
      </div>
    </Container>
  );
}

export default ClientHandbook;
