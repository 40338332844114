import React from 'react';
import { Icon } from 'react-materialize'; // Assuming you still want to use Material Icons
import { Tooltip } from 'antd';
import styles from '../../HomeStyle.module.scss';

const KanbanFilters = ({ onFilterClick, iconState, section, className }) => {
  
  const handleFilter = (type) => {
    onFilterClick(type); // Pass the filter type to the parent component
  };

  const getTooltip = (direction) => {
    const tooltipTexts = {
      video_request: 'Creation Time',
      editing: 'Editor Assigned Time',
      qa: 'QA Assigned Time',
      done: 'Done Time',
      on_hold: 'Creation Time',
      cancel: 'Creation Time',
      canceled: 'Creation Time',
      repurposeEditing: 'Done Time',
      repurpose_editing: 'Done Time',
      repurpose_done: 'Repurpose Done Time',
      updatedDone: 'Done Time',
      completed_done: 'Done Time',
    };

    const textDirection = tooltipTexts[section] || 'Time';
    return `${textDirection} (${direction === 'up' ? 'Asc' : 'Desc'})`;
  };

  return (
    <div className={`${styles[className]}`}>
      {iconState.reset && (
        <Tooltip title="Reset Filters">
          <span onClick={() => handleFilter('reset')}><Icon>refresh</Icon></span>
        </Tooltip>
      )}
      {iconState.up && (
        <Tooltip title={getTooltip('up')}>
          <span onClick={() => handleFilter('up')}><Icon>arrow_upward</Icon></span>
        </Tooltip>
      )}
      {iconState.down && (
        <Tooltip title={getTooltip('down')}>
          <span onClick={() => handleFilter('down')}><Icon>arrow_downward</Icon></span>
        </Tooltip>
      )}
    </div>
  );
};

export default KanbanFilters;
