import React, { useEffect, useState } from "react";
import { Layout, Card, Typography } from "antd";
import styles from "./ExportCardsStyle.module.scss";
import TopHeader from "../TopHeader/TopHeader";
import { Button } from "react-materialize";
import { exportCardBasedOnYear } from "../../services/cardsService";
import { errorToast, successToast } from "../../api/Util";
import { CSVLink } from "react-csv";
import CustomDropdown from "../common/CustomDropdown/DropDown";

const ExportCards = () => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedLimit, setSelectedLimit] = useState(null);
  const [cardsData, setCardsData] = useState([]);

  // Handle year dropdown change
  const handleYearChange = (value) => {
    setSelectedYear(value);
  };
  // Handle limit dropdown change
  const handleLimitChange = (value) => {
    setSelectedLimit(value);
  };

  // Handle Export button click
  const handleExportClick = async () => {
    const requestData = {
      year: selectedYear,
      limit: selectedLimit,
    };
    try {
      const response = await exportCardBasedOnYear(requestData);
      if (response.status) {
        const filteredData = response.data?.map((item) => ({
          "Card Title": item.card_title,
          "Card Status": item.card_status,
          "Qa Name": item.qaFirstName + " " + item.qaLastName,
          "Customer Name": item.customerFirstName + " " + item.customerLastName,
          "created Date": item.creation_time,
        }));
        setCardsData(filteredData);
        setSelectedLimit(null);
        successToast("Export successful");
      } else {
        errorToast("Something went wrong");
      }
    } catch (error) {
      console.error(error);
      errorToast("Export failed");
    }
  };

  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: 5 }, (_, index) => ({
    value: currentYear - index,
    label: `${currentYear - index}`,
  }));

  useEffect(() => {
    // Trigger the CSV download when cardsData is updated
    if (cardsData.length > 0) {
      const csvLink = document.getElementById("csv-download-link");
      if (csvLink) {
        csvLink.click();
      }
    }
  }, [cardsData]);

  return (
    <Layout className={styles.homeLayout}>
      <Card className={styles.topHeader} loading={false}>
        <TopHeader />
      </Card>
      <div className={styles.container}>
        <div className={styles.cardsTitleDiv}>
          <Typography.Title className={styles.cardsTitle}>
            Export Card
          </Typography.Title>
        </div>
        <div className={styles.filtersAndTotalCount}>
          <div className={styles.selectBoxWrapper}>
            <CustomDropdown
              options={yearOptions}
              placeholder={"Year"}
              className={styles.viewAsStyle}
              dropdownValue={selectedYear}
              handleOnchange={handleYearChange}
            />
            <CustomDropdown
              options={Array.from({ length: 10 }, (_, index) => ({
                value: 100 + index * 100,
                label: `${100 + index * 100}`,
              }))}
              placeholder={"Limit"}
              className={styles.viewAsStyle}
              dropdownValue={selectedLimit}
              handleOnchange={handleLimitChange}
            />
            <Button
              className={`standard-btn ${styles.exportButton}`}
              onClick={handleExportClick}
            >
              Export As Csv
            </Button>
            <div style={{ display: "none" }}>
              <CSVLink
                data={cardsData}
                filename={`exported_data_${selectedYear}.csv`}
                target="_blank"
                id="csv-download-link"
              >
                Export As Csv
              </CSVLink>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ExportCards;
