import React, { useEffect, useState } from 'react';
import { CollapsibleItem, Icon } from "react-materialize";
import RequestCard from "./RequestCard";
import EnableRepurpose from './EnableRepurpose/RepurposeModal';
import { axiosClient } from '../api/httpClient';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { toISODate } from '../api/Util';
import { Spin } from "antd";
import { Link } from 'react-router-dom';
import { SELECTED_CARD_SECTION } from '../actions/types';
import KanbanFilters from './Home/KanbanView/KanbanViewContent/KanbanFilters';
import ActivityStatusModal from './common/ActivityStatusModal';
import EnableYtHelper from './EnableYtHelper/EnableYtHelper';

export default (props) => {

    const [cardData, setCardData] = useState(props.cards || []);
    const [offset, setOffSet] = useState(10);
    const [hasMore, setHasMore] = useState(true);
    const [cardActivityModal, setCardActivityModal] = useState(false);
    const [cardRepurposeModal, setCardRepurposeModal] = useState(false);
    const [currentCardId, setCurrentCardId] = useState(null);
    const [cardActivityData, setCardActivityData] = useState([]);
    const [currentCardRepurpose, setCurrentCardRepurpose] = useState(true);
    const [CardYtHelperEnableModal, SetCardYtHelperEnableModal] = useState(false);
    const [updatedYtHelperStatus, setUpdatedYtHelperStatus] = useState(false);
    const [currentCardData, setCurrentCardData] = useState({});
    const viewType = localStorage.getItem("viewType");
    const [iconState, setIconState] = useState({
        up: false,
        down: true,
        reset: false,
    });
    const dispatch = useDispatch();
    useEffect(() => {
        setCardData(props.cards);
        setOffSet(10);
        if( props.cards && props.cards.length<8)
        {
            setHasMore(false);
        }
    }, [props.cards])

    let section = "";
    let dataSection = "";
    if (viewType !== "yt-helper") {
    if (props.name === "Video Requests") {
        section = "video_request"
        dataSection = "video_requests"
    }
    else if (props.name === "On Hold") {
        section = "on_hold"
        dataSection = "on_hold"
    }
    else if (props.name === "Editing") {
        section = "editing"
        dataSection = "editing"
    }
    else if (props.name === "Completed") {
        section = "updatedDone"
        dataSection = "updatedDone"
    }
    else if (props.name === "QA") {
        section = "qa"
        dataSection = "qa_cards"
    }
    else if (props.name === "Review") {
        section = "done"
        dataSection = "done_cards"
    }
    else if (props.name === "Canceled") {
        section = "canceled"
        dataSection = "cancelled_cards"
    }
    else if (props.name === "Repurposing Review") {
        section = "repurposeDone"
        dataSection = "repurposeDone"
    }
    else if (props.name === "Repurposing") {
        section = "repurposeEditing"
        dataSection = "repurposeEditing"
    } else {
        section = "video_request";
        dataSection = "video_requests";
    }
		} else {
			if (props.name === "Assigned") {
				section = "assigned"
				dataSection = "assigned"
			} else if (props.name === "In Progress") {
				section = "inProgress"
				dataSection = "inProgress"
            } else if (props.name === "QA") {
                section = "ytQA"
                dataSection = "ytQA"
            } else if (props.name === "Canceled") {
				section = "cancelled"
				dataSection = "cancelled"
			} else if (props.name === "Review") {
				section = "inDone"
				dataSection = "inDone"
			} else if (props.name === "Completed") {
				section = "completed"
				dataSection = "completed"
			}
		}

    const fetchMoreCards =()=>{
        let params = props.viewAs ? {
            view_as: props.viewAs
        } : {};
        let obj = {
            offset,
            limit:10,
            section,
            card_name: props.searchText ? props.searchText:"",
            viewType: viewType,
        }
        if(props.startDate && props.endDate)
        {
            obj = {
                ...obj,
                startDate:toISODate(props.startDate),
                endDate:toISODate(props.endDate),
                selectedDueDate:props.selectedDueDate
            }
        }
        let newOffset = offset+10;
            axiosClient.post("/api/getCards",{...params,...obj})
            .then(({data})=>{
                if(data[dataSection]?.length !== 0 )
                {
                    let allCardData = [...cardData ,...data[dataSection]];
                    setCardData(allCardData);
                    setOffSet(newOffset);
                }
                else{
                    setHasMore(false);
                }
            return;
            })
        return
    }


    const filterCardByDate =(dataFilter)=>{
        let params = props.viewAs ? {
            view_as: props.viewAs
        } : {};
        if (dataFilter) {
            params.dataFilter = dataFilter;
        }
        let obj = {
            offset:0,
            limit:10,
            section,
            card_name: props.searchText ? props.searchText:"",
            viewType: viewType,
        }
        if(props.startDate && props.endDate)
        {
            obj = {
                ...obj,
                startDate:toISODate(props.startDate),
                endDate:toISODate(props.endDate),
                selectedDueDate:props.selectedDueDate
            }
        }
        axiosClient.post("/api/getCards", { ...params, ...obj })
        .then(({ data }) => {
            if (data[dataSection] && data[dataSection].length !== 0) {
                setCardData(data[dataSection]);
            } else {
                setCardData([]);
            }
        })
        .catch(error => {
            console.error("Failed to fetch cards:", error);
            setCardData([]); 
        });
    }

    const handleFilterClick = (type) => {
        if (type === 'down') {
            setIconState({ up: true, down: false, reset: true });
            filterCardByDate('down'); // Pass 'down' as a parameter
        } else if (type === 'up') {
            setIconState({ up: false, down: true, reset: true });
            filterCardByDate('up'); // Pass 'up' as a parameter
        } else {
            setIconState({ up: false, down: true, reset: false });
            filterCardByDate(); // Call refresh without parameters
        }
    }
    return (
        <>
         <KanbanFilters iconState={iconState} onFilterClick={handleFilterClick} section={section} className='filtersIconKanban' />
            <CollapsibleItem
                className="cardList"
                expanded={true}
                header={props.name}
                icon={<Icon>arrow_drop_down</Icon>}
                node="div"
                onSelect={() => { }}
                style={{ overflowX: "scroll" }}
            >
                {(props?.queryParamsValue?.querySectionType 
                 ? props.type?.toLowerCase() === props?.queryParamsValue?.querySectionType && !cardData
                : !cardData) && (
                <div style={{ textAlign: "center", marginTop: "22px" }}>
                    <Spin />
                </div>
    )}
                {cardData && <InfiniteScroll
                    pageStart={0}
                    hasMore={hasMore}
                    useWindow={false}
                    loadMore={fetchMoreCards}
                >
                    <div className="drag-container" data-card-status={props.type} data-card-repurpose-status={props.rType}
                        data-ythelper-card-status = {props.ytType}
                        style={{ minHeight: "10vh", minWidth: "300px" }}>
                        {cardData && cardData.map((card, index) => {
                            return (
                                <div data-paused={card.paused} data-complete={card.is_complete} data-card-id={card.card_id}
                                    data-card-status={card.card_status} key={`card-${card.card_id}`}
                                    data-card-assigned={card.qa_assigned_to}
                                    data-card-repurpose-check={card?.repurpose_aspect}
                                    data-done-video-limit={ card?.done_video_limit ? card.done_video_limit : "1" }
                                    data-ythelper-card-status = {viewType === "yt-helper" ? (card.ytHelper_card_status ? card.ytHelper_card_status: props.ytType):null}
                                    data-card-repurpose-status={card.repurpose_status}
                                    data-index={index} data-revisions={card.revision_nr ? card.revision_nr : 0}>
                                            <Link className="linkTextStyle" to={{
                                                pathname: `/card-details/${card.card_id}`,
                                                state: { cardId: card.card_id }
                                            }} onClick={() => {
                                                    let cardSection;
                                                    if (props.type) {
                                                        cardSection = { cardType: "normal", section: props.type, markAsDone: 0 };
                                                    } else if (props.rType) {
                                                        cardSection = { cardType: "repurpose", section: props.rType, markAsDone: 0 };
                                                    }
                                                    dispatch({
                                                        type: SELECTED_CARD_SECTION,
                                                        payload: { status: 200, data: cardSection }
                                                    })
                                                }}>
                                                <RequestCard
                                                    setCardActivityModal={setCardActivityModal}
                                                    setCardActivityData={setCardActivityData}
                                                    viewCard={props.viewCard}
                                                    card={card}
                                                    section={props?.section}
                                                    setCardRepurposeModal={setCardRepurposeModal}
                                                    setCurrentCardId={setCurrentCardId}
                                                    currentCardRepurpose={currentCardRepurpose}
                                                    SetCardYtHelperEnableModal={SetCardYtHelperEnableModal}
                                                    CardYtHelperEnableModal={CardYtHelperEnableModal}
                                                    setCurrentCardData={setCurrentCardData}
                                                    updatedYtHelperStatus={updatedYtHelperStatus}
                                                    />
                                            </Link>
                                </div>
                            );
                        })}
                    </div>
                </InfiniteScroll>}
            </CollapsibleItem>
               <ActivityStatusModal cardActivityModal={cardActivityModal} setCardActivityModal={setCardActivityModal} cardActivityData={cardActivityData}/>
                <EnableRepurpose  cardRepurposeModal={cardRepurposeModal} setCardRepurposeModal={setCardRepurposeModal} currentCardId={currentCardId} setCurrentCardRepurpose={setCurrentCardRepurpose} />
                <EnableYtHelper SetCardYtHelperEnableModal={SetCardYtHelperEnableModal} CardYtHelperEnableModal={CardYtHelperEnableModal} currentCardData={currentCardData} setUpdatedYtHelperStatus={setUpdatedYtHelperStatus} />
        </>
    );
};
