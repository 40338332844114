import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Container,
  Row,
  TextInput,
  Icon,
} from "react-materialize";
import styles from "./Settings.module.scss";
import "./Settings.scss";
import {
  fetchHoliday,
  saveNewHoliday,
  updateHolidayStatus,
} from "../../services/holidayService"; // Assume these services exist
import { errorToast, successToast } from "../../api/Util";
import HolidayTable from "../HolidayTable/HolidayTable";

const SETTINGS_DEFAULT = {
  holiday_title: "",
  holiday_date: "",
  holiday_status: true,
  is_deleted: false,
};

const Holiday = () => {
  const [state, setState] = useState(SETTINGS_DEFAULT);
  const [holidayData, setHolidayData] = useState([]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const saveHolidays = async () => {
    const isDateExists = holidayData.some(holiday => holiday.holiday_date === state.holiday_date);
    if (isDateExists) {
      errorToast("Holiday with this date already exists.");
      return;
    }
    const response = await saveNewHoliday(state);
    if (response.status === 200) {
      successToast("Data saved successfully");
      fetchHolidays(); // Refetch holidays to include the new one
      setState(SETTINGS_DEFAULT); // Reset form
    }  else {
      errorToast(response.error || "Some Error Occurred");
    }
  };

  useEffect(() => {
    fetchHolidays();
  }, []);

  const fetchHolidays = async () => {
    const response = await fetchHoliday();
    if (response.status === 200) {
      setHolidayData(response?.data);
    } else {
      errorToast(response.error || "Some Error Occurred");
    }
  };

  const onDeleteHoliday = async (holiday) => {
    let params = {
      id: holiday.id,
      is_deleted: !holiday.is_deleted,
    };
    const response = await updateHolidayStatus(params);
    if (response.status === 200) {
      fetchHolidays();
      successToast("Holiday deleted successfully");
    } else {
      errorToast("Failed to delete holiday");
    }
  };

  const onToggleHolidayStatus = async (holiday) => {
    let params = {
      id: holiday.id,
      holiday_status: !holiday.holiday_status,
    };
    const response = await updateHolidayStatus(params);
    if (response.status === 200) {
      successToast("Holiday status updated successfully");
      fetchHolidays();
    } else {
      errorToast("Failed to update holiday status");
    }
  };

  return (
    <div>
      <div className={styles.holidayForm}>
      <Card title="Add Holiday" className={`${styles.holidayCard} holidayCardTitle`}>
        <Container>
          <Row className={styles.holidayRowContainer}>
            <div
              style={{
                display: "flex",
                alignItems: "stretch",
                flexWrap: "wrap",
              }}
            >
              <div className={styles.headingStyle}>
                <TextInput
                  s={12}
                  id="txt_bh"
                  name="holiday_title"
                  label="Title"
                  onChange={onChange}
                  value={state.holiday_title}
                />
              </div>
              <TextInput
                  s={12}
                  name="holiday_date"
                  label="Holiday Date"
                  type="date"
                  onChange={onChange}
                  value={state.holiday_date}
                  placeholder="yyyy-mm-dd"
                />
              <div className={styles.buttonStyle}>
                <Button
                  key="save-btn"
                  className="btn-primary"
                  icon={<Icon left>save</Icon>}
                  onClick={saveHolidays}
                  disabled={!state.holiday_date || !state.holiday_title}
                >
                  Save
                </Button>
              </div>
            </div>
          </Row>
        </Container>
      </Card>
      </div>
      <div className={styles.holidayTable}>
      <Card className="holidayListTable">
        <HolidayTable
          holidayData={holidayData}
          onDeleteHoliday={onDeleteHoliday}
          onToggleHolidayStatus={onToggleHolidayStatus}
        />
      </Card>
      </div>
    </div>
  );
};

export default Holiday;
