import React from "react";
import styles from "./PrivacyPolicy.module.scss";
import Logo from "../img/vidchops-black.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function PrivacyHeader() {
  return (
    <div className={styles.headerContainer}>
      <Link to="/">
        <img src={Logo} alt="Vidchops" className="vidchops-logo" />
      </Link>
    </div>
  );
}

export default PrivacyHeader;
