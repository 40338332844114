import React from "react";
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
import { storeAccessToken } from "../../services/YTTokenService";
import { errorToast, successToast } from "../../api/Util";
import styles from './CustomLoginButton.module.scss';
import GoogleButton from 'react-google-button'
function CustomLoginButton({ showGoogleLogin, setShowGoogleLogin, setYTData, setIsLoading }) {
  const saveJWTData = async (obj) => {
    setIsLoading(true);
    const data = await storeAccessToken(obj);
    if (data.status) {
        successToast("Logged In Successfully");
        setYTData(data?.data?.analytics);
        setIsLoading(false);
    } else {
        errorToast(data.error);
        setIsLoading(false);
    }
  }

  const login = useGoogleLogin({
    
    onSuccess: async codeResponse => {
        const data = `code=${encodeURIComponent(codeResponse.code)}&client_id=${process.env.REACT_APP_CLIENT_ID}&client_secret=${process.env.REACT_APP_CLIENT_SECRET}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&grant_type=authorization_code`;
        try {
          const response = await axios.post(process.env.REACT_APP_TOKEN_URL, data, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          });
          if (response.data.access_token) {
            setShowGoogleLogin(false);
          }
          saveJWTData(response.data);
        } catch (error) {
          console.error(error);
        }
    },
    flow: 'auth-code',
    scope: [
      "https://www.googleapis.com/auth/youtube.readonly",
    ]
   });
  return (
        <GoogleButton
        disabled={!showGoogleLogin} onClick={() => login()}  className={`${styles.loginButton} btn-primary`}
      />
  );
}

export default CustomLoginButton;
