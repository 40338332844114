import Login from "./Login";
import Signup from "./Signup";
import PasswordReset from './PasswordReset';
import VerifyEmail from './VerifyEmail';
import PrivacyPolicy from "./PrivacyPolicy/privacyPolicy";

const PublicRoutes = [
    { path: '/login', component: Login },
    { path: '/signup', component: Signup },
    { path: '/password-reset', component: PasswordReset },
    { path: '/verify-email', component: VerifyEmail },
    { path: '/privacy-policy', component: PrivacyPolicy },  
];


export default PublicRoutes;
