export const FETCH_USER = 'fetch_user';
export const FETCHING_USER = 'fetching_user';
export const UNSEEN_INBOX_MESSAGE = 'unseen_inbox_message';
export const USER_LOGOUT = 'user_logout';
export const TOKEN_EXPIRED = 'token_expired';

export const CARDS_FETCHING = 'cards_fetching';
export const CARDS_FETCHED = 'cards_fetched';

export const FETCHING_ALL_USERS = 'fetching_all_users';
export const FETCH_ALL_USERS = 'fetch_all_users';
export const ADD_USER = 'add_user';
export const ADD_CUSTOMER = 'add_customer';
export const REMOVE_USER = 'remove_user';
export const REMOVE_CUSTOMER = 'remove_customer';
export const FETCH_ALL_CUSTOMERS = 'fetch_all_customers';
export const FETCHING_ALL_CUSTOMERS = 'fetching_all_customers';
export const FETCH_FILTER_DATA = 'fetch_filter_data';

export const FETCHING_SETTINGS = 'fetching_settings';
export const FETCH_SETTINGS = 'fetch_settings';
export const UPDATE_SETTINGS = 'update_settings';

export const YTHELPER_CARDS_FETCHING = 'ythelper_cards_fetching';
export const YTHELPER_CARDS_FETCHED = 'ythelper_cards_fetched';

export const FETCHING_NOTIFICATION_COUNT = 'fetching_notification_count';
export const FETCH_NOTIFICATION_COUNT = 'fetch_notification_count';
export const FETCHING_NOTIFICATION_LIST = 'fetching_notification_list';
export const FETCH_NOTIFICATION_LIST = 'fetch_notification_list';

export const SELECTED_CARD_SECTION = "selected_card_section";
export const SEARCH_USER = "search_user";

export const FETCH_WEBSITE_USER = 'fetch_website_user';
export const CLEAR_CARD_SECTIONS = 'clear_card_sections';
