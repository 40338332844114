import React, {useState, useEffect} from 'react'
import { 
    Container,
    Row,
    ProgressBar,
    Collapsible 
} from 'react-materialize';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { 
    YT_HELPER_ASSINED,
    YT_HELPER_IN_PROGRESS,
    YT_HELPER_IN_DONE,
    YT_HELPER_SECTION,
    YT_HELPER_CANCELLED
} from '../api/Constants';
import CardList from './CardList';
import * as _ from 'lodash';
import PubSub from 'pubsub-js';
import {useLocation} from "react-router-dom";
import CardModal from './CardModal';
import {CARD_CLICKED} from "../api/Events";
import DragNDrop from './DragNDrop';
import { decodeBase64, isAdmin } from '../api/Util';

const YtHelperDashboard = (props) => {
    const { showCanceled, searchText, viewAs, setSearchText } = props;
    const [key, setKey] = useState(1);
    const [viewCardOpen, setViewCardOpen] = useState(false);
    const [viewCardId, setViewCardId] = useState(0);
    const loggedInUser = props.auth?.loggedInUser || {};
    const location = useLocation();
    const admin = isAdmin(loggedInUser);

    const showCard = (cardId) => {
        setViewCardId(cardId);
        setViewCardOpen(true);
    }

    const searchCards = () => {
        let obj = {
            offset: 0,
            limit: 10,
            section: "",
            card_name: searchText ? searchText : "",
        }
        props.fetchYtHelperCards(obj, viewAs);
    }

    const refresh = (user) => {
        setSearchText("");
        const obj = {
            offset: 0,
            limit: 10,
            section: "",
            card_name: "",
        }
        props.fetchYtHelperCards(obj, user || viewAs);
    }
   

    useEffect(() => {
        searchCards();
    }, [props?.viewAs, props.searchText])


    useEffect(() => {
        if (props?.location?.search) {
            let encodeCardId = props.history.location.search.slice("?cardId=".length);
            let cardId = decodeBase64(encodeCardId);
            showCard(cardId);
        }
    }, [props?.location?.search])

    useEffect(() => {
        let cardId = location.hash?.replace(/#card-/i, "");
        if (cardId && (props?.cards?.cardMap || {})[cardId]) {
            showCard(cardId);
        }
    }, [props.cards.cardMap, location.hash]);

    useEffect(() => {
        const subToken = PubSub.subscribe(CARD_CLICKED, (msg, data) => showCard(data));

        if (_.isEmpty(props.cards?.ytHelperCardList)) {
            refresh();
        }
        return () => PubSub.unsubscribe(subToken);
    }, [viewAs, admin])

    return (
        <Container>
            <DragNDrop loggedInUser={loggedInUser} refresh={refresh} setKey={setKey} />
            <CardModal cardId={viewCardId} isOpen={viewCardOpen} onClose={setViewCardOpen.bind(this, false)} refresh={refresh} section={YT_HELPER_SECTION}
                qas={props.users.qas} users={props.users.users} me={loggedInUser} />
            {props.cards.loading ? <Row><ProgressBar /></Row> : ""}
            <div className="ythelper-main">
                <Row style={{overflowX: "scroll", marginBottom: "0"}}>
                    <table className="cards-panel">
                        <tbody>
                            <tr>
                                <td width="25%">
                                    <Collapsible className="assigned-card-list" accordion>
                                        <CardList name="Assigned" cards={props.cards?.ytHelperCardList?.assigne_card} section={YT_HELPER_SECTION}
                                            viewCard={showCard} key={"vr-" + key} searchText={searchText} viewAs={viewAs}
                                            ytType={YT_HELPER_ASSINED} />
                                    </Collapsible>
                                </td>
                                {showCanceled && (
                                    <td width="25%">
                                        <Collapsible className="canceled-list" accordion>
                                            <CardList name="Cancelled" cards={props.cards?.ytHelperCardList?.cancelled} section={YT_HELPER_SECTION}
                                                viewCard={showCard} key={"vr-" + key} searchText={searchText} viewAs={viewAs}
                                                ytType={YT_HELPER_CANCELLED} />
                                        </Collapsible>
                                    </td>
                                )}
                                <td width="25%">
                                    <Collapsible className="in-progress-card-list" accordion>
                                        <CardList name="In Progress" cards={props.cards?.ytHelperCardList?.inProgress} section={YT_HELPER_SECTION}
                                            viewCard={showCard} key={"vr-" + key} searchText={searchText} viewAs={viewAs}
                                            ytType={YT_HELPER_IN_PROGRESS} />
                                    </Collapsible>
                                </td>
                                <td width="25%">
                                    <Collapsible className="done-list" accordion>
                                        <CardList name="Done" cards={props.cards?.ytHelperCardList?.inDone} section={YT_HELPER_SECTION}
                                            viewCard={showCard} key={"vr-" + key} searchText={searchText} viewAs={viewAs}
                                            ytType={YT_HELPER_IN_DONE} />
                                    </Collapsible>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
            </div>
        </Container>
    );
};

function mapStateToProps({ cards, auth, users, settings }) {
    return { cards, auth, users, settings };
}

export default connect(mapStateToProps, actions)(YtHelperDashboard);
