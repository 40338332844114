import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-materialize";
import { Typography, Avatar, Tooltip } from 'antd';
import { axiosClient } from "../../../../../api/httpClient";
import styles from './ChatBoxStyle.module.scss';
import './ChatBoxStyle.scss';

import {
	convertLocalToServerTime,
	convertServerToLocalTime,
	convertTimeToDateTimeFormat,
	errorToast,
	infoToast,
	isMobile,
	parseSqlDateTime,
	successToast,
	warningToast
} from "../../../../../api/Util";
import * as _ from 'lodash';
import useInterval from "../../../../../hooks/UseInterval";
import Profile from '../../../../img/profile.png';

import { ADMIN, CUSTOMER, QA, TEAM_LEAD, YT_HELPER, YT_HELPER_LEAD, YT_HELPER_IN_DONE, YT_HELPER_SECTION } from "../../../../../api/Constants";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import moment from 'moment';
import Select from "react-select";
import ConfirmationDialog from "../../../../ConfirmationDialog";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ChatSection from "../../../../common/ChatSection/ChatSection";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const reactStringReplace = require('react-string-replace');

const INITIAL_STATE = {
	me: {},
	cardId: null,
	lastUpdated: 0,
	comments: {},
	commentsArray: [],
}
const INITIAL_REPLY_STATE = {
	comment: "",
	showReplyField: false,
	parentId: null,
	oldComment: "",
}

const INITIAL_COMMENT_STATE = {
	id: 0,
	data: "",
	oldComment: "",
}

const REGEX_ADMIN = /(@[aA][dD][mM][iI][nN])/g;
const REGEX_CLIENT = /(@[cC][lL][iI][eE][nN][tT])/g;
const REGEX_EDITOR = /(@[eE][dD][iI][tT][oO][rR])/g;
const REGEX_QA = /(@[qQ][aA])/g;
const REGEX_YTHELPER = /(@[yY][tT][hH][eE][lL][pP][eE][rR])/g;
const REGEX_YTTEAMLEAD = /(@[yY][tT][tT][eE][aA][mM][lL][eE][aA][dD])/g;

function makeLinks(text) {
	return reactStringReplace(text, /(https?:\/\/[^ ]*)/g, (match, i) => (
		<strong><a key={match + i} className="comment-link" href={match} target="_blank" rel="noopener noreferrer">{match}</a></strong>
	));
}

function highlightTags(text) {
	let replacedText = reactStringReplace(text, REGEX_ADMIN, (match, i) => (
		<span key={match + i} className="comment-tag"><strong>{match}</strong></span>
	));
	replacedText = reactStringReplace(replacedText, REGEX_CLIENT, (match, i) => (
		<span key={match + i} className="comment-tag"><strong>{match}</strong></span>
	));
	replacedText = reactStringReplace(replacedText, REGEX_EDITOR, (match, i) => (
		<span key={match + i} className="comment-tag"><strong>{match}</strong></span>
	));
	replacedText = reactStringReplace(replacedText, REGEX_QA, (match, i) => (
		<span key={match + i} className="comment-tag"><strong>{match}</strong></span>
	));
	replacedText = reactStringReplace(replacedText, REGEX_YTHELPER, (match, i) => (
		<span key={match + i} className="comment-tag"><strong>{match}</strong></span>
	));
	replacedText = reactStringReplace(replacedText, REGEX_YTTEAMLEAD, (match, i) => (
		<span key={match + i} className="comment-tag"><strong>{match}</strong></span>
	));
	return replacedText;
}

function parseVideoTime(text, onClick) {
	return reactStringReplace(text || "", /^@VT{(.+)}/i, (match, i) => {
		let time = Math.floor(match);
		let hours = Math.floor(time / 3600);
		let minutes = Math.floor((time % 3600) / 60);
		let seconds = Math.floor(time % 60);

		return (
			<span key={match + time} style={{ cursor: "pointer", textDecoration: "underline", color: "#ff7700" }} onClick={onClick.bind(this, time)}>
				<strong>
					{hours ? `${hours}:`.padStart(2, "0") : ""}{minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}
				</strong>
			</span>
		);
	});
}

function uniqueComments(comments) {
	return _.uniqBy(comments, 'note_id');
}
function uniqueAnnotations(annotations) {
	return _.uniqBy(annotations, 'id');
}

export default (props) => {
	const [state, setState] = useState({ ...INITIAL_STATE, me: props.me, cardId: props.cardId });
	const [comment, setComment] = useState(INITIAL_COMMENT_STATE);

	const [disableBtn, setDisableBtn] = useState(false);

	useInterval(() => loopLoadComments("intervalCalled"), props.poll ? (process.env.REACT_APP_COMMENT_REFRESH_TIMEOUT || 10000) : null);

	const video = document.querySelector('#preview-vid');
	const [chatView, setChatView] = useState({ label: "Customer Chat", value: "customer" });
	const [teamState, setTeamState] = useState({ lastUpdated: 0, chats: {}, chatsArray: [] });
	const [viewChatOf, setViewChatOf] = useState({ label: "All", value: "all" });
	// const [noComments, setNoComments] = useState(false);
	const [roleOptions, setRoleOptions] = useState([]);

	const [deleteConfirmation, setDeleteConfirmation] = useState({ open: false, comment: {} });

	const [offset, setOffset] = useState(0);
	const [callLoadFunc, setCallLoadFunc] = useState(false);

	const [replyState, setReplyState] = useState(INITIAL_REPLY_STATE);
	const [isLoading, setIsLoading] = useState(false);
	const viewType = props.section === YT_HELPER_SECTION ? "yt-helper" : "normal";

	useEffect(() => {
		// eslint-disable-next-line no-mixed-operators
		if ((props.videoCount > 0 && props.showVideoChat) || (props.videoCount === 0 && props.cardId)) {
			setTeamState({ lastUpdated: 0, chats: {}, chatsArray: [] });
			setState({ ...INITIAL_STATE, me: props.me, cardId: props.cardId });
			setOffset(0);
			setCallLoadFunc(true);
		} else if (viewType === "yt-helper" || props.section === "editCard") {
			setComment(INITIAL_COMMENT_STATE);
			setOffset(0);
			setCallLoadFunc(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.cardId, props.showVideoChat, chatView, viewChatOf]);

	useEffect(() => {
		if (callLoadFunc) {
			loopLoadComments('scrollCalled');
		}
		if (props?.callChatApi) {
			loopLoadComments("intervalCalled");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [callLoadFunc, props?.callChatApi])

	useEffect(() => {
		if (props && props.card?.card_id) {
			let option = [
				{ label: "All", value: "all" }, { label: "Admin", value: ADMIN }, { label: "Team Lead", value: TEAM_LEAD },
				{ label: "QA", value: QA }
			];
			if (props.card.yt_helper_id) {
				option = [...option, { label: "YouTube Team Lead", value: YT_HELPER_LEAD }, { label: "YouTube Helper", value: YT_HELPER }]
			}
			if (chatView.value === "customer") {
				option = [...option, { label: "Customer", value: CUSTOMER }]
			}
			setRoleOptions(option);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.card?.card_id, chatView])

	function loopLoadComments(calledBy = "scrollCalled", apiRefresh = false) {
		setIsLoading(true);
		let chatApi = "";

		if (chatView.value === "team") {
			chatApi = `/api/card/${props.cardId}/team/commentsV2`;
		} else {
			if (viewType && viewType === "yt-helper") {
				chatApi = `/api/ythelper/card/${props.cardId}/commentsV2`;
			} else {
				chatApi = `/api/card/${props.cardId}/commentsV2`;
			}
		}

		let obj = {
			since: convertTimeToDateTimeFormat(new Date(chatView.value === "team" ? teamState.lastUpdated : state.lastUpdated)),
			order: "desc",
			filterChatOf: viewChatOf.value,
			offset,
			calledBy,
			note_for_video_number: props?.showVideoChat ? props.showVideoChat : '1',
		}

		setCallLoadFunc(false);
		props.setAnnotationStates && props.setAnnotationStates(prev => ({
			...prev,
			callChatApi: false
		}));

		if (apiRefresh) {
			obj = {
				since: convertLocalToServerTime(new Date()),
				order: "desc",
				filterChatOf: viewChatOf.value,
				offset: 0,
				calledBy: "scrollCalled"
			}
		}

		axiosClient.get(chatApi, {
			params: chatView.value === "team"
				? { ...obj, chat_for: (viewType === "yt-helper" ? "ytTeamChat" : "editorTeamChat") }
				: obj
		}).then((response) => {
			setIsLoading(false);
			const data = response.data.comments.groupByChat;
			const commentArray = response.data.comments.comments;
			const respCalledBy = response.data.calledBy;
			let annotations = [];
			if (_.isEmpty(data)) {
				// setNoComments(true);
				return;
			}

			if (offset === 0) {
				annotations = response.data?.annotations?.comments;
			}

			setOffset(offset + commentArray.length);
			let mostRecentTime = Math.max(chatView.value === "team" ? teamState.lastUpdated : state.lastUpdated, parseSqlDateTime(commentArray[0]?.original_db_date).getTime());
			chatView.value === "team" ?
				setTeamState(prev => ({
					...prev,
					// chats: (respCalledBy === "scrollCalled" ? { ...prev.chats, ...data } : { ...data, ...prev.chats }),
					chats: updateCommentGroups(respCalledBy, prev.chats, data),
					chatsArray: uniqueComments(respCalledBy === "scrollCalled" ? prev.chatsArray.concat(commentArray) : commentArray.concat(prev.chatsArray)),
					lastUpdated: mostRecentTime
				}))
				:
				 setState(prev => ({
					...prev,
					comments: updateCommentGroups(respCalledBy, prev.comments, data),
					commentsArray: uniqueComments(respCalledBy === "scrollCalled" ? prev.commentsArray.concat(commentArray) : commentArray.concat(prev.commentsArray)),
					lastUpdated: mostRecentTime
				}));

			if (viewType === "yt-helper") {
				const tempAnno = (annotations && annotations.length !== 0 ? annotations : commentArray).filter(note => note.note_type && note.note_type === "annotation" && props?.showVideoChat === note.note_for_video_number  ).map(note => {
					const { geometry, data } = JSON.parse(note.note);
					return {
						geometry,
						data: {
							...data,
							// id:note.note_id
						},
						id: note.note_id
					}
				});
				props.setAnnotationStates && props.setAnnotationStates(prev => ({
					...prev,
					annotation: {},
					annotations: uniqueAnnotations(props?.annotations.concat(tempAnno))
				}));
			}
		}).catch(err => {
			warningToast("Unable to refresh comments");
			console.error(err);
		});
	}

	function updateCommentGroups(type, prevComments, newComments) {
		// (respCalledBy === "scrollCalled" ? { ...prev.comments, ...data } : { ...data, ...prev.comments })
		let newTempComments = {};
		
		if (Object.keys(prevComments).length === 0) {
			newTempComments = newComments;
		} else {
			if (type === "scrollCalled") {
				newTempComments = mergeTwoObjects(prevComments, newComments);
			} else {
				newTempComments = mergeTwoObjects(newComments, prevComments);
			}
		}

		return newTempComments;
	}

	function mergeTwoObjects(object1, object2) {
		for (let x in object1) {
			for (let y in object2) {
				if (x === y) {
					object1[x] = object1[x].concat(object2[y]);
					delete(object2[y]);
				}
			}
		}

		if (Object.keys(object2).length > 0) {
			for (let y in object2) {
				object1[y] = object2[y];
			}
		}

		return object1;
	}

	function send(calledBy = 'main') {
		let note = calledBy === "reply" ? replyState.comment : comment.data;

		if (!note || note === '') {
			return;
		}
		if (disableBtn) {
			return infoToast("A new comment request has been already made for this comment");
		}


		if (video && video.duration) {
			note = `@VT{${video.currentTime}} ${note}`;
		}

		if (viewType === "yt-helper" && props.card.ytHelper_card_status === YT_HELPER_IN_DONE
			&& state.me.client_type === CUSTOMER) {
			note = `@YT_CUS_CMNT{} ${note}`;
		}

		infoToast("Creating a new comment request");
		setDisableBtn(true);

		let chatApi = "";

		if (chatView.value === "team") {
			chatApi = `/api/card/${props.cardId}/team/comment`;
		} else {
			if (viewType && viewType === "yt-helper") {
				chatApi = `/api/ythelper/card/${props.cardId}/comment`;
			} else {
				chatApi = `/api/card/${props.cardId}/comment`;
			}
		}

		let obj = {
			card_id: props.cardId,
			note: note,
			note_for_video_number: props?.showVideoChat ? props?.showVideoChat : '1',
		};

		if (calledBy === "reply") {
			obj = {
				...obj,
				noteParentId: replyState.parentId,
			}
		}

		axiosClient.post(chatApi,
			chatView.value === "team" ? { ...obj, chat_for: (viewType === "yt-helper" ? "ytTeamChat" : "editorTeamChat") }
				: obj
		).then(({ data }) => {
			let localServerTime = convertLocalToServerTime(new Date());
			let mostRecentTimeWhileAdd = Math.max(chatView.value === "team" ? teamState.lastUpdated : state.lastUpdated, parseSqlDateTime(localServerTime).getTime());
			let newConvertedDateTime = convertServerToLocalTime(localServerTime);
			let checkDate = moment(newConvertedDateTime).format("YYYY-MM-DD");
			let updatedData = {
				note_id: data.id,
				note: note,
				date_added: newConvertedDateTime,
				note_by_id: state.me.client_id,
				firstname: state.me.firstname,
				lastname: state.me.lastname
			};

			if (calledBy === "reply") {
				updatedData.note_parent_id = replyState.parentId;
			} else {
				updatedData.is_deleted = 0;
				updatedData.child = [];
			}

			chatView.value === "team" ?
				setTeamState(prev => {
					if (calledBy === "reply") {
						let chats = teamState.chats;
						chats = filterComments(chats, updatedData, "reply");
						loopLoadComments("scrollCalled", true);
						return { ...prev, chats: chats };
					}	else {
						let chats = teamState.chats;
						let flag = false;
						// eslint-disable-next-line no-unused-expressions
						Object.keys(chats)?.map(objKey => {
							if (objKey === checkDate) {
								chats[objKey].unshift(updatedData);
								flag = true;
							}
						})
						if (!flag) {
							chats = Object.assign({ [checkDate]: [updatedData] }, chats);
						}
						return { ...prev, lastUpdated: mostRecentTimeWhileAdd, chats: chats };
					}
				})
				:
				setState(prev => {
					if (calledBy === "reply") {
						let comments = state.comments;
						comments = filterComments(comments, updatedData, "reply");
						loopLoadComments("scrollCalled", true);
						return { ...prev, comments: comments };
					} else {
						let comments = state.comments;
						let flag = false;
						// eslint-disable-next-line no-unused-expressions
						Object.keys(comments)?.map(objKey => {
							if (objKey === checkDate) {
								comments[objKey].unshift(updatedData);
								flag = true;
							}
						})
						if (!flag) {
							comments = Object.assign({ [checkDate]: [updatedData] }, comments);
						}
						return { ...prev, lastUpdated: mostRecentTimeWhileAdd, comments: comments };
					}
				});
			setComment(INITIAL_COMMENT_STATE);
			setReplyState(INITIAL_REPLY_STATE);
			successToast("Comment is added successfully");
		}).catch(err => {
			console.error(err);
			errorToast("Unable to post comment, try again later");
		})
			.finally(() => {
				setDisableBtn(false);
			});
	}

	function editComment() {
		let note = comment.data;
		let oldNote = comment.oldComment;
		let noteId = comment.id;

		if (!note || note === '') {
			return;
		}
		if (disableBtn) {
			return infoToast("A new edit comment request has been already made for this comment");
		}


		if (oldNote && oldNote.includes('@VT{') === true) {
			let videoTime = oldNote.match(/^@VT{(.+)}/i);
			if (videoTime && videoTime.length === 2) {
				note = `@VT{${videoTime[1]}} ${note}`;
			}
		}

		if (oldNote && oldNote.includes('@YT_CUS_CMNT{}') === true) {
			note = `@YT_CUS_CMNT{} ${note}`;
		}

		infoToast("Creating a new edit comment request");
		setDisableBtn(true);

		let obj = {
			cardId: props.cardId,
			note: note,
			noteId: noteId,

		}
		if (chatView.value === "team") {
			obj.chatFor = "teamChatComment";
		} else if (viewType && viewType === "yt-helper") {
			obj.chatFor = 'ytClientChat';
		} else {
			obj.chatFor = 'clientChat';
		}

		axiosClient.put(`/api/card/${props.cardId}/comment/${noteId}`, obj)
			.then(({ data }) => {
				if (data.status) {
					if (chatView.value === "team") {
						let chats = teamState.chats;
						chats = filterComments(chats, { noteId: noteId, note: note }, "edit");
						setTeamState(prev => ({
							...prev,
							chats: chats
						}));
					}	else {
						let comments = state.comments;
						comments = filterComments(comments, { noteId: noteId, note: note }, "edit");
						setState(prev => ({
							...prev,
							comments: comments
						}));
					}
					setComment(INITIAL_COMMENT_STATE);
					successToast(data.message ?? "Comment edited successfully");
				}	else {
					errorToast("Unable to edit comment, try again later");
				}
			}).catch(err => {
				console.error(err);
				errorToast("Unable to edit comment, try again later");
			}).finally(() => {
				setDisableBtn(false);
			});
	}

	function onSeek(time) {
		let video = document.querySelector('#preview-vid');
		if (video) {
			video.currentTime = time;
			video.play();
		}
	}

	// when user press enter the comment will send
	function keyPressHandler(e, calledBy = 'main') {
		if (!e.shiftKey && e.key === 'Enter') {
			e.preventDefault();
			if (replyState.showReplyField) {
				send('reply');
			} else {
				if (comment.id > 0) {
					editComment();
				} else {
					send(calledBy);
				}
			}
		}
		e.persist();
	}

	function markCommentDone(comment) {
		let commonCommentApi = `/api/card/${props.cardId}/markCommentComplete`;
		let ytHelperCommentApi = `/api/ythelper/card/${props.cardId}/markCommentComplete`;

		axiosClient.post(viewType === "yt-helper" ? ytHelperCommentApi : commonCommentApi, {
			"status": 1,
			"note_id": comment.note_id,
			"type": chatView.value,
		}).then(({ data }) => {
			if (data.message) {
				successToast(data.message);
				if (chatView.value === 'team') {
					let chats = teamState.chats;
					chats = filterComments(chats, comment, "mark-done");
					setTeamState(prev => ({
						...prev,
						chats: chats
					}));
				} else {
					let comments = state.comments;
					comments = filterComments(comments, comment, "mark-done");
					setState(prev => ({
						...prev,
						comments: comments
					}));
				}
			}
		}).catch(err => {
			console.error(err);
			errorToast("Unable to mark comment, please try again later");
		})
	}
	function setBreakNewLines(text) {
		return reactStringReplace(text, "\n", (match, i) => (
			// return reactStringReplace(text, /(\\r?\\n)/g, (match, i) => (
			<br key={match + i} />
		));
	}

	// for removing the code which is used for yt helper section card's comment mark as done functionality 
	function removeYtCustomerCode(comment) {
		if (comment.search("@YT_CUS_CMNT{}") !== -1) {
			return comment.slice(15);
		}
		return comment;
	}

	function removeVideoTime(text) {
		return text.replace(/^@VT{(.+)}/i, '');
	}

	function handleDeleteComment(comment) {
		if (!comment) {
			return;
		}

		let obj = {
			card_id: props.cardId,
			note_id: comment.note_id,
			delete_note: 1
		}
		let chatApi = "";

		if (chatView.value === "team") {
			chatApi = `/api/card/${props.cardId}/team/deleteComment`;
		}
		else {
			chatApi = `/api/card/${props.cardId}/deleteComment`;
		}

		axiosClient.post(chatApi,
			viewType === "yt-helper" && chatView !== "team" ? { ...obj, section: YT_HELPER_SECTION }
				: obj
		)
			.then(({ data }) => {
				successToast(data.message);
				setOffset(0);
				props.setAnnotationStates && props.setAnnotationStates(prev => ({
					...prev,
					annotation: {},
					annotations: [],
					activeAnnotations: []
				}));
				setCallLoadFunc(true);
				chatView.value === "team" ? setTeamState(prev => ({ ...prev, lastUpdated: 0, chats: [] }))
					: setState(prev => ({ ...prev, lastUpdated: 0, comments: [] }));
			})
			.catch(err => {
				console.error(err);
			})
	}

	function noteReaction(comment) {
		document.getElementById('thumbs-up').style.color = comment.note_reaction ? 'black' : 'red';
		let commonCommentApi = `/api/card/${props.cardId}/noteReaction`;
		let ytHelperCommentApi = `/api/ythelper/card/${props.cardId}/noteReaction`;

		axiosClient.post(viewType === "yt-helper" ? ytHelperCommentApi : commonCommentApi, {
			"status": comment.note_reaction ? 0 : 1,
			"note_id": comment.note_id,
			"type": chatView.value,
			"section": viewType === "yt-helper" ? 'ytHelperChat' : 'editorChat',
		}).then(({ data }) => {
			if (data.message) {
				successToast(data.message);
				let comments = state.comments;
				comments = filterComments(comments, comment, "note-reaction");
				setState(prev => ({
					...prev,
					comments: comments
				}));
			}
		}).catch(err => {
			console.error(err);
			errorToast("Unable to React on comment, please try again later");
		})
	}

	function handleChat(chat) {
		setChatView(chat);
		setOffset(0);
		setViewChatOf({ label: "All", value: "all" });
	}

	function filterComments(comments, newComment, type) {
		let flag = false;
		// eslint-disable-next-line no-unused-expressions
		Object.keys(comments)?.map(objKey => {
			if (type === "reply" && !flag) {
				// eslint-disable-next-line no-unused-expressions
				let result = comments[objKey]?.filter(cmnt => cmnt.note_id === newComment.note_parent_id);
				if (result.length > 0) {
					flag = true;
					comments[objKey].unshift(newComment);
				}
			} else if (type === "mark-done") {
				// eslint-disable-next-line no-unused-expressions
				comments[objKey]?.filter(comment => comment.note_id === newComment.note_id).map(comment => comment.is_note_complete = 1);
			} else if (type === "note-reaction") {
				// eslint-disable-next-line no-unused-expressions
				comments[objKey]?.filter(comment => comment.note_id === newComment.note_id).map(comment => comment.note_reaction = 1);
			} else if (type === "edit") {
				// eslint-disable-next-line no-unused-expressions
				comments[objKey]?.filter(comment => comment.note_id === newComment.noteId).map(comment => comment.note = newComment.note);
			}
		})
		return comments;
	}

	const mapComments = (comments, commentsArray) => {
		const uniqueComments = {};
		Object.keys(comments).forEach(objKey => {
			comments[objKey].forEach(cmnt => {
				if (!uniqueComments[cmnt.note_id]) {
					uniqueComments[cmnt.note_id] = { ...cmnt, objKey };
				}
			});
		});
		const sortedComments = Object.values(uniqueComments).sort((a, b) => new Date(b.date_added) - new Date(a.date_added));
		const groupedComments = sortedComments.reduce((acc, cmnt) => {
			const dateKey = cmnt.objKey;
			if (!acc[dateKey]) {
				acc[dateKey] = [];
			}
			acc[dateKey].push(cmnt);
			return acc;
		}, {});
		return Object.keys(groupedComments).map(objKey => (
			groupedComments[objKey].map((cmnt, key) => {	
				let deletedCustomerMessage = cmnt.client_type === "customer" && cmnt.is_deleted === 1 && props.me.client_type === ADMIN;
				if (cmnt.is_deleted === 0 || (deletedCustomerMessage)) {
					let myComment = cmnt.note_by_id === state.me.client_id;
					let annotationData;
					if (cmnt.note_type && cmnt.note_type === "annotation") {
						annotationData = JSON.parse(cmnt.note);
					}
					let temp = commentsArray?.find(element => element?.note_id === cmnt?.note_parent_id);
					return (
						<div className={{ display: 'flex', width: '100%' }}>
							{
								key === 0 &&
								<div className={styles.chatDateDiv}>
									<span className={styles.chatDate}>{new Date(cmnt?.objKey).getDate()} {moment(cmnt?.objKey).format('MMM')} {new Date(cmnt?.objKey).getFullYear()}</span>
								</div>
							}
							<div className={styles.optionAndCommentContainer}>
								<div className={myComment ? styles.rowWrapRight : `${styles.rowWrapLeft} ${deletedCustomerMessage ? styles.lightGreyColor : ""}`}>
									<div>
										{!myComment && cmnt.profile_img ?
											<img src={Profile} alt="avatar"
												style={{ pointerEvents: 'none', userSelect: "none", verticalAlign: "middle", width: "28px", height: "28px", borderRadius: "50%", marginTop: "2.9rem" }} />
											: <Avatar style={{ backgroundColor: '#f56a00', marginTop: '1.8rem', width: "28px", height: "28px", fontSize: "12px" }}>
												{cmnt.firstname?.toUpperCase().charAt(0)}{cmnt.lastname?.toUpperCase().charAt(0)}
											</Avatar>
										}
									</div>
									<div className={styles.commentAndTimeContainer}>
										{!myComment && <span className={styles.clientName}><b>{cmnt.firstname} {cmnt.lastname}</b></span>}
										<div className={styles.commentMessage}>
											<div className={`${styles.chatMessage} ${cmnt?.not_replied && props?.me?.client_type !== CUSTOMER && styles.chatMessageForNotReplied}`}>
												{temp?.note && <>
													<FontAwesomeIcon style={{ color: '#616C76', marginRight: '4px' }} icon={faReply} />
													<span>Replied on {removeVideoTime(removeYtCustomerCode(temp.note)).substring(0, 15) + '...'}</span><br />
												</>}
												{
													annotationData && annotationData?.data !== "" ? annotationData.data.text :
														makeLinks(setBreakNewLines(highlightTags(parseVideoTime(removeYtCustomerCode(cmnt.note), onSeek))))
												}
												<div className={styles.editAndDeleteIconWrapper}>
													{
														(!cmnt.note_type && viewType !== "yt-helper" && props.me.client_type !== CUSTOMER && !deletedCustomerMessage) ?
															<Tooltip placement="bottom" title={cmnt.is_note_complete ? "Marked Done" : "Mark as Done"}>
																<FontAwesomeIcon className={styles.markAsDoneIcon} icon="check"
																	color={cmnt.is_note_complete ? "green" : "grey"} size="1x" onClick={() => markCommentDone(cmnt)} />
															</Tooltip> : null
													}
													{
														cmnt.client_type === CUSTOMER && !deletedCustomerMessage ?
															<Tooltip placement="bottom" title={cmnt.note_reaction ? "Reaction Added" : "Click to React"}>
																<FontAwesomeIcon style={{ color: cmnt.note_reaction ? 'red' : 'grey' }}
																	className={styles.thumbsUpIcon} id="thumbs-up" icon={faThumbsUp} onClick={() => noteReaction(cmnt)} />
															</Tooltip> : null
													}
													{!deletedCustomerMessage &&
														<Tooltip placement="bottom" title={'Reply'}>
															<FontAwesomeIcon className={styles.replyIcon}
																onClick={() => {
																	document.getElementById("submitBtn").focus();
																	setReplyState(prev => ({ ...prev, showReplyField: true, parentId: cmnt.note_id, oldComment: removeVideoTime(removeYtCustomerCode(cmnt.note)) }));
																}} icon={faReply} />
														</Tooltip>
													}
													{
														(props.me.client_id === cmnt.note_by_id || (props.me.client_type === ADMIN && cmnt.client_type !== CUSTOMER)) ?
															<>
																<Tooltip placement="bottom" title={'Edit'}>
																	<EditOutlined key={'comment-edit-key-id-' + cmnt.note_id}
																		onClick={() => {
																			let comment = cmnt.note;
																			comment = removeVideoTime(removeYtCustomerCode(cmnt.note));
																			setComment({ id: cmnt.note_id, data: comment, oldComment: cmnt.note });
																		}} className={styles.editIcon} />
																</Tooltip>
																<Tooltip placement="bottom" title={'Delete'}>
																	<DeleteOutlined key={'comment-delete-key-id-' + cmnt.note_id}
																		onClick={() => setDeleteConfirmation({ open: true, comment: cmnt })} className={styles.deleteIcon} />
																</Tooltip>
															</>
															: null
													}
													{viewType === "yt-helper" &&
														cmnt.note_type === "annotation" ?
														<Tooltip placement="bottom" title={'Annotation'}>
															<span className="annotation-label-colored-circle" />
														</Tooltip>
														: <Tooltip placement="bottom" title={'Comment'}>
															<span className="yt-chat-label-colored-circle" />
														</Tooltip>
													}
												</div>
											</div>
											{/* <p>It's more important to me that you get started on the NEXT videos</p> */}
											<span>{moment(cmnt.date_added).format('LT')}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					)
				}
			})
		));
	}

	return (
		<Container>
			<ConfirmationDialog
				onNegative={() => { setDeleteConfirmation({ open: false }) }}
				onPositive={() => handleDeleteComment(deleteConfirmation.comment)}
				confirmationHeader="Confirm"
				confirmationDialogId={"confirm-move"}
				confirmation={deleteConfirmation.open}
				confirmationText={"Are you sure you want to delete this comment?"}
			/>
			<Row className={`chat-header-row ${styles.chatHeaderRow}`}>
				<div>
					<Typography.Title level={3} className={styles.cardTitle} style={{ marginBlockStart: viewType === "yt-helper" ? 0 : "1em" }}>
					 Revision Comments
					</Typography.Title>
					<Typography.Paragraph className={styles.ChatDescription} style={{ marginBlockStart: viewType === "yt-helper" ? 0 : "1em" }}>
					  If you have any major concerns or queries non-related to this particular video. Please send us a message in your Client 
					  <Link to={"/inbox"}> Inbox </Link>
					</Typography.Paragraph>
				</div>
				<div className={styles.btnsAndSelectBoxOuterWrapper}>
					<div className={styles.btnsAndSelectBoxWrapper}>
						{
							props?.me?.client_type !== CUSTOMER &&
							<div className={styles.customerTeamChatBtnContainer}>
								<Button type="primary" size={'large'} className={styles.customerChatBtn}
									disabled={chatView.value === 'customer' ? true : false}
									onClick={() => handleChat({ label: "Customer Chat", value: "customer" })}>
									{isMobile ? "Customer" : "Customer Chat"}
								</Button>
								<Button type="primary" size={'large'} className={styles.teamChatBtn}
									disabled={chatView.value === 'team' ? true : false}
									onClick={() => handleChat({ label: "Team Chat", value: "team" })}>
									{isMobile ? "Team" : "Team Chat"}
								</Button>
							</div>
						}
						<div className={`${styles.selectUserType} chat-select-video`}>
							<Select
								value={viewChatOf}
								options={roleOptions}
								isSearchable={false}
								onChange={(e) => {
									setOffset(0);
									setViewChatOf(e);
									chatView.value === "team" ?
										setTeamState(prev => ({ ...prev, lastUpdated: 0, chats: [] }))
										:
										setState(prev => ({ ...prev, lastUpdated: 0, comments: [] }));
								}
								}
							/>
						</div>
					</div>
				</div>
				{/* <Col className={"chat-annotation-label"}>
                    
                </Col> */}
				<Col s={12}>
					<div className={`chat-annotation-label ${styles.chatAnnotationLabel}`}>
						{
							viewType === "yt-helper" ?
								<label>
									<span className="annotation-label-colored-circle" /><span className="annotation-span"> Annotation </span>
									<span className="yt-chat-label-colored-circle" /><span className="chat-span"> Comment </span>
								</label> : null
						}
					</div>
				</Col>
			</Row>
			<ChatSection
				state={state}
				teamState={teamState}
				chatView={chatView}
				loopLoadComments={loopLoadComments}
				mapComments={mapComments}
				isLoading={isLoading}
				replyState={replyState}
				setReplyState={setReplyState}
				INITIAL_REPLY_STATE={INITIAL_REPLY_STATE}
				comment={comment}
				setComment={setComment}
				video={video}
				keyPressHandler={keyPressHandler}
				editComment={editComment}
				section={props.section}
				send={send}
				disableBtn={disableBtn}
				hasMore={true}
				chatSectionType={"commentChat"}
				allowNewChat={props?.allowNewChat}
			/>
		</Container>
	);
}
