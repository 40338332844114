import RequireLogin from './RequireLogin';
import Home from './Home/Home';
import CardDetails from './CardDetails/CardDetails';
import NewVideoRequest from './NewVideoRequest/NewVideoRequest';
import Main from './Main';
import AllUsers from './AllUsers';
import AllCustomers from './AllCustomers';
import UserProfile from './UserProfile';
import CustomerProfile from './CustomerProfile';
import Settings from './Settings/Settings';
import Profile from './Profile/Profile';
import Archived from './Archived';
import Dashboard from './Dashboard/Dashboard';
import YtHelperDashboard from './YtHelperDashboard';
import UploadVideo from './UploadVideo';
import Notification from './Notification/Notification';
import ClientHandbook from './ClientHandbook/ClientHandbook';
import Inbox from './Inbox/Inbox';
import Rating from './Rating/rating';
import DueCards from './DueCards/DueCards';
import ExportCards from './ExportCards/ExportCards';
import AIToolbox from "./AIToolbox/AIToolbox";

const routes = [
  { path: '/', component: RequireLogin(Home) },
  { path: '/card-details/:cardId', component: RequireLogin(CardDetails) },
  { path: '/new-card', component: RequireLogin(NewVideoRequest) },
  { path: '/cards', component: RequireLogin(Main) },
  { path: '/customer/index', component: RequireLogin(Main) },
  { path: '/users', component: RequireLogin(AllUsers) },
  { path: '/customers', component: RequireLogin(AllCustomers) },
  { path: '/user/:userId/profile', component: RequireLogin(UserProfile) },
  { path: '/customer/:userId/profile', component: RequireLogin(CustomerProfile) },
  { path: '/settings', component: RequireLogin(Settings) },
  { path: '/profile', component: RequireLogin(Profile) },
  { path: '/cards/archive', component: RequireLogin(Archived) },
  { path: '/dashboard', component: RequireLogin(Dashboard) },
  { path: '/ythelper/dashboard', component: RequireLogin(YtHelperDashboard) },
  { path: '/upload', component: RequireLogin(UploadVideo) },
  { path: '/notifications', component: RequireLogin(Notification) },
  { path: '/client-handbook', component: RequireLogin(ClientHandbook) },
  { path: '/inbox', component: RequireLogin(Inbox) },
  { path: '/rating', component: RequireLogin(Rating) },
  { path: '/dueCards', component: RequireLogin(DueCards) },
  { path: '/exportCards', component: RequireLogin(ExportCards) },
  { path: '/AIToolbox', component:RequireLogin(AIToolbox) },

];

export default routes;
