export const addHeaderOfTab = (tab) => {
    switch (tab) {
        case 'video_request':
            return 'Video Requests';
        case 'on_hold':
            return 'On Hold';
        case 'editing':
            return 'Editing';
        case 'qa':
            return 'QA';
        case 'ytQA':
            return 'QA'  
        case 'done':
            return 'Review';
        case 'canceled':
            return 'Canceled';
        case 'repurpose_editing':
            return 'Repurposing';
        case 'repurpose_done':
            return 'Repurposing Review'
        case "assigned":
            return 'Assigned';
        case "assigne_card":
            return 'Assigned';
        case 'inProgress':
            return 'In Progress';
        case 'inDone':
            return 'In Done';
        case 'cancelled':
            return 'Cancelled';
        case 'completed_done':
            return 'Completed';
        case 'completed':
            return 'Completed';     
        default:
            return "Cards";
    }
}

export const addCardStatus = (tab) => {
    switch (tab) {
        case 'video_request':
            return 'Yet to be Started';
        case 'on_hold':
            return 'Hold';
        case 'editing':
            return 'In Progress';
        case 'qa':
            return 'In QA';
        case 'done':
            return 'Completed';
        case 'canceled':
            return 'Canceled';
        case 'repurpose_editing':
            return 'In Repurpose';
        case 'repurpose_done':
            return 'Repurpose Completed'
        case 'completed_done':
             return 'completed_done';    
        default:
            return "In Progress";
    }
}

export function getAdminPermission(activeTab) {
    switch(activeTab) {
        case 'video_request':
            return [
                {
                    label: 'On Hold',
                    key: 'on_hold',
                },
                {
                    label: 'Editing',
                    key: 'editing',
                },
                {
                    label: 'QA',
                    key: 'qa',
                },
                {
                    label: 'Done',
                    key: 'done',
                },
                {
                    label: 'Canceled',
                    key: 'canceled',
                },
                {
                    label: 'Repurpose Editing',
                    key: 'repurpose_editing',
                },
                {
                    label: 'Repurpose Done',
                    key: 'repurpose_done',
                },
            ];
        case 'on_hold':
            return [
                {
                    label: 'Video Request',
                    key: 'video_request',
                },
                {
                    label: 'Editing',
                    key: 'editing',
                },
                {
                    label: 'QA',
                    key: 'qa',
                },
                {
                    label: 'Done',
                    key: 'done',
                },
                {
                    label: 'Canceled',
                    key: 'canceled',
                },
                {
                    label: 'Repurpose Editing',
                    key: 'repurpose_editing',
                },
                {
                    label: 'Repurpose Done',
                    key: 'repurpose_done',
                },
            ];
        case 'editing':
            return [
                {
                    label: 'Video Request',
                    key: 'video_request',
                },
                {
                    label: 'On Hold',
                    key: 'on_hold',
                },
                {
                    label: 'QA',
                    key: 'qa',
                },
                {
                    label: 'Done',
                    key: 'done',
                },
                {
                    label: 'Canceled',
                    key: 'canceled',
                },
                {
                    label: 'Repurpose Editing',
                    key: 'repurpose_editing',
                },
                {
                    label: 'Repurpose Done',
                    key: 'repurpose_done',
                },
            ];
        case 'qa':
            return [
                {
                    label: 'Video Request',
                    key: 'video_request',
                },
                {
                    label: 'On Hold',
                    key: 'on_hold',
                },
                {
                    label: 'Editing',
                    key: 'editing',
                },
                {
                    label: 'Done',
                    key: 'done',
                },
                {
                    label: 'Canceled',
                    key: 'canceled',
                },
                {
                    label: 'Repurpose Editing',
                    key: 'repurpose_editing',
                },
                {
                    label: 'Repurpose Done',
                    key: 'repurpose_done',
                },
            ];
        case 'done':
            return [
                {
                    label: 'Video Request',
                    key: 'video_request',
                },
                {
                    label: 'On Hold',
                    key: 'on_hold',
                },
                {
                    label: 'Editing',
                    key: 'editing',
                },
                {
                    label: 'QA',
                    key: 'qa',
                },
                {
                    label: 'Canceled',
                    key: 'canceled',
                },
                {
                    label: 'Repurpose Editing',
                    key: 'repurpose_editing',
                },
                {
                    label: 'Repurpose Done',
                    key: 'repurpose_done',
                },
                {
                    label: 'Completed',
                    key: 'completed_done',
                }
            ];
        case 'repurpose_editing':
            return [
                {
                    label: 'Video Request',
                    key: 'video_request',
                },
                {
                    label: 'On Hold',
                    key: 'on_hold',
                },
                {
                    label: 'Editing',
                    key: 'editing',
                },
                {
                    label: 'QA',
                    key: 'qa',
                },
                {
                    label: 'Done',
                    key: 'done',
                },
                {
                    label: 'Canceled',
                    key: 'canceled',
                },
                {
                    label: 'Repurpose Done',
                    key: 'repurpose_done',
                },
            ];
            case 'repurpose_done':
                return [
                    {
                        label: 'Video Request',
                        key: 'video_request',
                    },
                    {
                        label: 'On Hold',
                        key: 'on_hold',
                    },
                    {
                        label: 'Editing',
                        key: 'editing',
                    },
                    {
                        label: 'QA',
                        key: 'qa',
                    },
                    {
                        label: 'Repurpose Editing',
                        key: 'repurpose_editing',
                    },
                    {
                        label: 'Completed',
                        key: 'completed_done',
                    }
                ];
        case 'assigned':
            return [
                {
                    label: 'In Progress',
                    key: 'inProgress',
                },
                {
                    label: 'Cancelled',
                    key: 'cancelled',
                },
            ];
        case 'cancelled':
            return [
                {
                    label: 'Assigned',
                    key: 'assigned',
                },
            ];
        case 'inProgress':
            return [
                {
                    label: 'QA',
                    key: 'ytQA',
                },
                {
                    label: 'Cancelled',
                    key: 'cancelled',
                },
            ];
            case 'ytQA':
                return [
                    {
                        label: 'In Done',
                        key: 'inDone',
                    },
                    {
                        label: 'Cancelled',
                        key: 'cancelled',
                    },
                ];
                case 'inDone':
                    return [
                        {
                            label: 'Completed',
                            key: 'completed',
                        },
                        {
                            label: 'Cancelled',
                            key: 'cancelled',
                        },
                        {
                            label: 'In Progress',
                            key: 'inProgress',
                        },
                    ];
        default:
            return [
                {
                    label: 'None',
                    key: 'none',
                    disabled: true,
                }
            ];
    }
}

export function getTeamLeadPermission(activeTab) {
    switch(activeTab) {
        case 'video_request':
            return [
                {
                  label: 'Editing',
                  key: 'editing',
                },
                {
                  label: 'Canceled',
                  key: 'canceled',
                },
            ];
        case 'on_hold':
            return [
                {
                    label: 'Editing',
                    key: 'editing',
                },
                {
                    label: 'Canceled',
                    key: 'canceled',
                },
            ];
        case 'editing':
            return [
                {
                    label: 'On Hold',
                    key: 'on_hold',
                },
                {
                    label: 'QA',
                    key: 'qa',
                },
                {
                    label: 'Canceled',
                    key: 'canceled',
                },
            ];
        case 'qa':
            return [
                {
                    label: 'On Hold',
                    key: 'on_hold',
                },
                {
                    label: 'Editing',
                    key: 'editing',
                },
                {
                    label: 'Done',
                    key: 'done',
                },
                {
                    label: 'Canceled',
                    key: 'canceled',
                },
            ];
        case 'done':
            return [
                {
                    label: 'Video Request',
                    key: 'video_request',
                },
                {
                    label: 'On Hold',
                    key: 'on_hold',
                },
                {
                    label: 'Canceled',
                    key: 'canceled',
                },
                {
                    label: 'Repurpose Editing',
                    key: 'repurpose_editing',
                },
            ];
        case 'repurpose_editing':
            return [
                {
                    label: 'Repurpose Done',
                    key: 'repurpose_done',
                },
            ];
            case 'assigned':
                return [
                    {
                        label: 'In Progress',
                        key: 'inProgress',
                    },
                    {
                        label: 'Cancelled',
                        key: 'cancelled',
                    },
                ];
            case 'cancelled':
                return [
                    {
                        label: 'Assigned',
                        key: 'assigned',
                    },
                ];
            case 'inProgress':
                return [
                    {
                        label: 'QA',
                        key: 'ytQA',
                    },
                    {
                        label: 'Cancelled',
                        key: 'cancelled',
                    },
                ];
                case 'ytQA':
                    return [
                        {
                            label: 'In Done',
                            key: 'inDone',
                        },
                        {
                            label: 'Cancelled',
                            key: 'cancelled',
                        },
                    ];
            case 'inDone':
            return [
                {
                    label: 'Completed',
                    key: 'completed',
                },
                {
                    label: 'Cancelled',
                    key: 'cancelled',
                },
                {
                    label: 'In Progress',
                    key: 'inProgress',
                },
            ];
        default:
            return [
                {
                    label: 'None',
                    key: 'none',
                    disabled: true,
                }
            ];
    }
}

export function getQAPermission(activeTab) {
    switch(activeTab) {
        case 'video_request':
            return [
                {
                  label: 'Editing',
                  key: 'editing',
                },
            ];
        case 'on_hold':
            return [
                {
                    label: 'Editing',
                    key: 'editing',
                },
            ];
        case 'editing':
            return [
                {
                    label: 'On Hold',
                    key: 'on_hold',
                },
                {
                    label: 'QA',
                    key: 'qa',
                },
            ];
        case 'qa':
            return [
                {
                    label: 'On Hold',
                    key: 'on_hold',
                },
                {
                    label: 'Done',
                    key: 'done',
                },
            ];
        case 'done':
            return [
                {
                    label: 'Video Request',
                    key: 'video_request',
                },
                {
                    label: 'On Hold',
                    key: 'on_hold',
                },
                {
                    label: 'Editing',
                    key: 'editing',
                },
                {
                    label: 'Canceled',
                    key: 'canceled',
                },
                {
                    label: 'Repurpose Editing',
                    key: 'repurpose_editing',
                },
            ];
        case 'repurpose_editing':
            return [
                {
                    label: 'Repurpose Done',
                    key: 'repurpose_done',
                },
            ];
        default:
            return [
                {
                    label: 'None',
                    key: 'none',
                    disabled: true,
                }
            ];
    }
}

export function getYTHelperPermission(activeTab) {
    switch(activeTab) {
        case 'video_request':
            return [
                {
                  label: 'Editing',
                  key: 'editing',
                },
            ];
        case 'on_hold':
            return [
                {
                    label: 'Editing',
                    key: 'editing',
                },
            ];
        case 'editing':
            return [
                {
                    label: 'On Hold',
                    key: 'on_hold',
                },
                {
                    label: 'QA',
                    key: 'qa',
                },
            ];
        case 'qa':
            return [
                {
                    label: 'On Hold',
                    key: 'on_hold',
                },
                {
                    label: 'Done',
                    key: 'done',
                },
            ];
        case 'done':
            return [
                {
                    label: 'Repurpose Editing',
                    key: 'repurpose_editing',
                },
            ];
        case 'repurpose_editing':
            return [
                {
                    label: 'Repurpose Done',
                    key: 'repurpose_done',
                },
            ];
            case 'assigned':
                return [
                    {
                        label: 'In Progress',
                        key: 'inProgress',
                    },
                    {
                        label: 'Cancelled',
                        key: 'cancelled',
                    },
                ];
            case 'cancelled':
                return [
                    {
                        label: 'Assigned',
                        key: 'assigned',
                    },
                ];
            case 'inProgress':
                return [
                    {
                        label: 'QA',
                        key: 'ytQA',
                    },
                    {
                        label: 'Cancelled',
                        key: 'cancelled',
                    },
                ];
                case 'ytQA':
                    return [
                        {
                            label: 'In Done',
                            key: 'inDone',
                        },
                        {
                            label: 'Cancelled',
                            key: 'cancelled',
                        },
                    ];
                    case 'inDone':
        default:
            return [
                {
                    label: 'None',
                    key: 'none',
                    disabled: true,
                }
            ];
    }
}

export function getCustomerPermission(activeTab) {
    switch(activeTab) {
        case 'video_request':
            return [
                {
                    label: 'Canceled',
                    key: 'canceled',
                },
            ];
        default:
            return [
                {
                    label: 'None',
                    key: 'none',
                    disabled: true,
                }
            ];
    }
}

export function getStatusAndColor(status) {
    switch(status) {
        case "editing":
            return {
                    label: "EDITING",
                    color: "volcano",
                };
        case "qa":
            return {
                    label: "QA",
                    color: "volcano",
                };
        case 'done':
            return {
                    label: "DONE",
                    color: "green",
                };
        default:
            return {
                    label: status,
                    color: "geekblue",
                };
    }
}
