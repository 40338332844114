import {CARDS_FETCHED, CARDS_FETCHING, YTHELPER_CARDS_FETCHED, YTHELPER_CARDS_FETCHING, SELECTED_CARD_SECTION,CLEAR_CARD_SECTIONS} from "../actions/types";
import * as _ from 'lodash';
import { errorToast } from "../api/Util";

export default function (state = {}, {payload, type}) {
    switch (type) {
        case CARDS_FETCHING:
            return { ...state, loading: true }
        case CARDS_FETCHED:
            if(payload.status >= 200 && payload.status < 300) {
                let cardMap = {};

                 _.flatten([payload.data?.video_requests, payload.data?.editing, payload.data?.qa_cards,
                     payload.data?.done_cards, payload.data?.on_hold, payload.data?.cancelled_cards,payload.data?.repurposeDone,payload.data?.repurposeEditing])
                    .forEach(card => {
                        cardMap[card?.card_id] = card;
                    });
                
                let tempObj = {};
                
                if(payload.data?.monthlyCardCount || payload.data?.monthlyCardCount === 0) {
                    tempObj.monthlyCardCount = payload.data?.monthlyCardCount;
                }

                return {...state, cardList: payload.data, cardMap: cardMap, loading: false, ...tempObj};
            }
            return {...state, loading: false};
        // for loading state
        case YTHELPER_CARDS_FETCHING:
            return {...state,loading: true}
        
        // to get all the cards and pass it to props
        case YTHELPER_CARDS_FETCHED:
            if(payload && payload.status >=200 && payload.status < 300 )
            {
                if(payload && payload?.data?.message)
                {
                    errorToast(payload?.data?.message || "No cards found try again later" );
                    return {...state, loading: false};
                }
                let cardMap = {};

                 _.flatten([payload.data?.assigne_card, payload.data?.inProgress, payload.data?.inDone])
                    .forEach(card => {
                        cardMap[card.card_id] = card;
                    });

                return {...state, ytHelperCardList: payload.data, cardMap: cardMap, loading: false};
            }
            return {...state, loading: false};
        case SELECTED_CARD_SECTION:
            return { ...state, selectedCardSection: payload.data };
        case CLEAR_CARD_SECTIONS:
             return {...state, selectedCardSection: {}}    
        default:
            return state;
    }
}