
export const Toolbox = [
	{
		title: "Get new video ideas",
		description: "Not sure what to do next? Here are some suggestions, just for you.",
		type: "ideas",
		placeholder: "What is the video about?",
	},
	{
		title: "AI Title inspiration",
		description: "Struggling to come up with a title for your next video? Let VidChops make some suggestions.",
		type: "title",
		placeholder: "What is the video about?",
	},
	{
		title: "Video scripts",
		description: "Use the AI to create a new script from scratch, start with a template.",
		type: "script",
		placeholder: "Enter the title of the video script you want to create",
	}
];
export const ToolType = [
	{
		title: "Title",
		value: "title"
	},
	{
		title: "Ideas",
		value: "ideas",
	},
	{
		title: "Scripts",
		value: "script",
	}
];
export const optionsAudienceType = [
	{ value: "entertainment", label: "Entertainment" },
	{ value: "education", label: "Education" },
	{ value: "gaming", label: "Gaming" },
	{ value: "tech", label: "Tech" },
	{ value: "beauty", label: "Beauty" },
	{ value: "fitness", label: "Fitness" },
	{ value: "travel", label: "Travel" },
	{ value: "food", label: "Food" },
	{ value: "books", label: "Books" },
	{ value: "diy", label: "DIY" },
	{ value: "news", label: "News" },
	{ value: "music", label: "Music" },
	{ value: "parenting", label: "Parenting" },
	{ value: "science", label: "Science" },
	{ value: "motivation", label: "Motivation" },
];

export const optionsCategoryFor = [
	{ value: "children", label: "Children" },
	{ value: "teens", label: "Teens" },
	{ value: "young_adults", label: "Young Adults" },
	{ value: "adults", label: "Adults" },
	{ value: "seniors", label: "Seniors" },
];

export const optionsDurationFor = [
	{ value: "short_duration_video", label: "Short Duration" },
	{ value: "long_duration_video", label: "Long Duration" },
]
