import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { Redirect } from "react-router-dom";
import { ProgressBar } from "react-materialize";

export default function(ComposedComponent) {
    class RequireAuth extends Component {
        componentDidMount() {
            this.performAuthCheck();
        }

        componentDidUpdate(prevProps) {
            if (this.props.auth !== prevProps.auth) {
                this.performAuthCheck();
            }
        }

        performAuthCheck() {
            if (!this.props.auth.loggedIn) {
                this.props.history.push('/login');
                return;
            }
           
            if (this.props.auth.checkingAuth) {
                return;
            }
            let skipProfile = localStorage.getItem("skipProfileForNow");

            let user = this.props.auth?.loggedInUser;

            if((user?.is_temporary_password || !user?.is_profile_completed 
                || (!skipProfile && user?.is_yt_fields_filled === 1)) && this.props.location.pathname !== 'profile') {
                this.props.history.push('/profile');
            }
        }

        render() {
            if (this.props.auth.checkingAuth) {
                return <ProgressBar />;
            }

            if (this.props.auth.loggedIn === false) {
                return <Redirect to="/login" />;
            }

            return <ComposedComponent {...this.props} />;
        }
    }

    function mapStateToProps({ auth }) {
        return { auth };
    }

    return connect(mapStateToProps, actions)(RequireAuth);
}
