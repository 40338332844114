import { axiosClient } from "../api/httpClient";

export const saveNewHoliday = async (obj) => {
  try {
    const data = await axiosClient.post("/api/portal/save-holiday", obj);
    return { status: data?.status };
  } catch (error) {
    return {
      status: error.response?.status,
      error: error.response?.data?.error || "Some Error Occurred",
    };
  }
};

export const fetchHoliday = async (obj) => {
  try {
    const data = await axiosClient.get(`api/portal/get-holidays`);
    return {
      status: data?.status,
      data: data?.data,
    };
  } catch (error) {
    return {
      status: false,
      error: "Some Error Occurred",
    };
  }
};

export const updateHolidayStatus = async (obj) => {
  try {
    const data = await axiosClient.post(`api/portal/update-holiday`, obj);
    return { status: data?.status };
  } catch (error) {
    return {
      status: false,
      error: "Some Error Occurred",
    };
  }
};
