import React from 'react';
import { Modal, Input } from 'antd';
import styles from './AddMessageModal.module.scss';

const AddMessageModal = ({ title, isModalOpen, handleOk, handleCancel, clearFields, addTitle, addMessage, setAddTitle, setAddMessage }) => {
    return (
        <Modal title={title}
            open={isModalOpen.status === 'Re-open' ? isModalOpen.open : isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            afterClose={() => clearFields()}
            okButtonProps={{ disabled: (addTitle && addMessage) ? false : true }}>
            <Input className={styles.addTitleField} disabled={isModalOpen.status === 'Re-open' ? true : false} value={addTitle} onChange={e => setAddTitle(e.target.value)} placeholder="Add Title" />
            <Input className={styles.addMessageField} value={addMessage} onChange={e => setAddMessage(e.target.value)} placeholder="Add Message" />
        </Modal>
    )
}

export default AddMessageModal;
