import React from "react";
import {TextInput, Button, Modal, Col, Icon} from "react-materialize";
import { moveYtHelperCardInDone, moveYtHelperCardInDoneForSkip } from "../../../api/CardActions";
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import "./YTHelperCardMovementModalTabStyle.scss";
import { resize } from "../../../api/Util";

const YTHelperCardMovementModal = ({
        setYtHelperAssetsModal,
        ytHelperAssetsModal,
        thumbnailsLink,
        setThumbnailsLink,
        videoDescription,
        setVideoDescriptions,
        snippetLink,
        setSnippetLink,
        unlistedLink,
        setUnlistedLink,
        editorState,
        setEditorState,
        updateCardDetail,
        setThumbnailLinkUrlArray,
        thumbnailLinkUrlArray,
        thumbnailCount,
    }) => {    
        function handleEditorStateChange(e) {
            if (e) {
                // Converting editor state to html 
                let value = draftToHtml(convertToRaw(e.getCurrentContent()));
                // Storing the editor state to display in the model
                setEditorState(e);
                // Storing the editor html tag in state so that it can be pass to backend
                setVideoDescriptions(value);
            }
        }

        function handleChangeThumbnailURL(value,index) {
            if (index != null && index >= 0) {
                let list = thumbnailLinkUrlArray;
                list = resize(list, index, "");
                list[index] = value;
                setThumbnailLinkUrlArray(list);
            }
        }
    
        function mapThumbnailFields(limit) {
            let tempFields = [];
            for (let i = 0; i < limit-1; i++) {
                tempFields.push(
                    <TextInput s={12} label={"Video Thumbnail Link"} icon="link" className="thumbnails-link" validate key={"thumbnails-link" + i}
                    value={thumbnailLinkUrlArray[i] || ""} onChange={e => handleChangeThumbnailURL(e.target.value,i)}/>
                );
            }
            return tempFields;
        }

    return (
        <Modal
            actions = {[
                <Button flat modal='close' node= 'button' waves="red" large onClick={() => setYtHelperAssetsModal({open: false}) }>Close</Button>,
                <Button modal="close" onClick={()=> {
                    moveYtHelperCardInDone(ytHelperAssetsModal.cardId,
                    ytHelperAssetsModal.newStatus,ytHelperAssetsModal.cardStatus,thumbnailLinkUrlArray,videoDescription,snippetLink,unlistedLink, updateCardDetail
                    ); setYtHelperAssetsModal({open: false});
                }} disabled={!(thumbnailLinkUrlArray.length > 0)} node="button" waves = "green" large>Submit</Button>,
                <Button flat modal='close' node= 'button' waves="red" large onClick={() => { moveYtHelperCardInDoneForSkip(ytHelperAssetsModal.cardId,
                    ytHelperAssetsModal.newStatus,ytHelperAssetsModal.cardStatus, updateCardDetail ); setYtHelperAssetsModal({open: false});}}>Skip<Icon>skip_next</Icon></Button>
            ]}
            header = "Set YouTube Helper Assets"
            id="setYtHelperAssetsModel"
            open = {ytHelperAssetsModal.open}
            style={{height: '50rem'}}
            options={{
                dismissible: true,
                endingTop: '10%',
                opacity: 0.5,
                preventScrolling: true,
                onOpenStart: () => {setThumbnailsLink(""); setThumbnailLinkUrlArray([]); setSnippetLink(""); setUnlistedLink(""); setVideoDescriptions("");setEditorState();},
                onCloseEnd: () => {setYtHelperAssetsModal({open: false});}
            }}
        >
            {
                mapThumbnailFields(3)
            }
            <TextInput s={12} label="Snippet Link" icon="link" validate id={"snippet-link"}
                    value={snippetLink} onChange={e => setSnippetLink(e.target.value)}/>
            
            <TextInput s={12} label="Unlisted Video" icon="link" validate id={"unlisted-link"}
                    value={unlistedLink} onChange={e => setUnlistedLink(e.target.value)}/>

            <Col s={12} className="label-column">
            <label>Video Description</label>
            </Col>
            <Col s={12} className="label-column labele_title">
                <Icon>short_text</Icon>
                <Editor
                    toolbar = {{options: ['inline', 'blockType', 'fontSize', 'list', 'colorPicker', 'emoji']}}
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={(e)=>{handleEditorStateChange(e)}}
                />
                <div className="clearfix"></div>
            </Col>
        </Modal>
    );
}

export default YTHelperCardMovementModal;
