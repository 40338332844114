import {axiosClient} from '../api/httpClient';

export const storeAccessToken = async (postData) => {
	try {
		const data = await axiosClient.post('/api/google-access-token', postData);
		return {
            status: true,
			data: data?.data
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured"
		}
	}
};

export const getYTAnalytics = async (filterDaysAndMonths, clientId) => {
	try {
		const data = await axiosClient.get(`/api/getYTAnalytics/${filterDaysAndMonths}`, { params: {clientId}});
		return {
            status: true,
			data: data?.data
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured"
		}
	}
};

export const deleteTokens = async () => {
	try {
		const data = await axiosClient.delete('/api/delete-tokens');
		return {
            status: true,
			data: data?.data
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured"
		}
	}
};
