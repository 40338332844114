import { UNSEEN_INBOX_MESSAGE } from "../actions/types";

export default function (state = {}, {payload, type}) {
    switch (type) {
        case UNSEEN_INBOX_MESSAGE:
            return { ...state, unseenMessages: payload?.data, unSeenCount: payload?.data?.length };
        default:
            return state;
    }
}
