import React from "react";
import { Container, Table, Button, Icon } from "react-materialize";
import styles from "./HolidayTable.module.scss";

const HolidayTable = ({
  holidayData,
  onDeleteHoliday,
  onToggleHolidayStatus,
}) => {
  // Filter out holidays that are marked as deleted
  const visibleHolidays = holidayData.filter((holiday) => !holiday.is_deleted);

  // Sort the visibleHolidays array by date in descending order
  visibleHolidays.sort((a, b) => new Date(b.holiday_date) - new Date(a.holiday_date));

  return (
    <Container>
      <Table className={styles.holidayTable}>
        <thead>
          <tr>
            <th style={{ width: "20%",textAlign:"center" }}>S.No</th>
            <th style={{ width: "30%", textAlign:"center" }}>Title</th>
            <th style={{ width: "25%", textAlign:"center" }}>Date</th>
            <th style={{ width: "25%", textAlign:"center" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {visibleHolidays.length > 0 ? (
            visibleHolidays.map((holiday, index) => (
              <tr key={holiday.id}>
                <td>{index + 1}</td>
                <td>{holiday.holiday_title}</td>
                <td>
                  {new Date(holiday.holiday_date).toLocaleDateString("en-GB")}
                </td>
                <td className={styles.holidayActionButton}>
                  <Button
                    className={`${styles.customButton} ${
                      holiday.holiday_status
                        ? styles.activateButton
                        : styles.deactivateButton
                    } btn me-3`}
                    onClick={() => onToggleHolidayStatus(holiday)}
                    title={holiday.holiday_status ? "Deactivate" : "Activate"}
                  >
                    <Icon>
                      {holiday.holiday_status ? "toggle_off" : "toggle_on"}
                    </Icon>
                  </Button>
                  <Button
                    className={`btn-danger me-3 ${styles.customButton}`}
                    onClick={() => onDeleteHoliday(holiday)}
                    title="Delete"
                  >
                    <Icon>delete_forever</Icon>
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className={styles.NoDataFound} colSpan={4}>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default HolidayTable;
