import { CUSTOM, DOUBLE, SINGLE, UNLIMITED, WEEKLY, ACTIVE, INACTIVEFOR14DAYS, INACTIVEFOR30DAYS, VIDCHOPS_WEEKLY, VIDCHOPS_PRO} from "../api/Constants";


export const subscriptionOptions = [
    { label: 'Select', value: '' },
    { label: SINGLE, value: SINGLE },
    { label: WEEKLY, value: WEEKLY },
    { label: UNLIMITED, value: UNLIMITED },
    { label: DOUBLE, value: DOUBLE },
    { label: CUSTOM, value: CUSTOM },
    { label: VIDCHOPS_WEEKLY, value: VIDCHOPS_WEEKLY },
    { label: VIDCHOPS_PRO, value: VIDCHOPS_PRO }
];

export const referralOptions = [
    { label: 'Select', value: '' },
    { label: 'Google', value: 'Google' },
    { label: 'Youtube', value: 'Youtube' },
    { label: 'Linkedin', value: 'Linkedin' },
    { label: 'Google Search', value: 'Google Search' },
    { label: 'Facebook', value: 'Facebook' },
    { label: 'Affiliate', value: 'Affiliate' },
    { label: 'Other', value: 'Other' }
];

export const activityOptions = [
    { label: 'Select', value: '' },
    { label: ACTIVE, value: ACTIVE },
    { label: INACTIVEFOR14DAYS, value: INACTIVEFOR14DAYS },
    { label: INACTIVEFOR30DAYS, value: INACTIVEFOR30DAYS }
];

export const cardTypes = [
    { label: "Editing", value: 'editing' },
    { label: "Video Request", value: "video_request" },
    { label: "Done", value: "done" },
    { label: "Due Date", value: "due_date" }
];
