import React,{useState} from 'react'
import { Button, Icon } from 'react-materialize';
import { connect } from 'react-redux';
import { DONE, YT_HELPER_SECTION } from '../api/Constants'
import { isAdmin, isCustomer} from '../api/Util'
import ConfirmationDialog from './ConfirmationDialog';
import {moveCardSubStatus} from '../api/CardActions'
const actions = require('../actions');

export default connect(null,actions)(({me,card,refresh,closeModal,section =''}) => {
    if (isCustomer(me) && me.is_active_subscription === 0) {
        return null;
    }

    const [confirmationOpen, setConfirmationOpen] = useState(false);
    let allow = isCustomer(me) || isAdmin(me);

    if( section === YT_HELPER_SECTION || !allow || card.is_repurpose_complete || !(card.card_status === DONE && card.repurpose_status === DONE )){
        return "";
    }
    
    return (
        <Button className="btn-danger" icon = {<Icon left>navigate_before</Icon>}
            onClick = {()=> setConfirmationOpen(true)}
        >
            Repurpose
            <ConfirmationDialog
                onNegative = {()=>setConfirmationOpen(false)}
                onPositive = {()=>{moveCardSubStatus(card.card_id, "editing", card.repurpose_status, refresh); closeModal() }}
                confirmationHeader = "Repurpose"
                confirmationDialogId = {"confirm-repurpose-"+card.card_id}
                confirmation = {confirmationOpen}
                confirmationText = {`Moving card "${card.card_title}" to repurpose.\nAre you sure you want to proceed?`}
            />
        </Button>
    );
}
);
