import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import * as actions from "../../../../actions";
import {
    Col,
    Collapsible,
    Container,
    ProgressBar,
    Row,
} from "react-materialize";
import * as _ from 'lodash';
import CardList from "../../../CardList";
import CreateCard from "../../../CreateCard";
import CardModal from "../../../CardModal";
import EditCard from "../../../EditCard";
import {decodeBase64, getCardListConfig, getCardListConfigYtHelper, isAdmin, isCustomer, isTeamLead,toISODate} from "../../../../api/Util";
import DragNDrop from "../../../DragNDrop";
import PubSub from 'pubsub-js';
import {CARD_CLICKED} from "../../../../api/Events";
import {useLocation} from "react-router-dom";
import { useDispatch } from 'react-redux';
import { fetchCardsBasedOnSection } from '../../../../services/cardsService';
import { CARDS_FETCHED } from '../../../../actions/types';

export default connect(mapStateToProps, actions)((props) => {

    const [cardsData, setCardsData] = useState({});
    const [clearFilterState, setClearFilterState] = useState(false);

    const [key, setKey] = useState(1);
    const [viewCardOpen, setViewCardOpen] = useState(false);
    const [editCardOpen, setEditCardOpen] = useState(false);
    const [viewCardId, setViewCardId] = useState(0);
    const [editCardId, setEditCardId] = useState(0);
    const location = useLocation();
    const dispatch = useDispatch();
    const viewType = localStorage.getItem("viewType")
    const startDate = props.startDate;
    const endDate = props.endDate;
    const selectedDueDate = props.selectedDueDate;
    const searchText = props.searchText;
    const viewAs = props.viewAs;
    const newCardRequestOpen = props.newCardRequestOpen;

    const loggedInUser = props.auth?.loggedInUser || {};

    const admin = isAdmin(loggedInUser);
    const customer = isCustomer(loggedInUser);
    const teamLead = isTeamLead(loggedInUser);
    const cardStatus = useCardStatus(props);

    // common function for get data according to section wise.
    function useCardStatus(props) {
        return useMemo(() => {
            const cardSection = props.cards.selectedCardSection;
            let cardStatus = [];
            if (cardSection) {
                if (cardSection?.cardType === "repurpose") {
                    if (cardSection?.section === "editing") {
                        cardStatus.push({ status: "repurposeEditing", key: "repurposeEditing" });
                    } else if (cardSection?.section === "done") {
                        cardStatus.push({ status: "repurposeDone", key: "repurposeDone" });
                    }
                } else if (cardSection?.cardType === "updatedDone") {
                         cardStatus.push({stats: "updatedDone" , key: "updatedDone" });
                } else {
                    if (cardSection?.markAsDone) {
                        cardStatus = [
                            { status: "done", key: "done_cards" },
                            { status: "repurposeEditing", key: "repurposeEditing" }
                        ];
                    } else {
                        switch (cardSection?.section) {
                            case "video_request":
                                cardStatus = [{ status: "video_request", key: "video_requests" }];
                                break;
                            case "on_hold":
                                cardStatus = [{ status: "on_hold", key: "on_hold" }];
                                break;
                            case "editing":
                                cardStatus = [{ status: "editing", key: "editing" }];
                                break;
                            case "qa":
                                cardStatus = [{ status: "qa", key: "qa_cards" }];
                                break;
                            case "done":
                                cardStatus = [{ status: "done", key: "done_cards" }];
                                break;
                            case "canceled":
                                cardStatus = [{ status: "canceled", key: "cancelled_cards" }];
                                break;
                            default:
                                break;
                        }
                    }
                }
            } else {
							if (viewType !== "yt-helper") {
                if (props?.queryParamsValue?.querySectionType) {
									 var sectionType = props?.queryParamsValue?.querySectionType;
									if (sectionType === 'video_request') {
                    cardStatus = [
                        { status: "video_request", key: "video_requests" },
                    ];
									}
									else if (sectionType === 'completed') {
										cardStatus = [
											{ status: "updatedDone", key:"updatedDone" },
										];
									}	
									else if (sectionType === 'editing') {
										cardStatus = [
											{ status: "editing", key: "editing" },
										];
									}
                } else {
                cardStatus = [
                    { status: "video_request", key: "video_requests" },
                    { status: "on_hold", key: "on_hold" },
                    { status: "editing", key: "editing" },
                    { status: "qa", key: "qa_cards" },
                    { status: "done", key: "done_cards" },
                    { status: "canceled", key: "cancelled_cards" },
                    { status: "repurposeEditing", key: "repurposeEditing" },
                    { status: "repurposeDone", key: "repurposeDone" },
                    { status: "updatedDone", key:"updatedDone" },
                ];
            }
					 } else {
						cardStatus = [
							{ status: "assigned", key: "assigned" },
							{ status: "inProgress", key: "inProgress" },
                            { status: "ytQA", key: "ytQA" },
							{ status: "inDone", key: "inDone" },
							{ status: "cancelled", key: "cancelled" },
							{ status: "completed", key: "completed"},
					];
					 }	
          }
            return cardStatus;
        }, [props.cards.selectedCardSection]);
    }

    useEffect(() => {
        cardStatus.forEach(data => {
            let obj = {
                offset: 0,
                limit: 10,
                section: data.status,
                card_name: searchText ? searchText:"",
								viewType: viewType,
            }
            if (localStorage.getItem("viewAs")) {
                obj = {
                    ...obj,
                    view_as: localStorage.getItem("viewAs"),
                }
            }
            getCards(obj, data.key);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardStatus]);



    async function getCards(obj, key, params = null) {
        const res = await fetchCardsBasedOnSection(obj, params);
        if (res.status) {
            if (props.cards.selectedCardSection) {
                let currentCards = props.cards?.cardList;
                Object.keys(currentCards).forEach((cardKey) => {
                    if (cardKey === key) {
                        currentCards[cardKey] = res.data[key];
                    }
                });
                setCardsData({ ...currentCards });
            } else {
                setCardsData(prevState => ({ ...prevState, [key]: res?.data[key] }));
            }
        } else {
            console.error(res.error);
        }
    }
    useEffect(() => {
        if (Object.keys(cardsData).length > 0) {
            dispatch({
                type: CARDS_FETCHED,
                payload: { status: 200, data: cardsData }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardsData]);

    const refresh = () => {
        props.setSearchText("");
        let params = props.viewAs ? {
            view_as: props.viewAs
        } : {};
        cardStatus.forEach(data => {
            let obj = {
                offset: 0,
                limit: 10,
                section: data.status,
                card_name: searchText ? searchText:"",
								viewType: viewType,
            };
            if (startDate && endDate) {
                obj = {
                    ...obj,
                    startDate: toISODate(startDate),
                    endDate: toISODate(endDate),
                    selectedDueDate
                }
            }
            getCards(obj, data.key, params);
        });
    };
    
    const showEditCard = (cardId) => {
        setEditCardId(cardId);
        setEditCardOpen(true);
    }
    const showCard = (cardId) => {
        setViewCardId(cardId);
        setViewCardOpen(true);
    }

    useEffect(() => {
        if (props?.location?.search) {
            let encodeCardId = props.history.location.search.slice("?cardId=".length);
            let cardId = decodeBase64(encodeCardId);
            showCard(cardId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.location?.search])

    useEffect(() => {
        let cardId = location.hash?.replace(/#card-/i, "");
        if (cardId && (props?.cards?.cardMap || {})[cardId]) {
            showCard(cardId);
        }
    }, [props.cards.cardMap, location.hash]);

    useEffect(() => {
        const subToken = PubSub.subscribe(CARD_CLICKED, (msg, data) => showCard(data));

        if (_.isEmpty(props.settings)) {
            props.fetchSettings();
        }

        if (_.isEmpty(props.cards.cardList) && viewAs!==null) {
            refresh();
        }

        if((admin || teamLead ) && props.users.qas == null && !props.users.loadingUsers) {
            props.fetchAllUsers();
        }
        if ((admin || teamLead ) && props.users.customers === undefined && !props.users.loadingCustomers) {
            props.fetchAllCustomers();
        }
        return () => PubSub.unsubscribe(subToken);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewAs, admin]);

    useEffect(() => {
        if ((startDate && endDate) || clearFilterState) {
            refresh();
            setClearFilterState(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate,endDate,selectedDueDate, viewAs])

    // let cardsEmpty = _.isEmpty(props.cards);

    // if (cardsEmpty) {
    //     return (
    //         <Row>
    //             <Col s={12}>
    //                 <ProgressBar/>
    //             </Col>
    //         </Row>
    //     );
    // }

    return (
        <Container>
            <DragNDrop loggedInUser={loggedInUser} refresh={refresh} setKey={setKey}/>
            <CardModal cardId={viewCardId} isOpen={viewCardOpen} onClose={setViewCardOpen.bind(this, false)} refresh={refresh}
                       qas={props.users.qas} users = {props.users.users} me={loggedInUser} />
            <EditCard cardId={editCardId} me={loggedInUser} isOpen={editCardOpen} onClose={() => setEditCardOpen(false)}/>
            <CreateCard me={loggedInUser} isOpen={newCardRequestOpen} onClose={() => {props.setNewCardRequestOpen(false); refresh();}}/>
            {props.cards.loading ? <Row><ProgressBar /></Row> : ""}
       
            <Row className="row right-sec-row">
                <Col s={12} m={12}>
            {/* 4 columns --- */}
            <div className="card-rows">
                    <Row className="video-rows" style={{ overflowX: "scroll", marginBottom: "0" }}>
                    {viewType !== "yt-helper" && getCardListConfig(props).map((item, index) => (
                        item.condition !== false && (
                            <Col key={index} className="mobile-card-rows" s={4} m={4}>
                                <Collapsible className={`${item.name.toLowerCase().replace(/\s+/g, "-")}-list filters-icon`} accordion>
                                    <CardList
                                        name={item.name}
                                        viewCard={customer && item.type === "VIDEO_REQUEST" ? showEditCard : showCard}
                                        cards={props.cards?.cardList?.[item.key]}
                                        viewAs={viewAs}
                                        key={`${item.type.toLowerCase()}-${key}`}
                                        searchText={searchText}
                                        startDate={startDate}
                                        endDate={endDate}
                                        selectedDueDate={selectedDueDate}
                                        type={item.name !== "Repurposing" && item.name !== "Repurposing Review" ? item.type : undefined}
                                        rType={item.name === "Repurposing" || item.name === "Repurposing Review" ? item.type : undefined}
                                        queryParamsValue={props?.queryParamsValue}
                                    />
                                </Collapsible> 
                            </Col>
                        )
                    ))}

								{viewType === "yt-helper" && getCardListConfigYtHelper(props).map((item, index) => (
									item.condition !== false && (
										<Col key={index} className="mobile-card-rows" s={4} m={4}>
											<Collapsible className={`${item.name.toLowerCase().replace(/\s+/g, "-")}-list filters-icon`} accordion>
												<CardList
													name={item.name}
													viewCard={customer && item.type === "VIDEO_REQUEST" ? showEditCard : showCard}
													cards={props.cards?.cardList?.[item.key]}
													viewAs={viewAs}
													key={`${item.type.toLowerCase()}-${key}`}
													searchText={searchText}
													startDate={startDate}
													endDate={endDate}
													selectedDueDate={selectedDueDate}
													ytType={item.type}
													queryParamsValue={props?.queryParamsValue}
												/>
											</Collapsible>
										</Col>
									)
								))}

                    </Row>
            </div>
           </Col>
            </Row>
        </Container>
    );
});

function mapStateToProps({cards, auth, users, settings}) {
    return {cards, auth, users, settings};
}
