import React from 'react';
import Clock from 'react-live-clock';
import { Icon } from 'react-materialize';
import { PHILLIPINES_TIMEZONE } from '../../../api/Constants';

export default ({ timezone, clockDivStyle, format, view }) => {
    return (
        <div className={clockDivStyle}>
            <Icon left>access_time</Icon>
            <Clock
                format={format}
                ticking={true}
                interval={1000}
                timezone={timezone ? timezone : PHILLIPINES_TIMEZONE}
            />
            &nbsp;
            {
                view === "kanbanView" ? <span>EST</span> : null
            }
        </div>
    );
}
