import React from "react";
import { Modal, Typography } from 'antd';
import styles from './YTHelperCommonModalStyle.module.scss';
import Ratings from "react-ratings-declarative";
import { TextInput } from "react-materialize";
import './YTHelperCommonModalStyle.scss';
import { disableColor, primaryColor } from "../../../api/Util";

const YTHelperCommonModal = ({
    title,
    isModal,
    handleOk,
    handleCancel,
    popUpMessage,
    rating,
    setRating,
    comment,
    setComment,
    cardId,
    }) => {

    return (
        <Modal className={'modalStyle'} title={title} open={isModal} onOk={handleOk} onCancel={handleCancel}>
            <Typography.Text className={`${styles.greyFontColor} ${styles.containerForVideoDescription}`}>
                {popUpMessage}
            </Typography.Text>
            {
                title === "Mark As Done" ?
                    <div className={"ratingDiv"}>
                        <Ratings
                            rating={rating}
                            widgetHoverColors={primaryColor}
                            widgetEmptyColors={disableColor}
                            widgetRatedColors={primaryColor} 
                            changeRating={setRating}
                        >
                            <Ratings.Widget widgetDimension={"40px"} />
                            <Ratings.Widget widgetDimension={"40px"} />
                            <Ratings.Widget widgetDimension={"40px"} />
                            <Ratings.Widget widgetDimension={"40px"} />
                            <Ratings.Widget widgetDimension={"40px"} />
                        </Ratings>
                        <TextInput s={12} label="Any feedback?" icon="short_text" value={comment}
                            id={"txt-feedback-" + cardId}
                            onChange={e => setComment(e.target.value)}/>
                    </div> : null
            }
        </Modal>
    );
}

export default YTHelperCommonModal;
