import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { errorToast, isAdmin, successToast } from "../../api/Util";
import { Redirect } from "react-router-dom";
import { Tabs } from "antd";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Container,
  Icon,
  Row,
  Textarea,
  TextInput,
  Select,
} from "react-materialize";
import { axiosClient } from "../../api/httpClient";
import * as actions from "../../actions";
import TopHeader from "../TopHeader";
import "react-datepicker/dist/react-datepicker.css";
import AnnouncmentTable from "../AnnouncementTable/AnnouncementTable";
import styles from "./Settings.module.scss";
import "./Settings.scss";
import TabPane from "antd/es/tabs/TabPane";
import Holiday from "./holiday";

const SETTINGS_DEFAULT = {
  enable_banner: false,
  banner_heading: "",
  banner_heading_style: "h4",
  banner_text: "",
  banner_text_style: "15px",
  banner_heading_color: "#ffffff",
  banner_font_style: "none",
  banner_image: "",
  banner_image_style_height: "",
  banner_image_style_width: "",
  startDate: "",
  endDate: "",
};
const FontStyles = [
  { name: "Normal", value: "none" },
  { name: "Bold", value: "b" },
  { name: "Italics", value: "i" },
];

const Settings = ({ auth, settings }) => {
  const [editData, setEditedData] = useState({});
  const [deleteId, setDeleteId] = useState();
  const [state, setState] = useState(SETTINGS_DEFAULT);
  const [color, setColor] = useState(SETTINGS_DEFAULT.banner_heading_color);
  const [allSettingsData, setAllSettingsData] = useState([]);
  const [activateDeactive, setActivateDeactivate] = useState(null);
  const [activeTab, setActiveTab] = useState('Announcments');

  useEffect(() => {
    fetchAllSettings();
  }, []);

  useEffect(() => {
    if (editData && Object.keys(editData).length > 0) {
      setState(editData);
      setColor(editData.banner_heading_color || SETTINGS_DEFAULT.banner_heading_color);
    } else {
      setState(SETTINGS_DEFAULT);
      setColor(SETTINGS_DEFAULT.banner_heading_color);
    }
  }, [editData]);

  useEffect(() => {
    if (deleteId) {
      deleteSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteId]);

  useEffect(() => {
    if (activateDeactive) {
      editSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activateDeactive]);

  if (!isAdmin(auth?.loggedInUser)) {
    return <Redirect to="/" />;
  }

  function saveSettings() {
    let body = {
      ...state,
      enable_banner: state.enable_banner ? 1 : 0,
      banner_heading_color: color,
    };
    axiosClient
      .put(`/api/portal/settings/V2`, body)
      .then(({ data }) => {
        successToast(data.message || "Settings updated");
        fetchAllSettings();
        setState(SETTINGS_DEFAULT);
        setColor(SETTINGS_DEFAULT.banner_heading_color);
        setDeleteId(null);
        setEditedData({});
      })
      .catch((err) => {
        errorToast("Something went wrong");
      });
  }

  function deleteSettings() {
    if (deleteId) {
      axiosClient
        .delete(`/api/portal/settings/V2/${deleteId}/deleteAnnouncment`)
        .then(({ data }) => {
          successToast(data.message || "Announcement deleted");
          fetchAllSettings();
          setDeleteId(null);
        })
        .catch((err) => {
          errorToast("Something went wrong");
        });
    }
  }

  function editSettings() {
    let body;
    if (editData && Object.keys(editData).length > 0) {
      body = { ...state };
    } else if (activateDeactive) {
      body = { ...activateDeactive };
    } else {
      return;
    }
    axiosClient
      .post(`/api/portal/update-settings/V2/${body.id}`, body)
      .then(({ data }) => {
        successToast(data.message || "Settings updated");
        fetchAllSettings();
        setState(SETTINGS_DEFAULT);
        setColor(SETTINGS_DEFAULT.banner_heading_color);
        setDeleteId(null);
        setEditedData({});
        setActivateDeactivate(null); // Reset activateDeactive state
      })
      .catch((err) => {
        errorToast("Something went wrong");
      });
  }

  function fetchAllSettings() {
    axiosClient
      .get("/api/portal/settings/V2")
      .then(({ data }) => {
        setAllSettingsData(data);
      })
      .catch((err) => {
        errorToast("Something went wrong");
      });
  }

  function onChange(e) {
    let name = e.target.name;
    let value = e.target.value;

    if (e.target.type === "checkbox") {
      setState((prevState) => ({ ...prevState, [name]: !prevState[name] }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
  }
  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <Row className="settings-main">
      <div className="aside-right-top-bar">
        <div className="top-bar-left">
          <div className="top_bar">
            <h3>Settings</h3>
          </div>
        </div>
        <div className="top-bar-right">
          <TopHeader />
        </div>
      </div>
      <Container className="toolBoxCustom">
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="Announcments" key="Announcments">
      <Card
        title="Admin Portal Settings"
        className="announcementsForm"
        actions={[
          <Button
            key="save-btn"
            className="btn-primary saveButton"
            icon={<Icon left>save</Icon>}
            onClick={
              editData && Object.keys(editData).length === 0
                ? saveSettings
                : editSettings
            }
            disabled={
              !state.banner_heading ||
              !state.banner_text ||
              !state.startDate ||
              !state.endDate
            }
          >
            {editData && Object.keys(editData).length === 0 ? "Save" : "Update"}
          </Button>,
        ]}
      >
        <Container>
          <Row style={{ display: "flex", flexDirection: "column" }}>
            <Col s={12}>
              <Icon left>visibility_off</Icon>
              <Checkbox
                name="enable_banner"
                id="check-enable_banner"
                value="1"
                label="Enable Announcement"
                onChange={onChange}
                checked={state?.enable_banner}
              />
            </Col>
            <div
              style={{
                display: "flex",
                alignItems: "stretch",
                flexWrap: "wrap",
              }}
            >
              <div className={styles.headingStyle}>
                <TextInput
                  s={7}
                  id="txt_bh"
                  name="banner_heading"
                  label="Heading"
                  onChange={onChange}
                  className={styles.commonFormInputStyle}
                  value={state?.banner_heading}
                />
                <Select
                  s={3}
                  id="txt_bhs"
                  name="banner_heading_style"
                  label="Heading Type"
                  value={state?.banner_heading_style}
                  className={styles.commonFormInputStyle}
                  onChange={onChange}
                >
                  {[...Array(6).keys()].map((index) => (
                    <option key={index} value={`h${index + 1}`}>
                      H{index + 1} {index + 1 === 4 ? " (Recommended)" : null}
                    </option>
                  ))}
                </Select>
                <div className={`${styles.colorPicker} colorPickerDiv`}>
                  <TextInput
                    s={2}
                    id="color"
                    name="banner_heading_color"
                    style={{ width: "100%"}} // Specify your desired color
                    type="color"
                    label="Heading Style"
                    className={styles.commonFormInputStyle}
                    onChange={(e) => {
                      setColor(e.target.value);
                      onChange(e);
                    }}
                  />{" "}
                </div>
              </div>
              <div className={styles.bodyTextAreaStyle}>
                <Textarea
                  s={7}
                  id="txt_bt"
                  name="banner_text"
                  label="Body"
                  value={state?.banner_text}
                  className={styles.commonFormInputStyle}
                  onChange={onChange}
                />
                <Select
                  s={3}
                  id="txt_bts"
                  name="banner_text_style"
                  className={styles.commonFormInputStyle}
                  label="Body Style"
                  value={state?.banner_text_style}
                  onChange={onChange}
                >
                  {[...Array(7).keys()].map((index) => (
                    <option key={index} value={`${14 + index}px`}>
                      {14 + index}px{" "}
                      {14 + index === 15 ? " (Recommended)" : null}
                    </option>
                  ))}
                </Select>
                <Select
                  s={2}
                  id="txt_bhs"
                  name="banner_font_style"
                  value={state?.banner_font_style}
                  className={styles.commonFormInputStyle}
                  label="Font Style"
                  onChange={onChange}
                >
                  {FontStyles.map((fontStyle, index) => (
                    <option key={index} value={fontStyle.value}>
                      {fontStyle.name}
                    </option>
                  ))}
                </Select>
              </div>
              <TextInput
                s={7}
                id="txt_bi"
                name="banner_image"
                value={state?.banner_image}
                className={styles.commonFormInputStyle}
                label="Image"
                onChange={onChange}
              />
              <TextInput
                s={3}
                id="txt_bis"
                name="banner_image_style_height"
                value={state?.banner_image_style_height}
                className={styles.commonFormInputStyle}
                type="number"
                label="Image Style(Height in Pixel)"
                onChange={onChange}
              />
              <TextInput
                s={2}
                id="txt_bis"
                name="banner_image_style_width"
                value={state?.banner_image_style_width}
                className={styles.commonFormInputStyle}
                type="number"
                label="Image Style(Width in Pixel)"
                onChange={onChange}
              />
              {/* <TextInput s={4} id="txt_bis" name="banner_image_style"value={state?.banner_image_style}  label="Image Style" onChange={onChange} /> */}
              <div className={styles.startAndEndDateDiv}>
                <TextInput
                  s={4}
                  id="start_date"
                  name="startDate"
                  label="Start Date"
                  value={state?.startDate}
                  className={styles.commonFormInputStyle}
                  onChange={onChange}
                  type="date"
                />
                <TextInput
                  s={4}
                  id="end_date"
                  name="endDate"
                  label="End Date"
                  value={state?.endDate}
                  className={styles.commonFormInputStyle}
                  onChange={onChange}
                  type="date"
                />
              </div>
            </div>
          </Row>
        </Container>
      </Card>
      <Card className="announcementsListTable">
        <AnnouncmentTable
          settingsData={allSettingsData}
          setEditedData={setEditedData}
          setDeleteId={setDeleteId}
          setActivateDeactivate={setActivateDeactivate}
        />
      </Card>
      </TabPane>
      <TabPane tab="Holiday List" key="HolidayList">
          {activeTab === 'HolidayList' && (
          <Holiday />
          )}
        </TabPane>
      </Tabs>
      </Container>
    </Row>
  );
};

function mapStateToProps({ auth, settings }) {
  return { auth, settings };
}

export default connect(mapStateToProps, actions)(Settings);
