import React, {useEffect, useState} from "react";
import LoginPageCommon from "./LoginPageCommon";
import {Link} from "react-router-dom";
import {Button, Col, Row, TextInput} from "react-materialize";
import {errorToast, infoToast, isMobile, successToast} from "../api/Util";
import {axiosClient} from "../api/httpClient";

export default () => {

    const [email, setEmail] = useState('');
    const [showInstructions, setShowInstructions] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setEmail('');
        setShowInstructions(false);
        setLoading(false);
    }, []);

    function validateForm() {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
    }

    function handleSubmit() {
        infoToast("Loading");
        setLoading(true);
        axiosClient.post('/api/user/password-reset', { email })
            .then(() => {
                successToast('Password reset successful');
                setShowInstructions(true);
            })
            .catch((err) => {
                console.error(err);
                errorToast('Something went wrong, please try again');
            }).finally(() => setLoading(false));
    }

    return (
        <LoginPageCommon>
            <h5>Welcome Back<br/>Pleasure To See You Again</h5>

            <div className="loginPageWrapper" style={{width: "80%", maxWidth: "400px", margin: "auto"}}>
                <div className="left-align signInDiv" style={{width: "80%", margin: "auto"}}>
                    <h4>Reset Password</h4>
                    <p style={{ marginTop: isMobile ? "-10px" : 0 }}>Or, <Link to='/login'>click here to sign in</Link></p>
                </div>

                <Row>
                    <TextInput s={12}
                               id="email"
                               inputClassName="border-text-box border-text-box-login"
                               placeholder="Email"
                               email validate autoFocus
                               value={email}
                               onChange={e => setEmail(e.target.value)} />
                </Row>
                <Row className="center-align">
                    <Col s={12} className="loginColStyle" style={{padding: 0}}>
                        <Button className="btn-primary round-button subminBtn" disabled={!validateForm() || loading}
                                onClick={handleSubmit}>Reset Password</Button>
                    </Col>
                </Row>
                {
                    showInstructions &&
                    <Row className="center-align">
                        <Col s={12}>
                            <p className="round-button green">We've reset your password and set an email containing further instructions</p>
                        </Col>
                    </Row>
                }
            </div>
        </LoginPageCommon>
    );
}
