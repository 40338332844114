import React from "react";
import PrivacyHeader from "./privacyHeader";
import PrivacyContent from "./privacyContent";
import styles from "./PrivacyPolicy.module.scss";
const PrivacyPolicy = () => {
  return (
    <div className={styles.mainContainer}>
      <PrivacyHeader />
      <PrivacyContent />
    </div>
  );
};

export default PrivacyPolicy;
