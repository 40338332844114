import React, {useState} from "react";
import {disableColor, errorToast, isAdmin, isCustomer, primaryColor, successToast} from "../api/Util";
import {Button, Col, Icon, Modal, Row, TextInput} from "react-materialize";
import ConfirmationDialog from "./ConfirmationDialog";
import {axiosClient} from "../api/httpClient";
import {connect} from "react-redux";
import Ratings from "react-ratings-declarative";
import {DONE, EDITING, YT_HELPER_IN_DONE, YT_HELPER_SECTION, YT_HELPER} from "../api/Constants";

const replaceString = require('react-string-replace');
const actions = require('../actions');

export default connect(null, actions)(({me, card, fetchCards,section = '', onToggleRevisionButton}) => {
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [ratingsOpen, setRatingsOpen] = useState(false);
    const [YTHelperNotifyOpen, setYTHelperNotifyOpen] = useState(false);
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState("");

    if((!isCustomer(me) && !isAdmin(me)) || (card.card_status !== DONE && section !== YT_HELPER_SECTION) || (card.card_status === DONE && card.repurpose_status === EDITING)
        || (section === YT_HELPER_SECTION && card.yt_card_status !== YT_HELPER_IN_DONE) || (section === YT_HELPER_SECTION && card.yt_is_complete)) 
    {
        return "";
    }

    function onConfirmComplete() {

        let commonCardUrl = `/api/card/${card.card_id}/markComplete`;
        let ytCardUrl = `/api/ythelper/card/${card.card_id}/markComplete`;
        let cardSection = section !== YT_HELPER_SECTION && card.card_status === DONE && card.repurpose_status === DONE ? "repurpose" : "done";

        axiosClient.post(section === YT_HELPER_SECTION ? ytCardUrl:commonCardUrl ,
            {card_id: card.card_id, status: 1,cardSection, buttonText: card.card_status === DONE && card.yt_helper_status && card.role === YT_HELPER ? "Mark As Done" : "Mark As Done" })
            .then(({data}) => {
                successToast(data.success || "Card marked completed!");
                onToggleRevisionButton(false);
                section !== YT_HELPER_SECTION && setRatingsOpen(true);
            }).catch(err => {
                let message = "Something went wrong, please try again";
                if(err?.response?.data && err.response.data.message && err.response.data.message !== "") {
                    message = err.response.data.message;
                }
                errorToast(message);
                console.error(err);
            });
    }

    function getCardRateType() {
        let cardType = "done";
        if (card.is_repurpose_complete) {
            cardType = "repurpose";
        }
        return cardType;
    }

    function onRate() {
        axiosClient.post(`/api/card/${card.card_id}/rate`,
            {
                card_id: card.card_id,
                rating, comment,
                card_type: getCardRateType(),
                assigned_qa_id: card.assigned_qa_id,
            })
            .then(({data}) => {
                successToast("Feedback submitted, thank you!");
                card.card_status === DONE && card.yt_helper_status && card.role === YT_HELPER && setYTHelperNotifyOpen(true);
            }).catch(err => {
                errorToast("Something went wrong, please try again");
                console.error(err);
            });
    }

    let ratingsModal = (
        <Modal
            actions={[
                <Button modal="close" onClick={onRate} disabled={!rating} node="button" waves="green" className="btn-primary" large>Submit</Button>
            ]}
            header="Feedback"
            id="setFeedbackModal"
            open={ratingsOpen}
            style={{height: '28rem'}}
            options={{
                dismissible: false,
                endingTop: '10%',
                opacity: 0.5,
                preventScrolling: true,
                onOpenStart: () => setRating(0),
                onCloseEnd: () => { setRatingsOpen(false); fetchCards(); }
            }}
        >
            <Row style={{marginTop: "20px"}}>
                <Col s={12} m={10} l={6} push="m1 l3" className="align-center">
                    <div className="rating-div">
                        <h2>CONGRATULATIONS!</h2>
                        <p>For completing your VIdeo Request. Let us know how satisfied you are with your Editor by rating them below.</p>
                    </div>
                    <Ratings
                        rating={rating}
                        changeRating={setRating}
                        widgetHoverColors={primaryColor}
                        widgetEmptyColors={disableColor}
                        widgetRatedColors={primaryColor}   
                    >
                        <Ratings.Widget/>
                        <Ratings.Widget/>
                        <Ratings.Widget/>
                        <Ratings.Widget/>
                        <Ratings.Widget/>
                    </Ratings>
                </Col>
                <TextInput s={12} label="Any feedback? (Optional)" icon="short_text" value={comment}
                           id={"txt-feedback-" + card.card_id}
                           onChange={e => setComment(e.target.value)}/>
            </Row>
        </Modal>
    );

    let YTHelperReminderModal = (
        <Modal
            actions={[
                <Button flat modal="close" node="button" waves="red" large>Close</Button>
            ]}
            header="Reminder"
            open={YTHelperNotifyOpen}
            options={{
                dismissible: true,
                endingTop: '10%',
                opacity: 0.5,
                preventScrolling: true,
                onCloseEnd: () => setYTHelperNotifyOpen(false)
            }}
        >
            <p>Your video has now been finalized. This is now moving to the YT Helper Optimization (Thumbnail Creation, Description, Upload on your YT Channel). For the status, please go to the YT Helper Tab.</p>
        </Modal>
    )


    if(((card.is_complete && card.repurpose_status === null) || (card.is_repurpose_complete && card.repurpose_status !== null)) && section !== YT_HELPER_SECTION) {
        return (
            <Button className="btn-primary" icon={<Icon left>star</Icon>} onClick={() => setRatingsOpen(true)}>
                {ratingsModal}
                Rate
            </Button>
        );
    }

    return React.Children.toArray([
        <Button className="btn-primary" icon={<Icon left>check</Icon>} onClick={() => setConfirmationOpen(true)}>
            {card.card_status === DONE && card.yt_helper_status && card.role === YT_HELPER ? "Mark As Done" : "Mark As Done"}
            <ConfirmationDialog
                onNegative={() => setConfirmationOpen(false)}
                onPositive={onConfirmComplete}
                confirmationHeader={card.card_status === DONE && card.yt_helper_status && card.role === YT_HELPER ? "Mark As Done" : "Mark As Done"}
                confirmationDialogId={"confirm-complete-" + card.card_id}
                confirmation={confirmationOpen}
                confirmationText={replaceString("Mark PLCHLDR as done?", /(PLCHLDR)/,
                    (match, i) => (<strong key={match + i}>{card.card_title}</strong>))}
            />
        </Button>,
        ratingsModal,
        YTHelperReminderModal
    ]);
});
