import React, { useCallback, useEffect } from "react";
import {Button, Modal} from "react-materialize";
import { isMobile } from "../api/Util";

export default (props) => {

    const closeModal = useCallback(() => {
        const modalElement = document.getElementById(props.confirmationDialogId);
        if (modalElement && modalElement.M_Modal) {
            modalElement.M_Modal.close();
        }
    }, [props.confirmationDialogId]);

    const enterFunction = useCallback((event) => {
        if (event.key === "Enter" && props.confirmation) {
            props.onPositive();
            closeModal();
        }
    }, [props.confirmation, props.onPositive, closeModal]);

    useEffect(() => {
        document.addEventListener("keydown", enterFunction, false);
        return () => {
            document.removeEventListener("keydown", enterFunction, false);
        };
    }, [enterFunction]);

    return (
        <Modal className={props.confirmationDialogId === "userUpdateConfirmation" ? "confirmationModal" : ""}
            actions={[
                <Button flat onClick={props.onNegative} modal="close" node="button" waves="red" large style={{marginLeft: "10px", padding: "10px 28px"}}>
                    {props.negativeText || "No"}
                </Button>,
                <Button  onClick={() => {
                    props.onPositive();
                    closeModal();
                }}  modal="close" node="button" waves="green" className="btn-primary" large style={{marginLeft: "10px", padding: "10px 28px"}}>
                    {props.positiveText || "Yes"}
                </Button>
            ]}
            header={props.confirmationHeader}
            id={props.confirmationDialogId}
            open={props.confirmation}
            style={{minHeight: isMobile ? "16vh" : "15vh", width: "30%"}}
            options={{
                dismissible: true,
                startingTop: '4%',
                endingTop: '10%',
                opacity: 0.5,
                preventScrolling: true,
                onCloseEnd: props.onNegative
            }}
        >
            {props.confirmationText}
        </Modal>
    );
}
