import React from 'react';
import { Button } from 'antd';
import styles from './KanbanAndListViewToggleStyle.module.scss';
import KanbanBtn from '../../img/kanbanBtn.png';
import ListBtn from '../../img/listBtn.png';
import { useSelector } from 'react-redux';
import { isMobile } from '../../../api/Util';

const KanbanAndListViewToggle = ({
    selectedListingView,
    setSelectedListingView,
    }) => {
    const props = useSelector((state) => state);
    const loggedInUser = props.auth?.loggedInUser;

    const handleKanbanClick = () => {
        localStorage.setItem('selectedListingView', 
        JSON.stringify({ "userSelected" : loggedInUser.client_id, "cardViewType" : "kanbanView" }));
        setSelectedListingView("kanbanView");
    };

    const handleListClick = () => {
        localStorage.setItem('selectedListingView', 
        JSON.stringify({ "userSelected" : loggedInUser.client_id, "cardViewType" : "listView" }));
        setSelectedListingView("listView");
    };

    return (
        <div className={styles.viewsContainer}>
            <Button
                type="primary"
                size="large"
                className={isMobile ? styles.kanbanViewBtnMobile : selectedListingView === "kanbanView" ? styles.activeViewBtn : styles.inActiveViewBtn}
                disabled={selectedListingView === "kanbanView"}
                onClick={handleKanbanClick}>
                {isMobile ? <img src={KanbanBtn} alt="KanbanBtn" /> : "Board View"}
            </Button>
            <Button
                type="primary"
                size="large"
                className={isMobile ? styles.listViewBtnMobile : selectedListingView === "listView" ? styles.activeViewBtn : styles.inActiveViewBtn}
                disabled={selectedListingView === "listView"}
                onClick={handleListClick}>
                {isMobile ? <img src={ListBtn} alt="ListBtn" /> : "List View"}
            </Button>
        </div>
    );
}

export default KanbanAndListViewToggle;
