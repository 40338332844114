import {axiosClient} from '../api/httpClient';

export const fetchCardsBasedOnSection = async (obj, params) => {
	try {
        const requestObj = params ? { ...obj, ...params } : obj;
        const data = await axiosClient.post('/api/getCards', requestObj);
		return {
            status: true,
			data: data?.data,
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured",
		}
	}
};

export const fetchCardsWithDueDate = async () => {
	try {
		const data = await axiosClient.get('/api/exportCards');
		return {
            status: true,
			data: data?.data,
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured",
		}
	}
};

export const getCardsBasedOnTitle = async (title) => {
	try {
		const data = await axiosClient.get('/api/getCardsByTitle', {
            params: {
				title: title
			},
        });
		return {
            status: true,
			data: data?.data,
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured",
		}
	}
};

export const saveNewQAOnCard = async (obj) => {
	try {
		const data = await axiosClient.post('/api/assignNewQA', obj);
		return {
            status: data?.data?.status,
			data: data?.data,
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured",
		}
	}
};

export const getCards = async (obj) => {
	try {
		const data = await axiosClient.post('/api/getCard', obj);
		return {
            status: true,
			data: data?.data
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured"
		}
	}
};

export const getCardDetail = async (cardId) => {
	try {
		const data = await axiosClient.get('/api/cardDetail/' + cardId);
		return {
            status: true,
			data: data?.data
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured"
		}
	}
};

export const getCardsBasedOnDueDate = async (filterParams) => {
	try {
		const data = await axiosClient.get('/api/get-cards-by-due-date', {
            params: {
				viewAsCustomer: filterParams.viewAsCustomer,
				dueDate: filterParams.dueDate,
				viewAsQA: filterParams.viewAsQA,
				viewAsCardType: filterParams.viewAsCardType
			},
        });
		return {
            status: true,
			data: data?.data
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured"
		}
	}
};

export const giveRatingToCard = async (obj) => {
	try {
		await axiosClient.post(`/api/card/${obj?.card_id}/rate`, obj);
		return {
            status: true,
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured"
		}
	}
};

export const exportCardBasedOnYear = async (filterParams) => {
	try {
		const data = await axiosClient.get(`/api/exportCardAsCsv/${filterParams?.year}`, {
		params: {
			limit: filterParams.limit,
		},
	});
		return {
		status: true,
		data: data?.data
		}
	} catch (error) {
		return {
		status: false,
		error: "Some Error Occurred"
		}
	}
}
export const getUpdatedRatingCards = async (params) => {
    try {
        const data = await axiosClient.get("/api/getUpdatedRatingCards", {
            params: {
				startDate: params?.startDate,
				endDate: params?.endDate,
                limit: params?.limit,
                page: params?.index,
				selectedRating: params?.selectedRating
            },
        });
        return {
            status: true,
            data: data?.data,
        };
    } catch (error) {
        return {
            status: false,
            error: "Some Error Occurred",
        };
    }
};

export const addEventsForCard = (eventType,cardId) => {
	return axiosClient.get(`/api/add-card-event/${cardId}?type=${eventType}`) 
}
