import { CRED_SECRET_KEY } from '../credSecretKey'
const CryptoJS = require('crypto-js');

export function getAccessKeyValue(type) {
  let string = "";
  if (type === "aws") {
    string = process.env.REACT_APP_AWS_ACCESS_KEY;
  } else if (type === "digitalOcean") {
    string = process.env.REACT_APP_DIGITALOCEAN_ACCESS_KEY;
  }
  return CryptoJS.AES.decrypt(string, CRED_SECRET_KEY).toString(CryptoJS.enc.Utf8);
}

export function getSecretKeyValue(type) {
  let string = "";
  if (type === "aws") {
    string = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
  } else if (type === "digitalOcean") {
    string = process.env.REACT_APP_DIGITALOCEAN_SECRET_KEY;
  }
  return CryptoJS.AES.decrypt(string, CRED_SECRET_KEY).toString(CryptoJS.enc.Utf8);
}

