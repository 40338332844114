import React from "react";
import { Container, Table, Button, Icon } from "react-materialize";
import styles from "./AnnouncementTable.module.scss";
import { isMobile } from "../../api/Util";

const AnnouncmentTable = ({
  settingsData,
  setEditedData,
  setDeleteId,
  setActivateDeactivate,
}) => {
  const handleEdit = (data) => {
    setEditedData({ ...data });
  };
  const handleDelete = (id) => {
    setDeleteId(id);
  };
  const handleActivateDeactivate = (setting, buttonName) => {
      setActivateDeactivate((prevData) => {
      const newEnableBanner = setting.enable_banner === 1 ? 0 : 1;
      return { ...setting, enable_banner: newEnableBanner };
    });
  };

  const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) {
      return text;
    }
    return text?.substring(0, maxLength) + "...";
  };

  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>S.No</th>
            <th style={{ width: "22%" }}>Heading</th>
            <th style={{ width: "22%" }}>Body</th>
            <th style={{ width: "8%" }}>Start Date</th>
            <th style={{ width: "8%" }}>End Date</th>
            <th style={{ width: "35%" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {settingsData?.length > 0 ? settingsData?.map((setting, index) => (
            <tr key={setting.id}>
              <td>{index + 1}</td>
              <td>{truncateText(setting.banner_heading, 60)}</td>
              <td>{truncateText(setting.banner_text, 50)}</td>
              <td>{setting.startDate}</td>
              <td>{setting.endDate}</td>
              <td className={styles.actionListButton}>
                  <Button
                    className={`btn-danger ${styles.customButton}`}
                    onClick={() => handleEdit(setting)}
                  >
                   {!isMobile && <span>Edit</span> }<Icon right>edit</Icon>
                  </Button>
                  <Button
                   className={`btn-danger me-3 ${styles.customButton}`}
                    onClick={() => handleDelete(setting?.id)}
                  >
                    {" "}
                   {!isMobile && <span>Delete</span> } <Icon right>delete_forever</Icon>{" "}
                  </Button>
                  <Button
                    className={`btn-danger ${styles.customButton}`}
                    onClick={() =>
                      handleActivateDeactivate(
                        setting,
                        setting.enable_banner === 1 ? "Deactivate" : "Activate"
                      )
                    }
                  >
                   {!isMobile && (setting.enable_banner === 1 ? "Deactivate" : "Activate")}
                    <Icon right>check</Icon>
                  </Button>
              </td>
            </tr>
          ))
        : <td className={styles.NoDataFound} colSpan={6}>No data found</td>
        }
        </tbody>
      </Table>
    </Container>
  );
};

export default AnnouncmentTable;
