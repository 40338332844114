import './styles/app.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from "redux";
import reduxThunk from 'redux-thunk';

import App from './components/App';
import reducers from './reducers';
import {axiosClient, setAuthToken, setUpInterceptors, setUserTimezoneInRequest} from "./api/httpClient";
import {FETCH_USER, FETCHING_USER,FETCH_NOTIFICATION_COUNT,FETCHING_NOTIFICATION_COUNT,FETCHING_NOTIFICATION_LIST,FETCH_NOTIFICATION_LIST, UNSEEN_INBOX_MESSAGE} from "./actions/types";

// eslint-disable-next-line no-unused-vars
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

const store =  createStore(reducers, {}, applyMiddleware(reduxThunk));

let authToken = localStorage.getItem("Authorization");
localStorage.removeItem('activeTab');
setAuthToken(authToken);
setUserTimezoneInRequest();
setUpInterceptors(store);
if(authToken) {
    store.dispatch({type: FETCHING_NOTIFICATION_COUNT});
    axiosClient.get("/api/notifications/getCount")
        .then(response => {
            store.dispatch({type: FETCH_NOTIFICATION_COUNT, payload: response.data.notificationsCount});
        }).catch(err => {
            console.error(err);
            store.dispatch({type: FETCH_NOTIFICATION_COUNT, payload: err.response});
    });
    store.dispatch({type: FETCHING_NOTIFICATION_LIST});
    axiosClient.get("/api/notifications")
        .then(response => {
            store.dispatch({type: FETCH_NOTIFICATION_LIST, payload: response.data.notifications});
        }).catch(err => {
            console.error(err);
            store.dispatch({type: FETCH_NOTIFICATION_LIST, payload: err.response});
    });
    store.dispatch({type: FETCHING_USER});
    axiosClient.get('/api/me')
        .then(response => {
            store.dispatch({type: FETCH_USER, payload: response});
        }).catch(err => {
            console.error(err);
            store.dispatch({type: FETCH_USER, payload: err.response});
    });
    axiosClient.get('/api/inbox/unseen')
    .then(response => {
        store.dispatch({type: UNSEEN_INBOX_MESSAGE, payload: response});
    }).catch(err => {
        console.error(err);
        store.dispatch({type: UNSEEN_INBOX_MESSAGE, payload: err.response});
    });
}

ReactDOM.render(
    <Provider store={store}><App/></Provider>,
    document.querySelector('#root')
);
