import React, { useEffect, useState } from "react";
import { Button, Col, Container, Icon, Row, Textarea } from "react-materialize";
import { axiosClient } from "../api/httpClient";
import {
    convertLocalToServerTime,
    convertServerToLocalTime,
    errorToast,
    infoToast,
    parseSqlDateTime,
    toISODateTime,
    successToast,
    warningToast
} from "../api/Util";
import * as _ from 'lodash';
import useInterval from "../hooks/UseInterval";
import Profile from './img/profile.png';

import { MdComment } from 'react-icons/md';

import { ADMIN, CUSTOMER, QA, TEAM_LEAD, YT_HELPER, YT_HELPER_LEAD, YT_HELPER_IN_DONE, YT_HELPER_SECTION } from "../api/Constants";
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import Select from "react-select";
import ConfirmationDialog from "./ConfirmationDialog";
import InfiniteScroll from "react-infinite-scroller";
const reactStringReplace = require('react-string-replace');

const INITIAL_STATE = {
    me: {},
    cardId: null,
    lastUpdated: 0,
    comments: []
}
const INITIAL_REPLY_STATE = {
    comment: "",
    showReplyField: false,
    parentId: null
}

const INITIAL_COMMENT_STATE = {
    id: 0, 
    data: "", 
    oldComment: "",
}

const REGEX_ADMIN = /(@[aA][dD][mM][iI][nN])/g;
const REGEX_CLIENT = /(@[cC][lL][iI][eE][nN][tT])/g;
const REGEX_EDITOR = /(@[eE][dD][iI][tT][oO][rR])/g;
const REGEX_QA = /(@[qQ][aA])/g;
const REGEX_YTHELPER = /(@[yY][tT][hH][eE][lL][pP][eE][rR])/g;
const REGEX_YTTEAMLEAD = /(@[yY][tT][tT][eE][aA][mM][lL][eE][aA][dD])/g;

function makeLinks(text) {
    return reactStringReplace(text, /(https?:\/\/[^ ]*)/g, (match, i) => (
        <strong><a key={match + i} className="comment-link" href={match} target="_blank" rel="noopener noreferrer">{match}</a></strong>
    ));
}

function highlightTags(text) {
    let replacedText = reactStringReplace(text, REGEX_ADMIN, (match, i) => (
        <span key={match + i} className="comment-tag"><strong>{match}</strong></span>
    ));
    replacedText = reactStringReplace(replacedText, REGEX_CLIENT, (match, i) => (
        <span key={match + i} className="comment-tag"><strong>{match}</strong></span>
    ));
    replacedText = reactStringReplace(replacedText, REGEX_EDITOR, (match, i) => (
        <span key={match + i} className="comment-tag"><strong>{match}</strong></span>
    ));
    replacedText = reactStringReplace(replacedText, REGEX_QA, (match, i) => (
        <span key={match + i} className="comment-tag"><strong>{match}</strong></span>
    ));
    replacedText = reactStringReplace(replacedText, REGEX_YTHELPER, (match, i) => (
        <span key={match + i} className="comment-tag"><strong>{match}</strong></span>
    ));
    replacedText = reactStringReplace(replacedText, REGEX_YTTEAMLEAD, (match, i) => (
        <span key={match + i} className="comment-tag"><strong>{match}</strong></span>
    ));
    return replacedText;
}

function parseVideoTime(text, onClick) {
    return reactStringReplace(text || "", /^@VT{(.+)}/i, (match, i) => {
        let time = Math.floor(match);
        let hours = Math.floor(time / 3600);
        let minutes = Math.floor((time % 3600) / 60);
        let seconds = Math.floor(time % 60);

        return (
            <span key={match + time} style={{ cursor: "pointer", textDecoration: "underline", color: "#ff7700" }} onClick={onClick.bind(this, time)}>
                <strong>
                    {hours ? `${hours}:`.padStart(2, "0") : ""}{minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}
                </strong>
            </span>
        );
    });
}

function uniqueComments(comments) {
    return _.uniqBy(comments, 'note_id');
}
function uniqueAnnotations(annotations) {
    return _.uniqBy(annotations, 'id');
}

export default (props) => {
    const [state, setState] = useState({ ...INITIAL_STATE, me: props.me, cardId: props.cardId });
    const [comment, setComment] = useState(INITIAL_COMMENT_STATE);

    const [disableBtn, setDisableBtn] = useState(false);

    useInterval(() => loopLoadComments("intervalCalled"), props.poll ? (process.env.REACT_APP_COMMENT_REFRESH_TIMEOUT || 10000) : null);

    const video = document.querySelector('#preview-vid');

    const [chatView, setChatView] = useState({ label: "Customer Chat", value: "customer" });
    const [teamState, setTeamState] = useState({ lastUpdated: 0, chats: [] });
    const [viewChatOf, setViewChatOf] = useState({ label: "All", value: "all" });
    const [noComments, setNoComments] = useState(false);
    const [roleOptions, setRoleOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState({ open: false, comment: {} });

    const [offset, setOffset] = useState(0);
    const [callLoadFunc, setCallLoadFunc] = useState(false);

    const [replyState, setReplyState] = useState(INITIAL_REPLY_STATE);
    const [showToggleState, setShowToggleState] = useState({ showToggle: false, parentId: null });

    useEffect(() => {
        // eslint-disable-next-line no-mixed-operators
        if (props.section !== YT_HELPER_SECTION && (props.videoCount > 0 && props.showVideoChat || props.videoCount === 0 && props.cardId)) {
            setTeamState({ lastUpdated: 0, chats: [] });
            setState({...INITIAL_STATE, me: props.me, cardId: props.cardId});
            setOffset(0);
            setCallLoadFunc(true);
        } else if (props.section === YT_HELPER_SECTION || props.section === "editCard") {
            setComment(INITIAL_COMMENT_STATE);
            setOffset(0);
            setCallLoadFunc(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cardId,props.showVideoChat, chatView, viewChatOf]);

    useEffect(() => {
        if (callLoadFunc) {
            loopLoadComments('scrollCalled');
        }
        if (props?.callChatApi) {
            loopLoadComments("intervalCalled");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callLoadFunc, props?.callChatApi])

    useEffect(() => {
        if (props && props.card?.card_id) {
            let option = [
                { label: "All", value: "all" }, { label: "Admin", value: ADMIN }, { label: "Team Lead", value: TEAM_LEAD },
                { label: "QA", value: QA }
            ];
            if (props.card.yt_helper_id) {
                option = [...option, { label: "YouTube Team Lead", value: YT_HELPER_LEAD }, { label: "YouTube Helper", value: YT_HELPER }]
            }
            if (chatView.value === "customer") {
                option = [...option, { label: "Customer", value: CUSTOMER }]
            }
            setRoleOptions(option);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.card?.card_id, chatView])

    function loopLoadComments(calledBy = "scrollCalled") {
        setIsLoading(true);
        let chatApi = "";

        if (chatView.value === "team") {
            chatApi = `/api/card/${props.cardId}/team/comments`;
        }
        else {
            if (props.section && props.section === YT_HELPER_SECTION) {
                chatApi = `/api/ythelper/card/${props.cardId}/comments`;
            }
            else {
                chatApi = `/api/card/${props.cardId}/comments`;
            }
        }

        let obj = {
            since: convertLocalToServerTime(new Date(chatView.value === "team" ? teamState.lastUpdated : state.lastUpdated)),
            order: "desc",
            filterChatOf: viewChatOf.value,
            offset,
            calledBy,
            calledFrom: props.calledFrom,
        }

        if (props?.section !== YT_HELPER_SECTION) {
            obj = {
                ...obj,
                note_for_video_number: props?.showVideoChat ? props.showVideoChat : '1',
            }
        }

        setCallLoadFunc(false);
        props.setAnnotationStates && props.setAnnotationStates(prev => ({
            ...prev,
            callChatApi: false
        }));

        axiosClient.get(chatApi, {
            params:
                chatView.value === "team" ? { ...obj, chat_for: (props?.section === YT_HELPER_SECTION ? "ytTeamChat" : "editorTeamChat") }
                    : obj
            ,
        }).then((response) => {
            setIsLoading(false);
            const data = response.data.comments;
            const respCalledBy = response.data.calledBy;
            let annotations = [];
            if (_.isEmpty(data)) {
                setNoComments(true);
                return;
            }

            if (offset === 0) {
                annotations = response.data?.annotations;
            }

            setOffset(offset + data.length);

            let mostRecentTime = Math.max(chatView.value === "team" ? teamState.lastUpdated : state.lastUpdated, parseSqlDateTime(convertServerToLocalTime(data[0].date_added)).getTime());
            chatView.value === "team" ?
                setTeamState(prev => ({
                    ...prev,
                    chats: uniqueComments(respCalledBy === "scrollCalled" ? prev.chats.concat(data) : data.concat(prev.chats)),
                    lastUpdated: mostRecentTime
                }))
                : setState(prev => ({
                    ...prev,
                    comments: uniqueComments(respCalledBy === "scrollCalled" ? prev.comments.concat(data) : data.concat(prev.comments)),
                    lastUpdated: mostRecentTime
                }));

            if (props?.section === YT_HELPER_SECTION) {
                const tempAnno = (annotations && annotations.length !== 0 ? annotations : data).filter(note => note.note_type && note.note_type === "annotation").map(note => {
                    const { geometry, data } = JSON.parse(note.note);
                    return {
                        geometry,
                        data: {
                            ...data,
                            // id:note.note_id
                        },
                        id: note.note_id
                    }
                });
                props.setAnnotationStates && props.setAnnotationStates(prev => ({
                    ...prev,
                    annotation: {},
                    annotations: uniqueAnnotations(props?.annotations.concat(tempAnno))
                }));
            }

        }).catch(err => {
            warningToast("Unable to refresh comments");
            console.error(err);
        });
    }

    function send(calledBy = 'main') {
        let note = calledBy === "reply" ? replyState.comment : comment.data;

        if (!note || note === '') {
            return;
        }
        if (disableBtn) {
            return infoToast("A new comment request has been already made for this comment");
        }


        if (video && video.duration) {
            note = `@VT{${video.currentTime}} ${note}`;
        }

        if (props && props.section === YT_HELPER_SECTION && props.card.ytHelper_card_status === YT_HELPER_IN_DONE
            && state.me.client_type === CUSTOMER) {
            note = `@YT_CUS_CMNT{} ${note}`;
        }

        infoToast("Creating a new comment request");
        setDisableBtn(true);

        let chatApi = "";

        if (chatView.value === "team") {
            chatApi = `/api/card/${props.cardId}/team/comment`;
        }
        else {
            if (props.section && props.section === YT_HELPER_SECTION) {
                chatApi = `/api/ythelper/card/${props.cardId}/comment`;
            }
            else {
                chatApi = `/api/card/${props.cardId}/comment`;
            }
        }

        let obj = {
            card_id: props.cardId,
            note: note
        }
        if (props?.section !== YT_HELPER_SECTION) {
            obj = {
                ...obj,
                note_for_video_number: props?.showVideoChat ? props?.showVideoChat : '1',
            }
        }
        if (calledBy === "reply") {
            obj = {
                ...obj,
                noteParentId: replyState.parentId,
            }
        }


        axiosClient.post(chatApi,
            chatView.value === "team" ? { ...obj, chat_for: (props?.section === YT_HELPER_SECTION ? "ytTeamChat" : "editorTeamChat") }
                : obj
        )
            .then(({ data }) => {
                chatView.value === "team" ?
                    setTeamState(prev => {
                        let newList;
                        if (calledBy === "reply") {
                            let newComment = {
                                note_id: data.id,
                                note: note,
                                date_added: convertLocalToServerTime(new Date()),
                                note_by_id: state.me.client_id,
                                firstname: state.me.firstname,
                                lastname: state.me.lastname,
                                note_parent_id: replyState.parentId
                            };
                            prev.chats.filter(cmnt => cmnt.note_id === newComment.note_parent_id)[0].child.unshift(newComment);
                            return { ...prev };
                        }
                        else {
                            newList = [{
                                note_id: data.id,
                                note: note,
                                date_added: convertLocalToServerTime(new Date()),
                                note_by_id: state.me.client_id,
                                firstname: state.me.firstname,
                                lastname: state.me.lastname,
                                child: []
                            }].concat(prev.chats);

                            return { ...prev, chats: uniqueComments(newList) };
                        }
                    })
                    :
                    setState(prev => {
                        let newList;
                        if (calledBy === "reply") {
                            let newComment = {
                                note_id: data.id,
                                note: note,
                                date_added: convertLocalToServerTime(new Date()),
                                note_by_id: state.me.client_id,
                                firstname: state.me.firstname,
                                lastname: state.me.lastname,
                                note_parent_id: replyState.parentId
                            };
                            prev.comments.filter(cmnt => cmnt.note_id === newComment.note_parent_id)[0].child.unshift(newComment);
                            return { ...prev };
                        }
                        else {
                            newList = [{
                                note_id: data.id,
                                note: note,
                                date_added: convertLocalToServerTime(new Date()),
                                note_by_id: state.me.client_id,
                                firstname: state.me.firstname,
                                lastname: state.me.lastname,
                                child: []
                            }].concat(prev.comments);

                            return { ...prev, comments: uniqueComments(newList) };
                        }
                    });
                setComment(INITIAL_COMMENT_STATE);
                setReplyState(INITIAL_REPLY_STATE);
                successToast("Comment is added successfully");
            }).catch(err => {
                console.error(err);
                errorToast("Unable to post comment, try again later");
            })
            .finally(() => {
                setDisableBtn(false);
            });
    }

    function editComment() {
        let note = comment.data;
        let oldNote = comment.oldComment;
        let noteId = comment.id;

        if (!note || note === '') {
            return;
        }
        if (disableBtn) {
            return infoToast("A new edit comment request has been already made for this comment");
        }


        if (oldNote && oldNote.includes('@VT{') === true) {
            let videoTime = oldNote.match(/^@VT{(.+)}/i);
            if (videoTime && videoTime.length === 2) {
                note = `@VT{${videoTime[1]}} ${note}`;
            }
        }

        if (oldNote && oldNote.includes('@YT_CUS_CMNT{}') === true) {
            note = `@YT_CUS_CMNT{} ${note}`;
        }

        infoToast("Creating a new edit comment request");
        setDisableBtn(true);

        let obj = {
            cardId: props.cardId,
            note: note,
            noteId: noteId,

        }
        if (chatView.value === "team") {
            obj.chatFor = "teamChatComment";
        } else if (props.section && props.section === YT_HELPER_SECTION) {
            obj.chatFor = 'ytClientChat';
        } else {
            obj.chatFor = 'clientChat';
        }

        axiosClient.put(`/api/card/${props.cardId}/comment/${noteId}`, obj)
            .then(({ data }) => {
                if (data.status) {
                    if (chatView.value === "team") {

                        let chats = teamState.chats;
                        chats.filter(comment => comment.note_id === noteId).map(comment => comment.note = note);
                        setTeamState(prev => ({ ...prev, chats: uniqueComments(chats) }));
                    }
                    else {
                        let comments = state.comments;
                        comments.filter(comment => comment.note_id === noteId).map(comment => comment.note = note);
                        setState(prev => ({
                            ...prev,
                            comments: uniqueComments(comments)
                        }));
                    }
                    setComment(INITIAL_COMMENT_STATE);
                    successToast(data.message ?? "Comment edited successfully");
                }
                else {
                    errorToast("Unable to edit comment, try again later");
                }
            }).catch(err => {
                console.error(err);
                errorToast("Unable to edit comment, try again later");
            })
            .finally(() => {
                setDisableBtn(false);
            });
    }

    function onSeek(time) {
        let video = document.querySelector('#preview-vid');
        if (video) {
            video.currentTime = time;
            video.play();
        }
    }

    // when user press enter the comment will send
    function keyPressHandler(e, calledBy = 'main') {
        if (!e.shiftKey && e.key === 'Enter') {
            e.preventDefault();
            if (comment.id > 0) {
                editComment();
            } else {
                send(calledBy);
            }
        }
        e.persist();
    }

    function markCommentDone(cmnt) {
        let commonCommentApi = `/api/card/${props.cardId}/markCommentComplete`;
        let ytHelperCommentApi = `/api/ythelper/card/${props.cardId}/markCommentComplete`;

        axiosClient.post(props?.section === YT_HELPER_SECTION ? ytHelperCommentApi : commonCommentApi, {
            "status": 1,
            "note_id": cmnt.note_id,
            "type": chatView.value,
        }).then(({ data }) => {
            if (data.message) {
                successToast(data.message);
                if (chatView.value === 'team') {
                    let chats = teamState.chats;
                    chats.filter(chat => chat.note_id === cmnt.note_id).map(chat => chat.is_note_complete = 1);
                    setTeamState(prev => ({
                        ...prev,
                        chats: uniqueComments(chats)
                    }));
                } else {
                    let comments = state.comments;
                    comments.filter(comment => comment.note_id === cmnt.note_id).map(comment => comment.is_note_complete = 1);
                    setState(prev => ({
                        ...prev,
                        comments: uniqueComments(comments)
                    }));
                }
            }
        }).catch(err => {
            console.error(err);
            errorToast("Unable to mark comment, please try again later");
        })
    }
    function setBreakNewLines(text) {
        return reactStringReplace(text, "\n", (match, i) => (
            // return reactStringReplace(text, /(\\r?\\n)/g, (match, i) => (
            <br key={match + i} />
        ));
    }

    // for removing the code which is used for yt helper section card's comment mark as done functionality 
    function removeYtCustomerCode(comment) {
        if (comment.search("@YT_CUS_CMNT{}") !== -1) {
            return comment.slice(15);
        }
        return comment;
    }

    function removeVideoTime(text) {
        return text.replace(/^@VT{(.+)}/i,'');
    }

    function handleDeleteComment(comment) {
        if (!comment) {
            return;
        }

        let obj = {
            card_id: props.cardId,
            note_id: comment.note_id,
            delete_note: 1
        }
        let chatApi = "";

        if (chatView.value === "team") {
            chatApi = `/api/card/${props.cardId}/team/deleteComment`;
        }
        else {
            chatApi = `/api/card/${props.cardId}/deleteComment`;
        }

        axiosClient.post(chatApi,
            props?.section === YT_HELPER_SECTION && chatView !== "team" ? { ...obj, section: YT_HELPER_SECTION }
                : obj
        )
            .then(({ data }) => {
                successToast(data.message);
                setOffset(0);
                props.setAnnotationStates && props.setAnnotationStates(prev => ({
                    ...prev,
                    annotation: {},
                    annotations: [],
                    activeAnnotations: []
                }));
                setCallLoadFunc(true);
                chatView.value === "team" ? setTeamState(prev => ({ ...prev, lastUpdated: 0, chats: [] }))
                    : setState(prev => ({ ...prev, lastUpdated: 0, comments: [] }));
            })
            .catch(err => {
                console.error(err);
            })
    }

    function showChatOption(isChildCmnt, cmnt, annotationData) {
        if (!isChildCmnt) {
            return true;
        } else if (!cmnt.is_note_complete && !cmnt.note_type && props?.section !== YT_HELPER_SECTION && cmnt.client_type === CUSTOMER && cmnt.note.search("@VT{") !== -1
            && props.me.client_type !== CUSTOMER) {
            return true;
        } else if (!cmnt.is_note_complete && props?.section === YT_HELPER_SECTION && cmnt.client_type === CUSTOMER &&
            (annotationData ? annotationData.data.text : cmnt.note.search("@YT_CUS_CMNT{}") !== -1) && props.me.client_type !== CUSTOMER) {
            return true;
        } else if (props.me.client_id === cmnt.note_by_id || (props.me.client_type === ADMIN && cmnt.client_type !== CUSTOMER)) {
            return true;
        } else {
            return false;
        }
    }

    function noteReaction(cmnt) {
        document.getElementById('thumbs-up').style.color = cmnt.note_reaction ? 'black' : 'red';
        let commonCommentApi = `/api/card/${props.cardId}/noteReaction`;
        let ytHelperCommentApi = `/api/ythelper/card/${props.cardId}/noteReaction`;

        axiosClient.post(props?.section === YT_HELPER_SECTION ? ytHelperCommentApi : commonCommentApi, {
            "status": cmnt.note_reaction ? 0 : 1,
            "note_id": cmnt.note_id,
            "type": chatView.value,
            "section": props?.section === YT_HELPER_SECTION ? 'ytHelperChat' : 'editorChat',
        }).then(({ data }) => {
            if (data.message) {
                successToast(data.message);
                let comments = state.comments;
                comments.filter(comment => comment.note_id === cmnt.note_id).map(comment => comment.note_reaction = 1);
                setState(prev => ({
                    ...prev,
                    comments: uniqueComments(comments)
                }));
            }
        }).catch(err => {
            console.error(err);
            errorToast("Unable to React on comment, please try again later");
        })
    }

    function getChatOptionList(isChildCmnt, cmnt, annotationData) {
        return <ul className="inside-tog-drpdwn">
            {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                !isChildCmnt ? <li><a onClick={() => setReplyState(prev => ({ ...prev, showReplyField: true, parentId: cmnt.note_id }))}>Reply</a></li> : null
            }
            {
                (!cmnt.is_note_complete && !cmnt.note_type && props?.section !== YT_HELPER_SECTION && props.me.client_type !== CUSTOMER) ?
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <li><a onClick={() => markCommentDone(cmnt)}>Mark as Done</a></li>
                    : null
            }
            {
                (!cmnt.is_note_complete && props?.section === YT_HELPER_SECTION && props.me.client_type !== CUSTOMER) ?
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <li><a onClick={() => markCommentDone(cmnt)}>Mark as Done</a></li>
                    : null
            }
            {
                (props.me.client_id === cmnt.note_by_id || (props.me.client_type === ADMIN && cmnt.client_type !== CUSTOMER)) ?
                    [
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <li key={'comment-edit-key-id-' + cmnt.note_id}><a onClick={() => {
                            let comment = cmnt.note;
                            comment = removeVideoTime(removeYtCustomerCode(cmnt.note));
                            setComment({id:cmnt.note_id, data:comment, oldComment:cmnt.note});
                        }}>Edit</a></li>,
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <li key={'comment-delete-key-id-' + cmnt.note_id}><a onClick={() => setDeleteConfirmation({ open: true, comment: cmnt })}>Delete</a></li>
                    ] : null
            }
        </ul>
    }

    function handleChat(chat) {
        setChatView(chat);
        setOffset(0);
        setChatView(chat);
        setViewChatOf({ label: "All", value: "all" });
    }

    return (
        <Container>
            <ConfirmationDialog
                onNegative={() => { setDeleteConfirmation({ open: false }) }}
                onPositive={() => handleDeleteComment(deleteConfirmation.comment)}
                confirmationHeader="Confirm"
                confirmationDialogId={"confirm-move"}
                confirmation={deleteConfirmation.open}
                confirmationText={"Are you sure you want to delete this comment?"}
            />
            <Row className="chat-header-row">
                <Col s={12}><h5><MdComment style={{ float: 'left', marginTop: '2px', marginRight: '5px', marginBottom: '10px' }} size='25px' />Chat</h5></Col>
                <Col s={7}>
                    <div>
                        {
                            props.me.client_type !== CUSTOMER && <div className={chatView.value === "team" ? "team-chat-select chat-select-video client-type-select" : "chat-select chat-select-video client-type-select"}>
                                <div className="btn-container">
                                    <Button className="btn-customer" tooltip={`${chatView.value === 'customer' ? 'Selected' : 'Click to Select'}`} disabled={chatView.value === 'customer' ? true : false} onClick={() => handleChat({ label: "Customer Chat", value: "customer" })}>Customer Chat</Button>
                                    <Button className="btn-team" tooltip={`${chatView.value === 'team' ? 'Selected' : 'Click to Select'}`} disabled={chatView.value === 'team' ? true : false} onClick={() => handleChat({ label: "Team Chat", value: "team" })}>Team Chat</Button>
                                </div>
                            </div>
                        }
                    </div>
                </Col>
                {/* <Col className={"chat-annotation-label"}>
                    
                </Col> */}
                <Col
                    className="chat-select-video" 
                    s={5}>
                    <Select
                        value={viewChatOf}
                        options={roleOptions}
                        isSearchable={false}
                        onChange={(e) => {
                            setOffset(0);
                            setViewChatOf(e);
                            chatView.value === "team" ?
                                setTeamState(prev => ({ ...prev, lastUpdated: 0, chats: [] }))
                                :
                                setState(prev => ({ ...prev, lastUpdated: 0, comments: [] }));
                        }
                        }
                    />
                </Col>
                <Col s={12}>
                    <div className={"chat-annotation-label"}>
                        {
                            props?.section === YT_HELPER_SECTION ?
                                <label>
                                    <span className="annotation-label-colored-circle" /><span className="annotation-span"> Annotation </span>
                                    <span className="yt-chat-label-colored-circle" /><span className="chat-span"> Comment </span>
                                </label> : null
                        }
                    </div>
                </Col>
            </Row>

            <Row className="comment_area">
                <Row className="text-area_chat">
                    <Textarea s={12} value={comment.data}
                        onFocus={() => {
                            video && !video.paused && video.pause();
                        }}
                        // for track enter button
                        onKeyPress={(e) => keyPressHandler(e)}

                        onChange={e => {let temp = e.target.value; setComment(prev => ({ ...prev, data: temp}))}}
                        icon={<Icon>short_text</Icon>}
                        // placeholder={props?.section === YT_HELPER_SECTION ? "Type @Admin/@YTHelper/@Client to tag them!" : "Type @Admin/@Qa/@Editor/@YTHelper/@Client to tag them!"}
                        placeholder={"Please use shift + enter to add line break"}
                    />
                    <Col className="submit_chat"><Button icon={<Icon left>send</Icon>} onClick={() => comment.id > 0 ? editComment() : send()} disabled={disableBtn} large flat /></Col>
                </Row>
                <div className="chat-cover">
                    {
                        (chatView.value === "team" ? teamState.chats : state.comments) && (chatView.value === "team" ? teamState.chats : state.comments).length !== 0 &&
                        <InfiniteScroll
                            pageStart={0}
                            hasMore={true}
                            useWindow={false}
                            loadMore={() => loopLoadComments()}
                            initialLoad={false}
                        >
                            {
                                // Remove reverse() function to display last chat on top 
                                (chatView.value === "team" ? teamState.chats : state.comments)
                                    //.slice((index-1)*10, index*10)
                                    .map(cmnt => {
                                        let myComment = cmnt.note_by_id === state.me.client_id;
                                        let annotationData;
                                        if (cmnt.note_type && cmnt.note_type === "annotation") {
                                            annotationData = JSON.parse(cmnt.note);
                                        }
                                        return (<Row key={cmnt.note_id} className={myComment ? "common_chat align-right" : "common_chat align-left"}
                                            onMouseOver={props.onAnnotationMouseOver ? props.onAnnotationMouseOver(cmnt.note_id) : null}
                                            onMouseOut={props.onAnotationMouseOut ? props.onAnotationMouseOut(cmnt.note_id) : null}
                                        >
                                            {
                                                props?.section === YT_HELPER_SECTION ? annotationData ? <span className="annotation-colored-circle annotation-circle main-annotation-circle" /> : <span className="yt-chat-colored-circle annotation-circle main-annotation-circle" /> : null
                                            }
                                            <Col className="caht_   box_thumb">
                                                <img src={cmnt.profile_img || Profile} alt="avatar"
                                                    style={{ pointerEvents: 'none', userSelect: "none", verticalAlign: "middle", width: "36px", height: "36px", borderRadius: "50%", marginTop: "5px" }} />
                                            </Col>
                                            <Col s={9} className={myComment ? "m_box_me" : "m_box"}>
                                                <div className="text-left">
                                                    <p className={`chat_bubble ${cmnt.is_deleted && "deletedComment"}`}>
                                                        <b>{cmnt.firstname} {cmnt.lastname}</b> on <time>{toISODateTime(cmnt.date_added)}</time>
                                                        {
                                                            cmnt.client_type === CUSTOMER ?
                                                            <FontAwesomeIcon style={{ color: cmnt.note_reaction ? 'red' : 'black' }} id="thumbs-up" icon={faThumbsUp} onClick={() => noteReaction(cmnt)} />
                                                            : null
                                                         }
                                                        <br />
                                                        {
                                                            annotationData && annotationData?.data !== "" ? annotationData.data.text :
                                                                makeLinks(setBreakNewLines(highlightTags(parseVideoTime(removeYtCustomerCode(cmnt.note), onSeek))))
                                                        }
                                                    </p>
                                                </div>
                                            </Col>
                                            {
                                                replyState.showReplyField && replyState.parentId === cmnt.note_id
                                                    ?
                                                    <div className="cmnt-reply-main-div">
                                                        <Textarea s={12} value={replyState.comment}
                                                            onFocus={() => {
                                                                video && !video.paused && video.pause();
                                                            }}
                                                            // for track enter button
                                                            onKeyPress={(e) => keyPressHandler(e, 'reply')}

                                                            onChange={e => {
                                                                let value = e.target.value;
                                                                setReplyState(prev => ({ ...prev, comment: value }))
                                                            }}
                                                            icon={<Icon>short_text</Icon>}
                                                            placeholder={"Please use shift + enter to add line break"}
                                                        />
                                                        <Col className="submit_reply_chat">
                                                            <Button icon={<Icon left>send</Icon>} onClick={() => comment.id > 0 ? editComment() : send("reply")} disabled={disableBtn} />
                                                            <Button icon={<Icon left>close</Icon>} onClick={() => setReplyState(INITIAL_REPLY_STATE)} disabled={disableBtn} />
                                                        </Col>
                                                    </div> : null
                                            }

                                            {!cmnt.is_deleted ?
                                                <div className="chat-option-btn">
                                                    <div className="toggle-dots">
                                                        <div className="inside-toggle" onClick={() => setShowToggleState(prev => ({ ...prev, showToggle: !prev.showToggle, parentId: cmnt.note_id }))}
                                                            onBlur={() => setShowToggleState(prev => ({ ...prev, showToggle: !prev.showToggle, parentId: 0 }))}
                                                        >
                                                            {
                                                                showToggleState.showToggle && showToggleState.parentId === cmnt.note_id ?
                                                                    getChatOptionList(false, cmnt, annotationData) : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : null
                                            }
                                            {
                                                cmnt.is_note_complete
                                                    ?
                                                    <div className="mark-comment">
                                                        <FontAwesomeIcon icon="check" color="green" size="2x" />
                                                    </div> : null

                                            }
                                            {
                                                cmnt.child && cmnt.child.length > 0 ?
                                                    cmnt.child.map(cmnt => {
                                                        let myComment = cmnt.note_by_id === state.me.client_id;
                                                        return (
                                                            <Row key={cmnt.note_id} className={myComment ? "common_child_chat align-child-right" : "common_child_chat align-child-left"} >
                                                                {
                                                                    props?.section === YT_HELPER_SECTION ? annotationData ? <span className="annotation-colored-circle annotation-circle" /> : <span className="yt-chat-colored-circle annotation-circle" /> : null
                                                                }
                                                                <Col className="caht_   box_thumb">
                                                                    <img src={cmnt.profile_img || Profile} alt="avatar"
                                                                        style={{ pointerEvents: 'none', userSelect: "none", verticalAlign: "middle", width: "36px", height: "36px", borderRadius: "50%", marginTop: "5px" }} />
                                                                </Col>
                                                                <Col s={9} className={myComment ? "child_m_box_me" : "child_m_box"}>
                                                                    <div className="child-text-left">
                                                                        <p className={`child_chat_bubble ${cmnt.is_deleted && "deletedComment"}`}>
                                                                            <b>{cmnt.firstname} {cmnt.lastname}</b> on <time>{toISODateTime(cmnt.date_added)}</time>
                                                                            <br />
                                                                            {
                                                                                setBreakNewLines(makeLinks(highlightTags(parseVideoTime(removeYtCustomerCode(cmnt.note), onSeek))))
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </Col>
                                                                {
                                                                    !cmnt.is_deleted && showChatOption(true, cmnt, annotationData) ?
                                                                        <div className="chat-child-option-btn">
                                                                            <div className="toggle-dots">
                                                                                <div className="inside-toggle" onClick={() => setShowToggleState(prev => ({ ...prev, showToggle: !prev.showToggle, parentId: cmnt.note_id }))}
                                                                                    onBlur={() => setShowToggleState(prev => ({ ...prev, showToggle: !prev.showToggle, parentId: 0 }))}
                                                                                >
                                                                                    {
                                                                                        showToggleState.showToggle && showToggleState.parentId === cmnt.note_id ?
                                                                                            getChatOptionList(true, cmnt, annotationData) : null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    cmnt.is_note_complete
                                                                        ?
                                                                        <div className="mark-comment">
                                                                            <FontAwesomeIcon icon="check" color="green" size="2x" />
                                                                        </div> : null

                                                                }
                                                            </Row>
                                                        )
                                                    })
                                                    : null
                                            }

                                        </Row>);
                                    })
                            }
                             { isLoading  && <ReactLoading type="bars" className="chat-loading"  />} 
                        </InfiniteScroll>
                    }

                    {
                    noComments && ( chatView.value === "team" ? teamState?.chats : state?.comments)?.length === 0 &&
                    <Col className="no-comment-label" >
                        <label>No Comments Found</label>
                    </Col>
                }
                </div>
            </Row>
        </Container>
    );
}
