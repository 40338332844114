import React, { useEffect, useState } from 'react';
import { Typography, Button, Divider, Row, Col, Avatar, Tooltip, Spin, Select, DatePicker } from 'antd';
import { TextInput, Modal as MaterialModal, Button as MaterialButton } from "react-materialize";
import { BsFillInfoCircleFill } from 'react-icons/bs';
import ConfirmationDialog from "../../../ConfirmationDialog";
import styles from './DetailsTabStyle.module.scss';
import { CloseOutlined, DownloadOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import AssignedToIcon from '../../../../components/img/assigned_to_icon.png';
import DueOnIcon from '../../../../components/img/due_on_icon.png';
import RawVideoLinkIcon from '../../../../components/img/raw_video_link_icon.png';
import AspectRatioIcon from '../../../../components/img/aspect_ratio_icon.png';
import ContentTypeIcon from '../../../../components/img/content_type_icon.png';
import VideoInfoIcon from '../../../../components/img/video_info_icon.png';
import VideoFormatIcon from '../../../../components/img/video_format_icon.png';
import CallToActionIcon from '../../../../components/img/call_to_action_icon.png';
import MobileFormatIcon from '../../../../components/img/mobile_link.png'
import MobileEyeIcon from '../../../../components/img/mobile-eye-icon.png';
import MobileCalenderIcon from '../../../../components/img/mobile-calender.png';
import { ADMIN, CUSTOMER, TEAM_LEAD, VIDEO_REQUEST, WEEKLY, YT_HELPER, YT_HELPER_IN_DONE, YT_HELPER_IN_PROGRESS, YT_HELPER_LEAD } from '../../../../api/Constants';
import { cardInProgress, dateFormatISO, errorToast, infoToast, isAdmin, isCustomer, parseSqlDateTime, successToast, warningToast, S3_BUCKET, myBucket, getUserShortName, isMobile, convertToCSTTimezone } from '../../../../api/Util';
import { axiosClient } from '../../../../api/httpClient';
import './DetailsTabStyle.scss';
import MobileModal from '../../../common/MobileModal/MobileModal';
import { getCardDetail } from '../../../../services/cardsService';
import VideoPlayTab from '../../../common/VideoPlayTab';
import DetailsHeader from '../DetailsTabHeader/DetailsHeader';

const DetailsTab = ({ card, setCardDetail, currentVideoUrl, section, fetchCardDetails }) => {
    const INITIAL_STATE = {
        card: {
            card_title: "Card Details"
        },
        videoLink: '',
        videoVersions:{},
        videoVersionsCount:0,
    };

    const MODAL_MOBILE_DATA = {
        isModalOpen: false,
        title: "",
        modalContent: "",
        type: "",
    };

	
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [changeDoneVideoUrlState, setChangeDoneVideoUrlState] = useState({isEditing: false});
    const [changeRepurposeVideoUrlState, setChangeRepurposeVideoUrlState] = useState({isEditing: false});
    const [changeContentIdState, setChangeContentIdState] = useState({isContentIdEditing: false});
    const [showMusicGuide, setShowMusicGuide] = useState(false);
    const [editSrtFile, setEditSrtFile] = useState({isEditing: false});
    const [selectedFile, setSelectedFile] = useState(null);
    const [videoVersionsState, setVideoVersionsState] = useState({currentVersion:null,options:[]});
    const [state, setState] = useState(INITIAL_STATE);
    const [options, setOptions] = useState([]);
    const [assignQa, setAssignQa] = useState({ value: `${state.card?.assigned_qa_id}`, label: `${getUserShortName(state.card?.assigned_to)}` } || "0");
    const [updatedDueDate, setUpdatedDueDate] = useState();
    const [mobileModalData, setMobileModalData] = useState(MODAL_MOBILE_DATA);
    const [type, setType] = useState("");
    const [changeUnlistedVideoUrlState, setChangeUnlistedVideoUrlState] = useState({isEditing: false});
    const [changeVideoThumbnailLink, setChangeVideoThumbnailLink] = useState({isEditing: false});
    const [changeSnippetVideoLink, setChangeSnippetVideoLink] = useState({isEditing: false});
    const [ytConfirmationOpen, setYTConfirmationOpen] = useState(false);
    const [assignYT, setAssignYT] = useState({value : `${state.card?.yt_helper_id}`,label:`${getUserShortName(state.card?.yt_assigned_to)}`});
    const props = useSelector((state) => state)
    const loggedInUser = props.auth?.loggedInUser;
    const viewType = localStorage.getItem("viewType");
    const isLongText = card?.additional_info.length > 100;
    const websiteUsers = useSelector(state => state?.allWebsiteUsers?.website_users);

    useEffect(() => {
        let videoVersionsCount = 0;
        let videoVersions = [];

        if ( card?.repurpose_video_versions && Object.keys(card?.repurpose_video_versions).length > 0) {
            videoVersions = card?.repurpose_video_versions;
            videoVersionsCount = Object.keys(card?.repurpose_video_versions).length;
        } else if ( card?.video_versions && Object.keys(card?.video_versions).length > 0) {
            videoVersions = card?.video_versions;
            videoVersionsCount = Object.keys(card?.video_versions).length;
        }
        setState({
            card: card,
            dueDate: viewType === "yt-helper" ? ( card?.yt_due_date ? parseSqlDateTime(card?.yt_due_date) : null ) : ( card?.due_date ? parseSqlDateTime(card?.due_date) : null ),
            videoLink:card?.repurpose_done_link ? card.repurpose_done_link : card?.done_video_link,
            videoVersions,
            videoVersionsCount,
        });
        setAssignQa({ value: `${card?.assigned_qa_id}`, label: `${getUserShortName(card?.assigned_to)}` });
        setAssignYT({ value: card?.yt_helper_id, label: getUserShortName(card?.yt_assigned_to)} || "0");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [card])

    useEffect(() => {
        var option;
        if (viewType === "normal") {
            option = (websiteUsers || [])?.filter(user => user.client_type === "qa" || user.client_type === "team-lead").map(user => {
                return {
                    value:`${user.client_id}` , 
                    label:`${getUserShortName(user.fullname)}`
                }
            })
            option = [{value:"0" ,label:"None"},...option];
            setOptions(option);

        } else if (viewType === "yt-helper") {
            option = (websiteUsers || [])?.filter(user => user.client_type === YT_HELPER || user.client_type === YT_HELPER_LEAD).map(user => {
                return {
                    value:`${user.client_id}` , 
                    label:`${getUserShortName(user.fullname)} (${user.client_type})`
                }
            })
            option = [{value:"0" ,label:"None"},...option];
            setOptions(option);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [section, websiteUsers])

    useEffect(() => {
        if (state.videoVersions && state.videoVersionsCount > 0) {
            let options = [];
            let versions = Object.keys(state.videoVersions);

            options = (versions || []).map((v, i) => {
                return <option key={'option-' + v + '-key'} value={v}>{`versions ${i + 1}`}</option>
            });
            setVideoVersionsState(prev => ({ ...prev, currentVersion: versions[state.videoVersionsCount - 1], options }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.videoVersions])


    const handleMapDoneVideos = () => {
        let tempFields = [];
        let videos = card?.video_versions[`version-${ Object.keys(card?.video_versions).length - 1 }`];
        videos && videos.forEach((data, i) => {
            tempFields.push(
                <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                    <Col span={isMobile ? 14 : 8} className={styles.iconsAndText}>
                        <img src={RawVideoLinkIcon} className={styles.iconsStyle} alt="DoneVideoLinkIcon" />
                        <Typography.Text className={styles.headingFontStyle}>
                            Done Video Link { videos.length > 1 ? i + 1 :null} :
                        </Typography.Text>
                    </Col>
                    <div style={{ display: 'flex', width: "100%", justifyContent: isMobile ? "flex-end" : "flex-start", alignItems: "center"}}>
                        {
                            !isMobile &&
                            <Col span={12} className={styles.deskMode}>
                                <Typography.Text className={styles.greyFontColor}>
                                    <VideoPlayTab
                                        dataTip={"Click to view video"}
                                        videoUrl={data.video_url}
                                        cardName={state?.card?.card_title} />
                                </Typography.Text>
                            </Col>
                        }
                        <Col span={isMobile ? 9 : 2} className={styles.videoDownloadIcon}>
                            {
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a className={styles.downloadLink} href={data?.video_url} download>
                                    <Button type="primary" icon={<DownloadOutlined />} className={styles.downloadBtn} size={'large'} />
                                </a>
                            }
                        </Col>
                        {
                            loggedInUser.client_type !== CUSTOMER ?
                            <Col span={isMobile ? 9 : 2} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                                <div className="btn-side">
                                    {
                                        <Button type="primary" icon={<EditOutlined />} size={'large'} style={{ backgroundColor: '#ab322d' }} className={styles.editOutlinedBtn} tooltip="Change Done Video Link" onClick={() => {
                                            setChangeDoneVideoUrlState({
                                                isEditing: true,
                                                oldUrl: data.video_url,
                                                oldVideoIndex: i,
                                                oldVideoId: data.video_id,
                                                oldUrlType: data.audio_video_type,
                                                newUrlType: data.audio_video_type,
                                            });
                                            setChangeRepurposeVideoUrlState({
                                                isEditing: false
                                            });
                                            setChangeUnlistedVideoUrlState({
                                                isEditing: false
                                            });
                                            }} >
                                        </Button>
                                    }
                                </div>
                            </Col>
                            : null
                        }
                        {isMobile &&
                            <Col span={4} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                                <Typography.Text className={styles.greyFontColor}>
                                    <img src={MobileFormatIcon} className={styles.mobileMode} onClick={() => {
                                            setMobileModalData({ isModalOpen: true, title: "Done Video Link", modalContent: data.video_url, type: "link" })
                                        }} alt="RawVideoLinkIcon" />
                                </Typography.Text>
                            </Col>
                        }
                    </div>
                </div>
            );
        });
        return tempFields;
    }

    const mapMultipleFields = (dataArray,fieldName) => {
        let tempFields = [];
        let dataLength = dataArray.length;
        dataLength > 0 && dataArray.forEach((data, i) => {
            tempFields.push(
                <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                    <Col span={isMobile ? 14 : 8} className={styles.iconsAndText}>
                        <img src={RawVideoLinkIcon} className={styles.iconsStyle} alt="RepurposeVideoLinkIcon" />
                        <Typography.Text className={styles.headingFontStyle}>
                            {fieldName} {dataLength > 1 ? i + 1 :null}:
                        </Typography.Text>
                    </Col>
                    <div style={{ display: 'flex', width: "100%", justifyContent: isMobile ? "flex-end" : "flex-start", alignItems: "center"}}>
                        {
                            !isMobile &&
                                <Col span={12}>
                                    <Typography.Text className={styles.greyFontColor}>
                                        <VideoPlayTab
                                            dataTip={"Click to view video"}
                                            videoUrl={data.video_url}
                                            cardName={state?.card?.card_title} />
                                    </Typography.Text>
                                </Col>
                        }
                        <Col span={isMobile ? 9 : 2} className={styles.videoDownloadIcon}>
                            {
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a className={styles.downloadLink} href={data?.video_url} download>
                                    <Button type="primary" icon={<DownloadOutlined />} className={styles.downloadBtn} size={'large'} />
                                </a>
                            }
                        </Col>
                        {
                            loggedInUser.client_type === ADMIN || loggedInUser.client_type === TEAM_LEAD ?
                            <Col span={isMobile ? 9 : 2} className={styles.videoDownloadIcon}>
                                <div className="btn-side edit-video-btn-div">
                                    {
                                        <Button type="primary" icon={<EditOutlined />} size={'large'} style={{ backgroundColor: '#ab322d' }} className={styles.editOutlinedBtn} tooltip="Change Repurpose Video Link" onClick={() => {
                                            setChangeRepurposeVideoUrlState({
                                                isEditing: true,
                                                oldUrl: data.video_url,
                                                oldVideoIndex: i,
                                                oldVideoId: data.video_id,
                                                oldUrlType: data.audio_video_type,
                                                newUrlType: data.audio_video_type,
                                            });
                                            setChangeDoneVideoUrlState({
                                                isEditing: false
                                            });
                                            setChangeUnlistedVideoUrlState({
                                                isEditing: false
                                            });
                                            }} >
                                        </Button>
                                    }
                                </div>
                           </Col> : null
                        }
                        {
                            isMobile &&
                                <Col span={4} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                                    <Typography.Text className={styles.greyFontColor}>
                                        <img src={MobileFormatIcon} className={styles.mobileMode} onClick={() => {
                                                setMobileModalData({ isModalOpen: true, title: fieldName, modalContent: data?.video_url, type: "link" })
                                            }} alt="RawVideoLinkIcon" />
                                    </Typography.Text>
                                </Col>
                        }
                    </div>
                </div>
            );
        });
        return tempFields;
    }

    function makeUserSelection(qas) {
        return <div>
            <ConfirmationDialog
                key="confirm-assign-qa"
                onNegative={() => setConfirmationOpen(false)}
                onPositive={onConfirmChangeQa}
                confirmationHeader="Confirmation"
                confirmationDialogId="confirm-assign-qa-dialog"
                confirmation={confirmationOpen}
                confirmationText="Are you sure you want to change assigned QA?"
            />
            <Select
                className={`${styles.qaSelectBox} selectBox`}
                classNamePrefix="select"
                value={assignQa.value === 'null' || null || 0 ? { value:'0', label:'None'} : assignQa}
                options = {options}
                onChange={e => { setAssignQa(e); setConfirmationOpen(true); }}>
            </Select>
        </div>;
    }

    function changeYTHelper() {
        return <div>
            <ConfirmationDialog
                key="confirm-assign-yt"
                onNegative={() => setYTConfirmationOpen(false)}
                onPositive={onConfirmChangeYTHelper}
                confirmationHeader="Confirmation"
                confirmationDialogId="confirm-assign-yt-dialog"
                confirmation={ytConfirmationOpen}
                confirmationText="Are you sure you want to change assigned YT Helper?"
            />
            <Select
                className={`${styles.qaSelectBox} selectBox`}
                classNamePrefix="select"
                    value={assignYT === 'null' || null || 0 ? { value:'0', label:'None'} : assignYT}
                    options = {options}
                    onChange={e => { setAssignYT(e); setYTConfirmationOpen(true); }}>
            </Select>
        </div>;
    }

    function onConfirmChangeQa() {
        infoToast("Changing assigned QA");
        axiosClient.post('/api/card/assign', { card_id: card.card_id, assign_to: Number(assignQa)})
            .then(async ({data}) => {
                if(data.success) {
                    updateCardDetail();
                    successToast(data.message);
                } else {
                    errorToast(data.message);
                }
            });
    }
    
    function onConfirmChangeYTHelper() {
        infoToast("Changing assigned YT Helper");
        axiosClient.post('/api/ythelper/card/assign', { card_id: card.card_id, assign_to: Number(assignYT)})
            .then(({data}) => {
                if(data.success) {
                    successToast(data.message);
                } else {
                    errorToast(data.message);
                }
            });
    }

    function makeQaAndDueDateRow() {
        if(isAdmin(props.auth.loggedInUser) || props.auth.loggedInUser.client_type === YT_HELPER_LEAD) {
            return React.Children.toArray([
                <Row className ="bottom_row">
                    <div className="inside_linkg">
                        {viewType === "yt-helper" ? changeYTHelper() : makeUserSelection(props.users.qas)}
                    </div>
                </Row>,
            ]);
        }
    }

    async function updateDueDate(e) {
        setState(prev => ({...prev, dueDate: dateFormatISO(e.$d)}));
        if(!dateFormatISO(e.$d)) {
            warningToast("Please select a valid due date");
            return;
        }

        let newDueDate = dateFormatISO(e.$d);

        const commenCardApi = '/api/card/updateDueDate';
        // for yt helper card due date
        const ytCardApi = '/api/ythelper/card/updateDueDate';

        await axiosClient.post(viewType === "yt-helper" ? ytCardApi : commenCardApi , {card_id: state.card.card_id, due_date: newDueDate})
            .then(async () => {
                updateCardDetail();
                setUpdatedDueDate(newDueDate);
                successToast(`Card ${state.card.card_title} due date changed to ${newDueDate}`);
            }).catch(err => {
                errorToast('Something went wrong, please try again');
                console.error(err);
            })
    }

    const updateCardDetail = async () => {
        const data = await getCardDetail(card.card_id);
        if (data.status) {
            setCardDetail(data.data);
        } else {
            errorToast(data.error);
        }
    }

    const handleDoneVideoChange = ()=>{
        if (changeDoneVideoUrlState.isEditing && !changeDoneVideoUrlState.newVideoLink) {
            return;
        }
        if (changeRepurposeVideoUrlState.isEditing && !changeRepurposeVideoUrlState.newVideoLink) {
            return;
        }
        if (changeUnlistedVideoUrlState.isEditing && !changeUnlistedVideoUrlState.newVideoLink) {
            return;
        }
        if (changeVideoThumbnailLink.isEditing && !changeVideoThumbnailLink.newVideoLink) {
            return;
        }
        if (changeSnippetVideoLink.isEditing && !changeSnippetVideoLink.newVideoLink) {
            return;
        }
        let obj;
        if (changeRepurposeVideoUrlState.isEditing) {
            obj = {
                card_id: card.card_id,
                done_video_link: changeRepurposeVideoUrlState.newVideoLink,
                video_id: changeRepurposeVideoUrlState.oldVideoId,
                type: 'repurpose',
            };
        } else if (changeDoneVideoUrlState.isEditing) {
            obj = {
                card_id: card.card_id, 
                done_video_link: changeDoneVideoUrlState.newVideoLink,
                video_id: changeDoneVideoUrlState.oldVideoId,
                type: 'done',
            };
        } else if (changeUnlistedVideoUrlState.isEditing) {
            obj = {
                card_id: card.card_id,
                unlisted_video_link: changeUnlistedVideoUrlState.newVideoLink,
                type: YT_HELPER_IN_DONE,
            };
        } else if (changeVideoThumbnailLink.isEditing) {
            obj = {
                card_id: card.card_id,
                thumbnails_link: changeVideoThumbnailLink.newVideoLink,
                type: YT_HELPER_IN_DONE,
            };
        } else if (changeSnippetVideoLink.isEditing) {
            obj = {
                card_id: card.card_id,
                yt_snippet_link: changeSnippetVideoLink.newVideoLink,
                type: YT_HELPER_IN_DONE,
            };
        }
        
        if (changeDoneVideoUrlState.oldUrl === state.card.done_video_link || changeRepurposeVideoUrlState.oldUrl === state.card.repurpose_done_link) {
            obj = {...obj,updateBothTable:true};
        } else {
            obj = {...obj,updateBothTable:false};
        }

        axiosClient.post("/api/card/changeDoneVideo",obj)
            .then(({data})=>{
                successToast(data?.message);
                setChangeDoneVideoUrlState({ isEditing: false });
            })
            .catch((err)=>{
                errorToast(err?.response?.data?.message || "Something went wrong, please try again");
            })
    }

    const handleDoneContentIdChange = () => {
        let obj = {
            card_id: card.card_id, 
            contentId: changeContentIdState.oldContentId
        };
        
        axiosClient.post("/api/card/changeContentId",obj)
            .then(({data})=>{
                successToast(data?.message);
                setChangeContentIdState({ isContentIdEditing: false });
            })
            .catch((err)=>{
                errorToast(err?.response?.data?.message || "Something went wrong, please try again");
            })
    }

    const onChangeFile = (e) => {
        let value = e.target.files[0];

        const params = {
            ACL: 'public-read',
            Body: value,
            Bucket: S3_BUCKET,
            Key: 'prod/media/srt/'+value.name
        };
        myBucket.upload(params,
            (err, data) => {
            if (err) {
                return err;
            } else {
                let temp = data?.Key ? "https://vidchops-media.s3.amazonaws.com/"+ data.Key : null;
                setSelectedFile(temp);
            }
        })
    }

    const clearFields = () => {
        setMobileModalData(MODAL_MOBILE_DATA)
    }

    const handleCancelMobileModal = () => {
        setMobileModalData(MODAL_MOBILE_DATA)
    }
  
    const getDueOnModalContent = () => {
        let dueDateWithTimezone;
        if (card?.timezone === "") {
            if (updatedDueDate || viewType === "yt-helper")  {
                dueDateWithTimezone = <div>{convertToCSTTimezone(card?.yt_due_date)}</div>
            } else {
                dueDateWithTimezone = <div>{convertToCSTTimezone(card?.due_date)}</div>
            }
        } else {
            if (updatedDueDate || viewType === "yt-helper")  {
                dueDateWithTimezone = <div>{convertToCSTTimezone(card?.convertedYTHelperTime)}</div>
            } else {
                dueDateWithTimezone = <div>{convertToCSTTimezone(card?.convertedTime)}</div>
            }
        }
        return dueDateWithTimezone;
    }

    return (
        <div>
            {card ?
                <div>
                   <DetailsHeader card={card} fetchCardDetails={fetchCardDetails} />
                    <Divider className="detailsTabDivider" />
                    <Row gutter={[16, 16]} style={{ marginRight: 0 }}>
                        <div className={styles.assignedToWrapper} style={{display: "flex", width: "100%"}}>
                            <Col span={isMobile ? 10 : 8} className={styles.iconsAndText}>
                                <img src={AssignedToIcon} className={styles.iconsStyle} alt="AssignedToIcon" />
                                <Typography.Text className={styles.headingFontStyle}>
                                    Assigned to:
                                </Typography.Text>
                            </Col>
                            {isMobile &&
                                    makeQaAndDueDateRow()
                            }
                            <Col span={isMobile ? 6 : 16}  className={styles.colStyle} style={{ justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                <Tooltip title={
                                            card?.client_firstname || card?.client_lastname ? `${card?.client_firstname || ''} ${card?.client_lastname || ''}` : 'N/A'
                                        } placement="top">
                                    <Avatar className={styles.avatarStyle} style={{ backgroundColor: '#f56a00' }}>
                                        {card?.client_firstname || card?.client_lastname ?
                                            `${card?.client_firstname.toUpperCase().charAt(0)}${card?.client_lastname.toUpperCase().charAt(0)}` : 'N/A'}
                                    </Avatar>
                                </Tooltip>
                                <Tooltip title={
                                    card?.qa_firstname || card?.qa_lastname ? getUserShortName(`${card?.qa_firstname || ''} ${card?.qa_lastname || ''}`) : 'N/A'
                                } placement="top">
                                    <Avatar className={styles.avatarStyle} style={{ backgroundColor: '#87d068' }}>
                                        {card?.qa_firstname || card?.qa_lastname ? 
                                            `${card?.qa_firstname.toUpperCase().charAt(0)}${card?.qa_lastname.toUpperCase().charAt(0)}` : 'N/A'}
                                    </Avatar>
                                </Tooltip>
                                {!isMobile &&
                                    makeQaAndDueDateRow()
                                }
                            </Col>
                        </div>
                        {
                            (viewType === "yt-helper" ? state.card?.yt_due_date && state.card?.ytHelper_card_status !== YT_HELPER_IN_DONE :  
                                state.dueDate && cardInProgress(state.card) ) ?
                                <>
                                    <Col span={isMobile ? 9 : 8}>
                                        <div className={styles.iconsAndText}>
                                            <img src={DueOnIcon} className={styles.iconsStyle} alt="DueOnIcon" />
                                            <Typography.Text className={styles.headingFontStyle}>
                                                Due on:
                                            </Typography.Text>
                                        </div>
                                    </Col>
                                    {
                                        !isMobile &&
                                            <Col span={16}>
                                                <Typography.Text className={styles.greyFontColor}>
                                                    {card?.timezone === "" ? 
                                                        <div className={styles.deskMode}>{updatedDueDate || viewType === "yt-helper" ?  convertToCSTTimezone(card?.yt_due_date) :  convertToCSTTimezone(card?.due_date)}</div>
                                                        :
                                                        <div className={styles.deskMode}>{updatedDueDate || viewType === "yt-helper" ?  convertToCSTTimezone(card?.convertedYTHelperTime) :  convertToCSTTimezone(card?.convertedTime)}</div>
                                                    }
                                                </Typography.Text>
                                            </Col>
                                    }
                                  
                                </> : null
                        }
                        {
                            ((state.dueDate  && (( viewType !== "yt-helper" && cardInProgress(state.card) )
                            || ( viewType === "yt-helper" && state.card.ytHelper_card_status === YT_HELPER_IN_PROGRESS ))) || (state.card?.card_status === VIDEO_REQUEST && state.card.subscription_type === WEEKLY)) &&
                                <>
                                    {
                                    !isMobile &&
                                        <Col span={8}>
                                            
                                        </Col>
                                    }
                                    <Col span={isMobile ? 13 : 16}>
                                        <DatePicker className={styles.setDueDateStyle} placeholder="Select Due Date" showTime onOk={(e) => updateDueDate(e)} />
                                    </Col>
                                </>
                        }
                        {
                            isMobile && ((state.dueDate  && ((viewType !== "yt-helper" && cardInProgress(state.card) )
                            || (viewType === "yt-helper" && state.card.ytHelper_card_status === YT_HELPER_IN_PROGRESS ))) || (state.card?.card_status === VIDEO_REQUEST && state.card.subscription_type === WEEKLY)) &&
                                <Col span={2} style={{ paddingLeft: 0, alignSelf: 'center', marginLeft: 'auto' }}>
                                    <Typography.Text className={styles.greyFontColor}>
                                        <img src={MobileCalenderIcon} className={styles.mobileMode} onClick={() => {
                                                    setMobileModalData({ isModalOpen: true, title: "Due on", modalContent: getDueOnModalContent()})
                                                }} alt="AspectRatioIcon" />
                                    </Typography.Text>
                                </Col>
                        }
                        <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                            <Col span={isMobile ? 22 : 8} className={styles.iconsAndText}>
                                <img src={RawVideoLinkIcon} className={styles.iconsStyle} alt="RawVideoLinkIcon" />
                                <Typography.Text className={styles.headingFontStyle}>
                                    Raw Video Link:
                                </Typography.Text>
                            </Col>
                            <Col span={isMobile ? 2 : 16} style={{ display: isMobile ? "flex" : "block", justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                <Typography.Text className={styles.greyFontColor}>
                                    <img src={MobileFormatIcon} className={styles.mobileMode} onClick={() => {
                                            setMobileModalData({ isModalOpen: true, title: "Raw Video Link", modalContent: card?.dropbox_link, type: "link" })
                                        }} alt="RawVideoLinkIcon" />
                                    <a target="_blank" rel="noopener noreferrer" className={styles.deskMode} href={card?.dropbox_link}>{card?.dropbox_link}</a>
                                </Typography.Text>
                            </Col>
                        </div>
                        {
                            card?.done_video_link  ? 
                                handleMapDoneVideos() : null
                        }
                        {
                            viewType === "yt-helper" && card.yt_card_description &&
                                <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                    <Col span={isMobile ? 22 : 8} className={styles.iconsAndText}>
                                        <img src={VideoInfoIcon} className={styles.iconsStyle} alt="VideoInfoIcon" />
                                        <Typography.Text className={styles.headingFontStyle}>
                                            Video Description:
                                        </Typography.Text>
                                    </Col>
                                    <Col span={isMobile ? 2 : 16} style={{ display: "flex", justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                        <Typography.Text className={`${styles.greyFontColor}`}>
                                            <img src={MobileEyeIcon} className={styles.mobileMode} onClick={() => {
                                                    setMobileModalData({ isModalOpen: true, title: "Video Description", modalContent: parse(card?.yt_card_description) })
                                                }} alt="AspectRatioIcon" />
                                            <div className={styles.deskMode}>{parse(card?.yt_card_description)}</div>
                                        </Typography.Text>
                                    </Col>
                                </div>
                        }
                        {
                            viewType === "yt-helper" ? [
                                card.yt_card_status === "inDone" ?
                                    <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                        <Col span={isMobile ? 14 : 8} className={styles.iconsAndText}>
                                            <img src={RawVideoLinkIcon} className={styles.iconsStyle} alt="UnlistedVideoLinkIcon" />
                                            <Typography.Text className={styles.headingFontStyle}>
                                                Unlisted video:
                                            </Typography.Text>
                                        </Col>
                                        <div style={{ display: 'flex', width: "100%", justifyContent: isMobile ? "flex-end" : "flex-start", alignItems: "center"}}>
                                            {
                                                !isMobile &&
                                                <Col span={12} className={styles.deskMode}>
                                                    <Typography.Text className={styles.greyFontColor}>
                                                        <a target="_blank" rel="noopener noreferrer" className={styles.deskMode} href={card.unlisted_video_link}>{card.unlisted_video_link || "N/A"}</a>
                                                    </Typography.Text>
                                                </Col>
                                            }
                                            <Col span={isMobile ? 9 : 2} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                                                {
                                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                    <a className={styles.downloadLink} href={card?.unlisted_video_link} download>
                                                        <Button type="primary" icon={<DownloadOutlined />} className={styles.downloadBtn} size={'large'} />
                                                    </a>
                                                }
                                            </Col>
                                            {
                                                loggedInUser.client_type === ADMIN || loggedInUser.client_type === YT_HELPER || loggedInUser.client_type === YT_HELPER_LEAD || loggedInUser.client_type === TEAM_LEAD ?
                                                <Col span={isMobile ? 9 : 2} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                                                    <div className="btn-side">
                                                        {
                                                            <Button type="primary" icon={<EditOutlined />} size={'large'} style={{ backgroundColor: '#ab322d' }} className={styles.editOutlinedBtn} tooltip="Change Unlisted Video Link" onClick={() => {
                                                                setChangeUnlistedVideoUrlState({
                                                                    isEditing: true,
                                                                    oldUrl: card.unlisted_video_link,
                                                                });
                                                                setChangeDoneVideoUrlState({
                                                                    isEditing: false
                                                                });
                                                                setChangeRepurposeVideoUrlState({
                                                                    isEditing: false
                                                                });
                                                                }} >
                                                            </Button>
                                                        }
                                                    </div>
                                                </Col>
                                                : null
                                            }
                                            {isMobile &&
                                                <Col span={4} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                                                    <Typography.Text className={styles.greyFontColor}>
                                                        <img src={MobileFormatIcon} className={styles.mobileMode} onClick={() => {
                                                                setMobileModalData({ isModalOpen: true, title: "Unlisted Video Link", modalContent: card.unlisted_video_link || "N/A", type: "link" })
                                                            }} alt="UnlistedVideoLinkIcon" />
                                                    </Typography.Text>
                                                </Col>
                                            }
                                        </div>
                                    </div> : null,
                                card.thumbnails_link ?
                                    <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                        <Col span={isMobile ? 14 : 8} className={styles.iconsAndText}>
                                            <img src={RawVideoLinkIcon} className={styles.iconsStyle} alt="UnlistedVideoLinkIcon" />
                                            <Typography.Text className={styles.headingFontStyle}>
                                                Video Thumbnail Link:
                                            </Typography.Text>
                                        </Col>
                                        <div style={{ display: 'flex', width: "100%", justifyContent: isMobile ? "flex-end" : "flex-start", alignItems: "center"}}>
                                            {
                                                !isMobile &&
                                                <Col span={12} className={styles.deskMode}>
                                                    <Typography.Text className={styles.greyFontColor}>
                                                        <a target="_blank" rel="noopener noreferrer" className={styles.deskMode} href={card?.thumbnails_link}>{card?.thumbnails_link || "N/A"}</a>
                                                    </Typography.Text>
                                                </Col>
                                            }
                                            <Col span={isMobile ? 9 : 2} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                                                {
                                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                    <a className={styles.downloadLink} href={card?.unlisted_video_link} download>
                                                        <Button type="primary" icon={<DownloadOutlined />} className={styles.downloadBtn} size={'large'} />
                                                    </a>
                                                }
                                            </Col>
                                            {
                                                loggedInUser.client_type === ADMIN || loggedInUser.client_type === YT_HELPER || loggedInUser.client_type === YT_HELPER_LEAD || loggedInUser.client_type === TEAM_LEAD ?
                                                <Col span={isMobile ? 9 : 2} className={styles.videoDownloadIcon}>
                                                    <div className="btn-side">
                                                        {
                                                            <Button type="primary" icon={<EditOutlined />} size={'large'} style={{ backgroundColor: '#ab322d' }} className={styles.editOutlinedBtn} tooltip="Change Video Thumbnail Link" onClick={() => {
                                                                setChangeVideoThumbnailLink({
                                                                    isEditing: true,
                                                                    oldUrl: card.thumbnails_link,
                                                                });
                                                                setChangeDoneVideoUrlState({
                                                                    isEditing: false
                                                                });
                                                                setChangeRepurposeVideoUrlState({
                                                                    isEditing: false
                                                                });
                                                                setChangeUnlistedVideoUrlState({
                                                                    isEditing: false
                                                                });
                                                                setChangeSnippetVideoLink({
                                                                    isEditing: false
                                                                });
                                                                }} >
                                                            </Button>
                                                        }
                                                    </div>
                                                </Col>
                                                : null
                                            }
                                            {isMobile &&
                                                <Col span={4} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                                                    <Typography.Text className={styles.greyFontColor}>
                                                        <img src={MobileFormatIcon} className={styles.mobileMode} onClick={() => {
                                                                setMobileModalData({ isModalOpen: true, title: "Video Thumbnail Link", modalContent: card.thumbnails_link || "N/A", type: "link" })
                                                            }} alt="ThumbnailsVideoLinkIcon" />
                                                    </Typography.Text>
                                                </Col>
                                            }
                                        </div>
                                    </div> : null,
                                card.yt_snippet_link ?
                                    <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                        <Col span={isMobile ? 14 : 8} className={styles.iconsAndText}>
                                            <img src={RawVideoLinkIcon} className={styles.iconsStyle} alt="UnlistedVideoLinkIcon" />
                                            <Typography.Text className={styles.headingFontStyle}>
                                                Snippet Link:
                                            </Typography.Text>
                                        </Col>
                                        <div style={{ display: 'flex', width: "100%", justifyContent: isMobile ? "flex-end" : "flex-start", alignItems: "center"}}>
                                            {
                                                !isMobile &&
                                                <Col span={12} className={styles.deskMode}>
                                                    <Typography.Text className={styles.greyFontColor}>
                                                        <a target="_blank" rel="noopener noreferrer" className={styles.deskMode} href={card?.yt_snippet_link}>{card?.yt_snippet_link || "N/A"}</a>
                                                    </Typography.Text>
                                                </Col>
                                            }
                                            <Col span={isMobile ? 9 : 2} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                                                {
                                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                    <a className={styles.downloadLink} href={card?.unlisted_video_link} download>
                                                        <Button type="primary" icon={<DownloadOutlined />} className={styles.downloadBtn} size={'large'} />
                                                    </a>
                                                }
                                            </Col>
                                            {
                                                loggedInUser.client_type === ADMIN || loggedInUser.client_type === YT_HELPER || loggedInUser.client_type === YT_HELPER_LEAD || loggedInUser.client_type === TEAM_LEAD ?
                                                <Col span={isMobile ? 9 : 2} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                                                    <div className="btn-side">
                                                        {
                                                            <Button type="primary" icon={<EditOutlined />} size={'large'} style={{ backgroundColor: '#ab322d' }} className={styles.editOutlinedBtn} tooltip="Change Snippet Link" onClick={() => {
                                                                setChangeSnippetVideoLink({
                                                                    isEditing: true,
                                                                    oldUrl: card.yt_snippet_link,
                                                                });
                                                                setChangeDoneVideoUrlState({
                                                                    isEditing: false
                                                                });
                                                                setChangeRepurposeVideoUrlState({
                                                                    isEditing: false
                                                                });
                                                                setChangeUnlistedVideoUrlState({
                                                                    isEditing: false
                                                                });
                                                                setChangeVideoThumbnailLink({
                                                                    isEditing: false
                                                                });
                                                                }} >
                                                            </Button>
                                                        }
                                                    </div>
                                                </Col>
                                                : null
                                            }
                                            {isMobile &&
                                                <Col span={4} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                                                    <Typography.Text className={styles.greyFontColor}>
                                                        <img src={MobileFormatIcon} className={styles.mobileMode} onClick={() => {
                                                                setMobileModalData({ isModalOpen: true, title: "Snippet Link", modalContent: card.thumbnails_link || "N/A", type: "link" })
                                                            }} alt="SnippetVideoLinkIcon" />
                                                    </Typography.Text>
                                                </Col>
                                            }
                                        </div>
                                    </div> : null
                            ] : null
                        }
                        {
                            changeUnlistedVideoUrlState.isEditing ?
                                <>
                                    {!isMobile &&
                                        <Col span={8}>
                                        </Col>
                                    }
                                    <Col span={isMobile ? 24 : 16} className={styles.wrapperForLinkChange}>
                                            <TextInput label="New Video Link" className={styles.newLinkField}
                                                value={changeUnlistedVideoUrlState.newVideoLink === '' ? '' : changeUnlistedVideoUrlState.newVideoLink || changeUnlistedVideoUrlState.oldUrl} validate
                                                onChange={(e) => {
                                                    let value = e.target.value; setChangeUnlistedVideoUrlState((prev) => ({ ...prev, newVideoLink: value }))
                                                }} />

                                            <Button type="primary" icon={<CloseOutlined />} size={'large'} className={styles.closeBtn} key="cancel-btn-change-unlisted-video" tooltip="Cancel" onClick={() =>
                                                setChangeUnlistedVideoUrlState({ isEditing: false })} />
                                            <Button type="primary" icon={<SaveOutlined />} size={'large'} className={styles.saveBtn} key="save-change-unlisted-video" tooltip="Save Unlisted Video Link" onClick={e =>
                                                handleDoneVideoChange()} />
                                    </Col>
                                </>
                            : null
                        }
                        {
                            changeVideoThumbnailLink.isEditing ?
                                <>
                                    {!isMobile &&
                                        <Col span={8}>
                                        </Col>
                                    }
                                    <Col span={isMobile ? 24 : 16} className={styles.wrapperForLinkChange}>
                                            <TextInput label="New Video Link" className={styles.newLinkField}
                                                value={changeVideoThumbnailLink.newVideoLink === '' ? '' : changeVideoThumbnailLink.newVideoLink || changeVideoThumbnailLink.oldUrl} validate
                                                onChange={(e) => {
                                                    let value = e.target.value; setChangeVideoThumbnailLink((prev) => ({ ...prev, newVideoLink: value }))
                                                }} />

                                            <Button type="primary" icon={<CloseOutlined />} size={'large'} className={styles.closeBtn} key="cancel-change-video-thumbnail-link" tooltip="Cancel" onClick={() =>
                                                setChangeVideoThumbnailLink({ isEditing: false })} />
                                            <Button type="primary" icon={<SaveOutlined />} size={'large'} className={styles.saveBtn} key="cancel-change-video-thumbnail-link" tooltip="Save Video Thumbnail Link" onClick={e =>
                                                handleDoneVideoChange()} />
                                    </Col>
                                </>
                            : null
                        }
                        {
                            changeSnippetVideoLink.isEditing ?
                                <>
                                    {!isMobile &&
                                        <Col span={8}>
                                        </Col>
                                    }
                                    <Col span={isMobile ? 24 : 16} className={styles.wrapperForLinkChange}>
                                            <TextInput label="New Video Link" className={styles.newLinkField}
                                                value={changeSnippetVideoLink.newVideoLink === '' ? '' : changeSnippetVideoLink.newVideoLink || changeSnippetVideoLink.oldUrl} validate
                                                onChange={(e) => {
                                                    let value = e.target.value; setChangeSnippetVideoLink((prev) => ({ ...prev, newVideoLink: value }))
                                                }} />

                                            <Button type="primary" icon={<CloseOutlined />} size={'large'} className={styles.closeBtn} key="cancel-change-snippet-link" tooltip="Cancel" onClick={() =>
                                                setChangeSnippetVideoLink({ isEditing: false })} />
                                            <Button type="primary" icon={<SaveOutlined />} size={'large'} className={styles.saveBtn} key="save-change-snippet-link" tooltip="Save Snippet Link" onClick={e =>
                                                handleDoneVideoChange()} />
                                    </Col>
                                </>
                            : null
                        }
                        {
                            changeDoneVideoUrlState.isEditing ?
                                <>
                                    {!isMobile &&
                                        <Col span={8}>
                                        </Col>
                                    }
                                    <Col span={isMobile ? 24 : 16} className={styles.wrapperForLinkChange}>
                                            <TextInput label="New Video Link" className={styles.newLinkField}
                                                value={changeDoneVideoUrlState.newVideoLink || changeDoneVideoUrlState.oldUrl} validate
                                                onChange={(e) => {
                                                    let value = e.target.value; setChangeDoneVideoUrlState((prev) => ({ ...prev, newVideoLink: value }))
                                                }} />

                                            <Button type="primary" icon={<CloseOutlined />} size={'large'} className={styles.closeBtn} key="cancel-change-done-video" tooltip="Cancel" onClick={() =>
                                                setChangeDoneVideoUrlState({ isEditing: false })} />
                                            <Button type="primary" icon={<SaveOutlined />} size={'large'} className={styles.saveBtn} key="save-change-done-video" tooltip="Save Done Video Link" onClick={e =>
                                                handleDoneVideoChange()} />
                                    </Col>
                                </>
                            : null
                        }
                        {
                            state.card?.repurpose_video_versions && Object.keys(state.card?.repurpose_video_versions).length > 0 ?
                                mapMultipleFields(((state.card?.repurpose_video_versions[videoVersionsState.currentVersion])?.map(d => {
                                    return {
                                    video_url: d.video_url,
                                    video_type: d.video_type,
                                    audio_video_type: d.audio_video_type,
                                    video_id: d.video_id,
                                    }
                                })) ?? [], "Repurposed Video Link")
                                : null
                        }
                        {
                            changeRepurposeVideoUrlState.isEditing ?
                                <>
                                    {!isMobile &&
                                        <Col span={8}>
                                        </Col>
                                    }
                                    <Col span={isMobile ? 24 : 16} className={styles.wrapperForLinkChange}>
                                        <TextInput label="New Video Link" className={styles.newLinkField}
                                            value={changeRepurposeVideoUrlState.newVideoLink || changeRepurposeVideoUrlState.oldUrl} validate
                                            onChange={(e) => {
                                                let value = e.target.value; setChangeRepurposeVideoUrlState((prev) => ({ ...prev, newVideoLink: value }))
                                            }} />

                                        <Button type="primary" icon={<CloseOutlined />} size={'large'} className={styles.closeBtn} key="cancel-change-repurpose-video" tooltip="Cancel" onClick={() =>
                                            setChangeRepurposeVideoUrlState({ isEditing: false })} />
                                        <Button type="primary" icon={<SaveOutlined />} size={'large'} className={styles.saveBtn} key="save-change-repurpose-video" tooltip="Save Repurpose Video Link" onClick={e =>
                                            handleDoneVideoChange()} />
                                    </Col>
                                </>
                            : null
                        }
                        {
                            state.card && state.card?.repurpose_done_videos && state.card?.repurpose_done_videos.length === 0 && state.card?.repurpose_done_link ?
                                <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                    <Col span={isMobile ? 20 : 8} className={styles.iconsAndText}>
                                        <img src={RawVideoLinkIcon} className={styles.iconsStyle} alt="RawVideoLinkIcon" />
                                        <Typography.Text className={styles.headingFontStyle}>
                                            Repurposed Video Link:
                                        </Typography.Text>
                                    </Col>
                                    <Col span={isMobile ? 4 : 14} style={{ display: "flex", justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                        <Typography.Text className={styles.greyFontColor}>
                                            <img src={MobileFormatIcon} className={styles.mobileMode} onClick={() => {
                                                    setMobileModalData({ isModalOpen: true, title: "Repurposed Video Link", modalContent: state.card?.repurpose_done_link, type: "link" })
                                                }} alt="RawVideoLinkIcon" />
                                            {!isMobile ?
                                                <VideoPlayTab
                                                    dataTip={"Click to view video"}
                                                    videoUrl={state?.card?.repurpose_done_link}
                                                    cardName={state?.card?.card_title} />
                                                    : null
                                            }
                                        </Typography.Text>
                                    </Col>
                                    <Col span={2} className={styles.deskMode}>
                                        {
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            <a className={styles.downloadLink} href={state.card?.repurpose_done_link} download>
                                                <Button type="primary" icon={<DownloadOutlined />} className={styles.downloadBtn} size={'large'} />
                                            </a>
                                        }
                                    </Col>
                                </div>
                                : null
                        }
                        {
                            state.card?.content_id && state.card?.content_id !== '' ?
                                <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                    <Col span={isMobile ? 18 : 8} className={styles.iconsAndText}>
                                        <img src={RawVideoLinkIcon} className={styles.iconsStyle} alt="RawVideoLinkIcon" />
                                        <Typography.Text className={styles.headingFontStyle}>
                                          Sync ID:
                                        </Typography.Text>
                                        <MaterialButton small className={`${styles.contentIdInfoButton} ${styles.deskMode}`} onClick={()=>setShowMusicGuide(true)} 
                                            icon={<BsFillInfoCircleFill size = '25px' />} tooltip="Learn more" />
                                    </Col>
                                    {!isMobile &&
                                        <Col span={14}>
                                                <div style={{paddingLeft: '0', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', flexWrap : 'wrap', width: '100%'}} >
                                                {state.card.content_id.split(",").map((i,key) => <div key={key}>{i}</div>)}
                                            </div>
                                        </Col>
                                    }
                                    <Col span={isMobile ? 4 : 2}>
                                        {/* {state.card.content_id} */}
                                        {
                                            loggedInUser.client_type !== CUSTOMER ?
                                                <div className="btn-side">
                                                    {
                                                        <Button type="primary" icon={<EditOutlined />} className={styles.editOutlinedBtn} size={'large'} style={{ backgroundColor: '#ab322d' }} tooltip="Edit content" onClick={() =>
                                                            setChangeContentIdState({ isContentIdEditing: true, oldContentId: state.card.content_id})} >
                                                        </Button>
                                                    }
                                                </div> : null
                                        }
                                    </Col>
                                    {
                                        isMobile &&
                                            <Col span={2} style={{ display: "flex", justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                                <img src={MobileEyeIcon} className={styles.mobileMode} onClick={() => {
                                                            setMobileModalData({ isModalOpen: true, title: "Content ID for Music Licensing", modalContent: state.card.content_id.split(",").map((i,key) => <div key={key}>{i}</div>) })
                                                        }} alt="AspectRatioIcon" />
                                            </Col>
                                    }
                                    
                                </div> : null
                        }
                        {
                            state.card?.card_status === "done" && changeContentIdState.isContentIdEditing ?
                                <>
                                    <Col span={8}>
                                    </Col>
                                    <Col span={isMobile ? 24 : 16} className={styles.wrapperForLinkChange}>
                                            <TextInput className={styles.newLinkField} label="Content Id for music licensing"
                                                value={changeContentIdState.oldContentId} validate
                                                onChange={(e) => {
                                                    let value = e.target.value; setChangeContentIdState((prev) => ({ ...prev, oldContentId: value }))
                                                }} />

                                            <Button type="primary" icon={<CloseOutlined />} size={'large'} className={styles.closeBtn} key="cancel-change-done-video" tooltip="Cancel" onClick={() =>
                                                setChangeContentIdState({ isContentIdEditing: false })} />
                                            <Button type="primary" icon={<SaveOutlined />} size={'large'} className={styles.saveBtn} key="save-change-done-video" tooltip="Save Content Id" onClick={e =>
                                                handleDoneContentIdChange()} />
                                    </Col>
                                </>
                            : null
                        }
                        <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                            <Col span={isMobile ? 16 : 8} className={styles.iconsAndText}>
                                <img src={AspectRatioIcon} className={styles.iconsStyle} alt="AspectRatioIcon" />
                                <Typography.Text className={styles.headingFontStyle}>
                                    Aspect Ratio:
                                </Typography.Text>
                            </Col>
                            <Col span={isMobile ? 8 : 16} style={{ display: "flex", justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                <Typography.Text className={`${styles.greyFontColor} ${styles.fontTwelve}`}>
                                    {card?.youtube_aspect_ratio}
                                </Typography.Text>
                            </Col>
                        </div>
                        {card?.rating && (
                        <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                            <Col span={isMobile ? 16 : 8} className={styles.iconsAndText}>
                                <img src={AspectRatioIcon} className={styles.iconsStyle} alt="AspectRatioIcon" />
                                <Typography.Text className={styles.headingFontStyle}>
                                    Rating:
                                </Typography.Text>
                            </Col>
                            <Col span={isMobile ? 8 : 16} style={{ display: "flex", justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                <Typography.Text className={`${styles.greyFontColor} ${styles.fontTwelve}`}>
                                    {card?.doneCardRating}
                                </Typography.Text>
                            </Col>
                        </div>
                        )}
                        {card?.comment && (
                            <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                                <Col span={isMobile ? 16 : 8} className={styles.iconsAndText}>
                                    <img src={AspectRatioIcon} className={styles.iconsStyle} alt="AspectRatioIcon" />
                                    <Typography.Text className={styles.headingFontStyle}>
                                        Feedback:
                                    </Typography.Text>
                                </Col>
                                <Col span={isMobile ? 8 : 16} style={{ display: "flex", justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                    <Typography.Text className={`${styles.greyFontColor} ${styles.fontTwelve}`}>
                                        {card?.comment}
                                    </Typography.Text>
                                </Col>
                            </div>
                        )}
                        <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                            <Col span={isMobile ? 22 : 8} className={styles.iconsAndText}>
                                <img src={ContentTypeIcon} className={styles.iconsStyle} alt="ContentTypeIcon" />
                                <Typography.Text className={styles.headingFontStyle}>
                                    What type of content is this for:
                                </Typography.Text>
                            </Col>
                            <Col span={isMobile ? 2 : 16} style={{ display: "flex", justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                <Typography.Text className={styles.greyFontColor}>
                                    <img src={MobileEyeIcon} className={styles.mobileMode} onClick={() => {
                                            setMobileModalData({ isModalOpen: true, title: "What type of content is this for", modalContent: card?.video_purpose })
                                        }} alt="AspectRatioIcon" />
                                    <div className={styles.deskMode}>{card?.video_purpose}</div>
                                </Typography.Text>
                            </Col>
                        </div>
                        {
                            card?.additional_info && card?.additional_info !== '' &&
                                <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                    <Col span={isMobile ? 22 : 8} className={styles.iconsAndText}>
                                        <img src={VideoInfoIcon} className={styles.iconsStyle} alt="VideoInfoIcon" />
                                        <Typography.Text className={styles.headingFontStyle}>
                                            Anything else about this video:
                                        </Typography.Text>
                                    </Col>
                                    <Col span={isMobile ? 2 : 16} style={{ display: "flex", justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                        <Typography.Text className={`${styles.greyFontColor}`}>
                                            <img src={MobileEyeIcon} className={styles.mobileMode} onClick={() => {
                                                    setMobileModalData({ isModalOpen: true, title: "Anything else about this video", modalContent: parse(card?.additional_info) })
                                                }} alt="AspectRatioIcon" />

												{isLongText && !isMobile ? (
													<>
														<div className={`${styles.deskMode} ellipsis`}>{parse(card?.additional_info)}</div>
														<a onClick={() => setMobileModalData({ isModalOpen: true, title: "Anything else about this video", modalContent: parse(card?.additional_info) })}
															style={{ cursor: 'pointer' }}>Read More</a>
													</>
												) : (
													<div className={styles.deskMode}>{parse(card?.additional_info)}</div>
												)}
                                        </Typography.Text>
                                    </Col>
                                </div>
                        }
                        {
                            card?.special_instructions && card?.special_instructions !== '' &&
                                <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                    <Col span={isMobile ? 22 : 8} className={styles.iconsAndText}>
                                        <img src={VideoInfoIcon} className={styles.iconsStyle} alt="VideoInfoIcon" />
                                        <Typography.Text className={styles.headingFontStyle}>
                                            Special Instructions:
                                        </Typography.Text>
                                    </Col>
                                    <Col span={isMobile ? 2 : 16} style={{ display: "flex", justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                        <Typography.Text className={`${styles.greyFontColor}`}>
                                            <img src={MobileEyeIcon} className={styles.mobileMode} onClick={() => {
                                                    setMobileModalData({ isModalOpen: true, title: "Special Instructions", modalContent: parse(card?.special_instructions) })
                                                }} alt="AspectRatioIcon" />
                                            <div className={styles.deskMode}>{parse(card?.special_instructions)}</div>
                                        </Typography.Text>
                                    </Col>
                                </div>
                        }
                        {
                            card?.subtitles ?
                                <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                    <Col span={isMobile ? 21 : 8} className={styles.iconsAndText}>
                                        <img src={VideoInfoIcon} className={styles.iconsStyle} alt="VideoInfoIcon" />
                                        <Typography.Text className={styles.headingFontStyle}>
                                            Subtitles:
                                        </Typography.Text>
                                    </Col>
                                    <Col span={isMobile ? 3 : 16} style={{ display: "flex", justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                        <Typography.Text className={`${styles.greyFontColor} ${styles.fontTwelve}`}>
                                            Yes
                                        </Typography.Text>
                                    </Col>
                                </div> : null
                        }
                        {
                            card?.voice_note &&
                                <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                    <Col span={isMobile ? 22 : 8} className={styles.iconsAndText}>
                                        <img src={VideoInfoIcon} className={styles.iconsStyle} alt="VideoInfoIcon" />
                                        <Typography.Text className={styles.headingFontStyle}>
                                            Voice notes:
                                        </Typography.Text>
                                    </Col>
                                    <Col span={isMobile ? 2 : 16} style={{ display: "flex", justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                        <audio src={state.card?.voice_note} controls="controls" />
                                    </Col>
                                </div>
                        }
                        {
                            card?.repurpose_aspect ?
                                <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                    <Col span={isMobile ? 18 : 8} className={styles.iconsAndText}>
                                        <img src={VideoFormatIcon} className={styles.iconsStyle} alt="VideoFormatIcon" />
                                        <Typography.Text className={styles.headingFontStyle}>
                                            Repurpose Video Formats:
                                        </Typography.Text>
                                    </Col>
                                    <Col span={isMobile ? 6 : 16} style={{ display: "flex", justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                        <Typography.Text className={`${styles.greyFontColor} ${styles.fontTwelve}`}>
                                            {card?.repurpose_aspect}
                                        </Typography.Text>
                                    </Col>
                                </div> : null
                        }
                        {
                            card?.repurpose_aspect ? 
                                <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                    <Col span={isMobile ? 22 : 8} className={styles.iconsAndText}>
                                        <img src={VideoFormatIcon} className={styles.iconsStyle} alt="VideoFormatIcon" />
                                        <Typography.Text className={styles.headingFontStyle}>
                                            Repurpose Video Headline:
                                        </Typography.Text>
                                    </Col>
                                    <Col span={isMobile ? 2 : 16} style={{ display: "flex", justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                        <Typography.Text className={styles.greyFontColor}>
                                            <img src={MobileEyeIcon} className={styles.mobileMode} onClick={() => {
                                                    setMobileModalData({ isModalOpen: true, title: "Repurpose Video Headline", modalContent: card?.repurpose_headline || "N/A" })
                                                }} alt="AspectRatioIcon" />
                                            <div className={styles.deskMode}>{card?.repurpose_headline || "N/A"}</div>
                                        </Typography.Text>
                                    </Col>
                                </div> : null
                        }
                        {
                            card?.repurpose_aspect ? 
                                <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                    <Col span={isMobile ? 22 : 8} className={styles.iconsAndText}>
                                        <img src={VideoFormatIcon} className={styles.iconsStyle} alt="VideoFormatIcon" />
                                        <Typography.Text className={styles.headingFontStyle}>
                                            Repurpose Video Additional Info:
                                        </Typography.Text>
                                    </Col>
                                    <Col span={isMobile ? 2 : 16} style={{ display: "flex", justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                        <Typography.Text className={styles.greyFontColor}>
                                            <img src={MobileEyeIcon} className={styles.mobileMode} onClick={() => {
                                                    setMobileModalData({ isModalOpen: true, title: "Repurpose Video Additional Info", modalContent: card?.repurpose_additional_info || "N/A" })
                                                }} alt="AspectRatioIcon" />
                                            <div className={styles.deskMode}>{card?.repurpose_additional_info || "N/A"}</div>
                                        </Typography.Text>
                                    </Col>
                                </div> : null
                        }
                        {
                            card?.repurpose_aspect ? 
                                <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                    <Col span={isMobile ? 22 : 8} className={styles.iconsAndText}>
                                        <img src={CallToActionIcon} className={styles.iconsStyle} alt="CallToActionIcon" />
                                        <Typography.Text className={styles.headingFontStyle}>
                                            Call To Action at the end of the repurposed video:
                                        </Typography.Text>
                                    </Col>
                                    <Col span={isMobile ? 2 : 16} style={{ display: "flex", justifyContent: isMobile ? "flex-end" : "flex-start" }}>
                                        <Typography.Text className={`${styles.greyFontColor} ${styles.fontTwelve}`}>
                                            <img src={MobileEyeIcon} className={styles.mobileMode} onClick={() => {
                                                    setMobileModalData({ isModalOpen: true, title: "Call To Action at the end of the repurposed video", modalContent: card?.call_to_action || "N/A" })
                                                }} alt="AspectRatioIcon" />
                                            <div className={styles.deskMode}>{card?.call_to_action || "N/A"}</div>
                                        </Typography.Text>
                                    </Col>
                                </div> : null
                        }
                        {
                            card?.repurpose_status !== null &&
                            <>
                                <Col span={isMobile ? 14 : 8}>
                                    <div className={styles.iconsAndText}>
                                        <img src={VideoFormatIcon} className={styles.iconsStyle} alt="VideoFormatIcon" />
                                        <Typography.Text className={styles.headingFontStyle}>
                                            SRT Text File:
                                        </Typography.Text>
                                    </div>
                                </Col>
                                <Col span={isMobile ? 6 : 14} className={styles.srtFileNA}>
                                    <div>{state.card?.srt_file && <div style={{paddingLeft: '0'}}><a className="downloadTextFile" href={state.card.srt_file}><DownloadOutlined /></a></div>}
                                    {!state.card?.srt_file && <div className={styles.greyFontColor} style={{paddingLeft: '0', marginLeft: (isMobile && isCustomer(loggedInUser) && state.card?.role !== YT_HELPER) ? "calc(100% - -32px)" : "0"}}>N/A</div>}</div>
                                </Col>
                                {
                                    isAdmin(loggedInUser) || 
                                    loggedInUser.client_type === YT_HELPER ||
                                    (isCustomer(loggedInUser) && state.card?.role === YT_HELPER) ?
                                        <Col span={isMobile ? 4 : 2}>
                                            <Button type="primary" icon={<EditOutlined />} size={'large'} className={styles.editOutlinedBtn} style={{ backgroundColor: '#ab322d' }} tooltip="Edit SRT File" onClick={() => {
                                                setEditSrtFile({ isEditing: true });
                                                }} >
                                            </Button>
                                        </Col>
                                    : null
                                }
                                {
                                    editSrtFile.isEditing ? <>
                                    <Col s={12} m={4}>
                                        <div style={{ display: "flex" }}>
                                        <input
                                            style={{ width: "100%" }}
                                            className="custom-file-input"
                                            s={12}
                                            id="srt-input"
                                            type="file"
                                            name="srtFile"
                                            onChange={(e) => onChangeFile(e)}
                                            label="Select SRT File"
                                        />
                                        </div>
                                    </Col>
                                    <Col s={12} m={8}>
                                        <MaterialButton className="srtButton" disabled={selectedFile ? false : true} onClick={() => {
                                            setEditSrtFile({ isEditing: true });
                                            axiosClient.post(`/api/card/${state.card.card_id}/srtFile`,
                                            {
                                                card_id: state.card.card_id,
                                                srt_file: selectedFile
                                            })
                                            .then(({data}) => {
                                                successToast(data.message || 'File Uploaded');
                                                props.onClose();
                                            }).catch(err => {
                                                errorToast("Something went wrong, please try again");
                                            });
                                            }} >
                                                Submit SRT
                                        </MaterialButton>
                                        </Col>
                                        </>
                                    : null
                                }
                            </>
                        }
                        <MobileModal
                            mobileModalData={mobileModalData}
                            clearFields={clearFields}
                            handleCancel={handleCancelMobileModal}
                            type={type} />

                        <MaterialModal
                            header={"CLEAR YOUR YOUTUBE CONTENT WITH SYNC ID"}
                            id="music-guide"
                            open={showMusicGuide}
                            options={{
                                dismissible: true,
                                startingTop: '4%',
                                endingTop: '10%',
                                opacity: 0.5,
                                preventScrolling: true,
                                onCloseEnd: ()=>setShowMusicGuide(false),
                            }}
                        >
                            <p>Add this SyncID to your YouTube video description to validate your license and avoid copyright claims.</p>
                            <p><span style={{fontWeight:'bold'}}>Important:</span> Make sure to set the video Visibility to "Unlisted" or "Public" and make sure to put the Sync ID in the Description before uploading.</p>
                            <p>What is SyncID™?</p>
                            <p>SyncID is a Content ID clearance system created by our third-party license provider, Musicbed for YouTube and offered as an exclusive benefit to subscribers. SyncID provides a smooth, streamlined process to clear videos of claims and allows you to monetize your videos.</p>
                            <p>What happens if I receive a claim on my video and I have a Musicbed subscription?</p>
                            <p>Through our third party's SyncID system, any claims placed on your video for music in subscription should clear automatically after the video is uploaded as unlisted or public. If your video still gets claimed for copyright, reach out to us so we can manually clear it via Music Bed.</p>
                            <div style={{display:"flex", justifyContent:"center"}}>
                            <iframe
                                width="420"
                                height="345"
                                src="https://www.youtube.com/embed/VYF6ZNcaG4U"
                                title="Musicbed Personal Subscription: 101- Clearing Copyright Claims with SyncID™"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen>
                            </iframe>
                            </div>
                        </MaterialModal>
                    </Row>
                </div>
                : <Spin className={styles.loader} size="large" spinning={card ? false : true} />
            }
        </div>
    );
}

export default DetailsTab;
