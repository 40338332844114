import { FETCH_FILTER_DATA } from "../actions/types";

export default function (state = {}, {payload, type}) {
    switch (type) {
        case FETCH_FILTER_DATA:
            return {...state, filterData: payload};
        default:
            break;
    }
    return state;
}
