import React, { useState } from "react";
import { Avatar, Button, Card ,Tabs } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { BiDownArrow, BiUpArrow } from "react-icons/bi";
import { errorToast, successToast } from "../../api/Util";
import styles from "./AIToolbox.module.scss";
import "./AIToolbox.scss";
import AIToolboxModal from "../common/AIToolboxModal/AIToolboxModal";
import { saveAIResponse } from "../../services/aiToolboxService";
import { Toolbox } from "../../constant/AIdata";
import VidchopsLogo from "../img/vidchops-icon.png";
import { Icon,  Container } from "react-materialize";
import { useSelector } from "react-redux";
import AIDataTable from "../common/AIDataTable/AIDataTable";
import TopHeader from "../TopHeader";

const { TabPane } = Tabs;
const AIToolbox = () => {
  const [AIPromptModal, setAIPromptModal] = useState(false);
  const props = useSelector((state) => state)
  const loggedInUser = props.auth?.loggedInUser;
  const [selectedTool, setSelectedTool] = useState({});
  const [selectedToolIndex, setSelectedToolIndex] = useState(null);
  const [AIGeneratedData, setAIGeneratedData] = useState([]);
  const [showFullTextArray, setShowFullTextArray] = useState([]);
  const [searchedResultFor, setSearchedResultFor] = useState({
    title: false,
    ideas: false,
    script: false
  });
  const [activeTab, setActiveTab] = useState('ToolBox'); // Default active tab

  const handleTabChange = (key) => {
    setActiveTab(key);
    setSelectedToolIndex(null); // Reset selected tool index when the tab changes
  };
  
  const childCallBack = (param, data) => {
    if (param === "close") {
      setAIPromptModal(false);
      setSelectedToolIndex(null);
    } else if (param === "response_data") {
      setAIGeneratedData(data);
      setShowFullTextArray(new Array(data?.length).fill(false));
    } else if (param === "resetReprompt") {
      if (typeof searchedResultFor[data] !== "undefined") {
        setSearchedResultFor(prevState => ({
          ...prevState,
          [data]: false
        }));
      }
    } else if (param === "searched_data") {
      if (typeof searchedResultFor[data] !== "undefined") {
        setSearchedResultFor({
          ...searchedResultFor,
          [data]: true
        })
      }
    }
  }

  const saveAIData = async (index) => {
    const storedParams = localStorage.getItem('aiDataParams');
    const params = storedParams ? JSON.parse(storedParams) : {};
    let aIsavedParams = {
      ...params,
      data: [AIGeneratedData[index]],
      user_id: loggedInUser?.client_id
    }
    const response = await saveAIResponse(aIsavedParams);
    if (response.status === true) {
      successToast("data saved successfully");
    } else {
      errorToast("Some Eror Occured");
    }
  };

  return (
    <Container className="toolBoxCustom" id="video_upload">
      <div className="aside-right-top-bar">
        <div className="top-bar-left">
          <div className="top_bar">
            <h3>AI Tool</h3>
          </div>
        </div>
        <div className="top-bar-right">
          <TopHeader />
        </div>
      </div>
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="ToolBox" key="ToolBox">
          <div className={`${styles.outerContainer} containerCustomStyle`}>
            <div className={`${styles.container}`}>
              {Toolbox?.map((item, index) => (
                <Card
                  className={`${styles.card} ${selectedToolIndex === index ? styles.selectedCard : ""
                    }`}
                  title={item.title}
                  bordered={false}
                  style={{
                    width: 320,
                  }}
                  key={`cardId${index}`}
                  onClick={() => {
                    setAIPromptModal(true);
                    setSelectedTool(item);
                    setSelectedToolIndex(index);
                  }}
                >
                  <div className={styles.descAndIconWrapper}>
                    <p>{item.description}</p>
                    <br />
                    <span className={styles.arrowIcon}>
                      <ArrowRightOutlined />
                      <span className={`${styles.rePrompt} ${searchedResultFor[item.type] ? styles.showPrompt : ""}`}>Re prompt</span>
                    </span>
                  </div>
                </Card>
              ))}

              <AIToolboxModal
                isModalOpen={AIPromptModal}
                selectedTool={selectedTool}
                childCallBack={childCallBack}
              />
            </div>

            <div className={styles.dataWrapper}>
              {Array.isArray(AIGeneratedData) &&
                AIGeneratedData.map((item, index) => (
                  item && typeof item === 'string' && item.trim().length > 0 && (
                    <Card
                      loading={false}
                      className={styles.cardDataStyle}
                      key={`generatedCardId${index}`}
                    >
                      <Card.Meta
                        avatar={<Avatar src={VidchopsLogo} />}
                        title={item.split('\n').map((text, i) => (
                          <React.Fragment key={i}>
                            {text}
                            <br />
                          </React.Fragment>
                        ))}
                        className={` ${showFullTextArray[index] ? "showFullText" : "AiDataText"}`}
                      />
                      <Button icon={<Icon>save</Icon>} className={styles.saveBtnChange} onClick={() => saveAIData(index)} />
                      {item.length > 100 && (
                        <div
                          className={styles.fullTextButton}
                          onClick={() => {
                            const newArray = [...showFullTextArray];
                            newArray[index] = !newArray[index];
                            setShowFullTextArray(newArray);
                            console.log(showFullTextArray, 'showfffulltext');
                          }}
                        >
                          <span className={styles.arrowIcon}>
                            {showFullTextArray[index] ? <BiUpArrow /> : <BiDownArrow />}
                          </span>
                        </div>
                      )}
                    </Card>
                  )
                ))}
            </div>
          </div>
        </TabPane>
        <TabPane tab="Saved List" key="SavedList">
          {activeTab === 'SavedList' && (
            <div className={`${styles.tableCustomStyle} tableCustomStyle`}>
              <div className={`${styles.tableViewData}`}>
                <AIDataTable loggedInUser={loggedInUser} />
              </div>
            </div>
          )}
        </TabPane>
      </Tabs>
    </Container>
  );
};

export default AIToolbox;
