import {axiosClient} from '../api/httpClient';

export const getCardActivityStatus = async (cardId) => {
	try {
		const data = await axiosClient.get('/api/card-activity/' + cardId);
		return {
            status: true,
			data: data?.data
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured"
		}
	}
};
