import React, { useEffect, useState } from 'react';
import { Layout, Card, Typography } from 'antd';
import { Col, Collapsible, CollapsibleItem, Row, CardPanel, Icon } from 'react-materialize';
import { useSelector } from 'react-redux';
import styles from './HomeStyle.module.scss';
import ListViewHeader from '../CustomTabs/TabHeading/ListViewHeader';
import ListViewContent from '../CustomTabs/TabContent/ListViewContent';
import TopHeader from '../TopHeader/TopHeader';
import KanbanViewHeader from './KanbanView/KanbanViewHeader/KanbanViewHeader';
import KanbanViewContent from './KanbanView/KanbanViewContent/KanbanViewContent';
import { cssStringToObj, toISODate } from '../../api/Util';
import KanbanAndListViewToggle from '../common/KanbanAndListViewToggle/KanbanAndListViewToggle';
import RenderHtml from '../common/RenderHtml';
import moment from 'moment';
import { ADMIN } from '../../api/Constants';

const Home = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [cardData, setCardData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [totalCards, setTotalCards] = useState(0);
  const startDateQueryParam = queryParams.get('startDate');
  const endDateQueryParam = queryParams.get('endDate');
  const [queryParamsValue, setQueryParams] = useState({
    queryStartDate: startDateQueryParam ? moment(decodeURIComponent(startDateQueryParam)).toDate() : null,
    queryEndDate: endDateQueryParam ? moment(decodeURIComponent(endDateQueryParam)).toDate() : null,
    querySectionType:queryParams.get('section')});
    const initialActiveTab = (() => {
      if (queryParamsValue?.querySectionType === 'video_request') {
        return 'video_request';
      } else if (queryParamsValue?.querySectionType === 'editing') {
        return 'editing';
      } else if (queryParamsValue?.querySectionType === 'completed') {
        return 'done';
      }
      return localStorage.getItem("activeTab") || "all_tabs";
    })();
    const [activeTab, setActiveTab] = useState(initialActiveTab);
    const [filterCards, setFilterCards] = useState({
      card_name: localStorage.getItem("listSearchText") || undefined,
      startDate: queryParamsValue?.queryStartDate || "",
      endDate: queryParamsValue?.queryEndDate|| "" ,
      view_as: Number(localStorage.getItem("listViewAs")) || null,
    });
  const [startDate, setStartDate] = useState(queryParamsValue?.queryStartDate || null);
  const [endDate, setEndDate] = useState(queryParamsValue?.queryEndDate|| null);
  const [selectedDueDate, setSelectedDueDate] = useState(false);
  const [searchText, setSearchText] = useState(localStorage.getItem("searchText") || '');
  const [showCanceled, setShowCanceled] = useState(localStorage.getItem("showCanceled") === "true" || false);
  const [showHold, setShowHold] = useState(localStorage.getItem("showHold") === "false" ? false : true);
  const [showRepurposed, setShowRepurposed] = useState(localStorage.getItem("showRepurposed") === "false" ? false : true);
  const [viewAs, setViewAsState] = useState(localStorage.getItem("viewAs") ||  null );
  
  const [newCardRequestOpen, setNewCardRequestOpen] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [showBanner, setShowBanner] = useState({});
  const props = useSelector((state) => state);
  const loggedInUser = props.auth?.loggedInUser;
  const selectedView = JSON.parse(localStorage.getItem("selectedListingView"));
  const [selectedListingView, setSelectedListingView] = useState(
    selectedView?.cardViewType || loggedInUser.client_type !== ADMIN ? "kanbanView" : "listView"
  );
  const showProfileMsg = localStorage.getItem("skipProfileForNow");
  const bodyFontStyle = {
    fontWeight: showBanner?.banner_font_style === "b" ? "bold" : "normal",
    fontStyle: showBanner?.banner_font_style === "i" ? "italic" : "normal",
    fontSize: showBanner?.banner_text_style,
    color: "#000000"
  }
  const headingStyle = {
    color: showBanner?.banner_heading_color || "#ffffff",
  };
  
  useEffect(() => {
    const settingsArray = Object.values(props.settings || {});
    const enableBanner = settingsArray?.filter(
      (setting) => setting?.enable_banner === 1
    );
    const filterData = enableBanner?.find((item) => {
      let announcementStartDate = item?.startDate;
      let announcementEndDate = item?.endDate;
      let currentDate = toISODate(new Date());
      if (
        currentDate >= announcementStartDate &&
        currentDate <= announcementEndDate
      ) {
        return item;
      }
    });
    setShowBanner(filterData);
  }, [props.settings]);

  const bannerTextStyle = Object.assign(
    cssStringToObj(showBanner?.banner_text_style || ""),
    { whiteSpace: "pre-wrap" }
  );

    return (
        <Layout className={styles.homeLayout}>
          {
            showBanner &&
            <Collapsible style={{ margin: 0 }}>
                <CollapsibleItem
                    className="notification-collapsible"
                    expanded={true}
                    header={'Announcement'}
                    icon={<Icon>arrow_drop_down</Icon>}
                    node="div"
                    style={{ margin: 0 }}
                >
                    <Row style={{ margin: 0}}>
                        <CardPanel className="bg-warning z-depth-4" style={{ margin: 0}}>
                            <Row>
                                <Col s={12}>
                                  <RenderHtml bannerHeading={showBanner?.banner_heading} bannerHeadingTag={showBanner?.banner_heading_style} headingStyle={headingStyle} />
                                </Col>
                            </Row>
                            <Row>
                                <Col s={12} style={bannerTextStyle}>
                                    <p style={bodyFontStyle}>{showBanner?.banner_text || ""}</p>
                                </Col>
                            </Row>
                            {
                                showBanner?.banner_image &&
                                <Row>
                                    <Col s={12}>
                                        <img src={showBanner?.banner_image} alt="Banner"
                                            style={Object.assign({width: "100%", height: "auto"},
                                                cssStringToObj(showBanner?.banner_image_style))}/>
                                    </Col>
                                </Row>
                            }
                        </CardPanel>
                    </Row>
                </CollapsibleItem>
            </Collapsible>
          }
          {
            showProfileMsg && loggedInUser.is_yt_fields_filled === 1 ?
              <Col className = {`profile-note-col ${styles.noteMaterialCol}`}>
                  <strong className = "profile-note">Note : </strong>
                  <span className = "profile-note-message">Please update your YouTube profile section. This will help us to create better content for your YouTube channel.   </span>
              </Col>
            : null
          }
          <Card className={styles.topHeader} loading={false}>
            <TopHeader />
          </Card>
          {
            selectedListingView === "listView" ?
            <div className={styles.leftSection}>
              <Typography.Title className={styles.cardsTitle}>
                  Cards
              </Typography.Title>
              <KanbanAndListViewToggle
                selectedListingView={selectedListingView}
                setSelectedListingView={setSelectedListingView}
                isBtnDisabled={isBtnDisabled}
                setIsBtnDisabled={setIsBtnDisabled}
                />
            </div> : null
          }

      {selectedListingView === "listView"
        ? [
            <ListViewHeader
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              cardData={cardData}
              setCardData={setCardData}
              setTotalCards={setTotalCards}
              filterCards={filterCards}
              setFilterCards={setFilterCards}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              offset={offset}
              setOffset={setOffset}
              queryParamsValue={queryParamsValue}
              setQueryParams={setQueryParams}
            />,
            <ListViewContent
              activeTab={activeTab}
              cardData={cardData}
              setCardData={setCardData}
              totalCards={totalCards}
              setTotalCards={setTotalCards}
              filterCards={filterCards}
              setFilterCards={setFilterCards}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              offset={offset}
              setOffset={setOffset}
              section=""
              setActiveTab={setActiveTab}
              setIsBtnDisabled={setIsBtnDisabled}
              queryParamsValue={queryParamsValue}
              setQueryParams={setQueryParams}
            />,
          ]
        : null}
      {selectedListingView === "kanbanView"
        ? [
            <KanbanViewHeader
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              selectedDueDate={selectedDueDate}
              setSelectedDueDate={setSelectedDueDate}
              setSearchText={setSearchText}
              showHold={showHold}
              setShowHold={setShowHold}
              showCanceled={showCanceled}
              setShowCanceled={setShowCanceled}
              showRepurposed={showRepurposed}
              setShowRepurposed={setShowRepurposed}
              viewAs={viewAs}
              setViewAsState={setViewAsState}
              setNewCardRequestOpen={setNewCardRequestOpen}
              searchText={searchText}
              selectedListingView={selectedListingView}
              setSelectedListingView={setSelectedListingView}
              isBtnDisabled={isBtnDisabled}
              setIsBtnDisabled={setIsBtnDisabled}
              queryParamsValue={queryParamsValue}
              setQueryParams={setQueryParams}
            />,

            <KanbanViewContent
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              selectedDueDate={selectedDueDate}
              setSelectedDueDate={setSelectedDueDate}
              searchText={searchText}
              setSearchText={setSearchText}
              showHold={showHold}
              showCanceled={showCanceled}
              showRepurposed={showRepurposed}
              viewAs={viewAs}
              newCardRequestOpen={newCardRequestOpen}
              setNewCardRequestOpen={setNewCardRequestOpen}
              setIsBtnDisabled={setIsBtnDisabled}
              queryParamsValue={queryParamsValue}
              setQueryParams={setQueryParams}
            /> 
          ]
        : null}
    </Layout>
  );
};

export default Home;
