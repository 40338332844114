import React, { useEffect, useState } from 'react';
import { Layout, Tabs, Typography, Avatar, Tooltip, Card, Empty, Button } from 'antd';
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import styles from './Inbox.module.scss';
import ChatSection from '../common/ChatSection/ChatSection';
import { convertLocalToServerTime, errorToast, infoToast, isMobile, successToast } from '../../api/Util';
import { deleteComment, editChatComment, getInboxMessages, handleInboxMessageStatus, seenMessages, storeChatMessage, storeInboxMessage } from '../../services/inboxService';
import MessageList from './Messages/MessageList';
import Profile from '../img/profile.png';
import { ADMIN, CUSTOMER, TEAM_LEAD } from '../../api/Constants';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from "../../actions/index";
import * as _ from 'lodash';
import ConfirmationDialog from "../ConfirmationDialog";
import { getChatByMessageId } from '../../services/inboxService';
import AddMessageModal from '../common/AddMessageModal/AddMessageModal';
import BackArrowIcon from '../../components/img/back_arrow.png';

const INITIAL_REPLY_STATE = {
    comment: "",
    showReplyField: false,
    parentId: null,
    oldComment: "",
}

const INITIAL_COMMENT_STATE = {
    id: 0, 
    data: "", 
    oldComment: "",
}

const Inbox = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [addTitle, setAddTitle] = useState("");
    const [addMessage, setAddMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [page, setPage] = useState(1);
    const [rowsCount, setRowsCount] = useState(0);
    const [filterMessages, setFilterMessages] = useState({ message_title: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState("ACTIVE");
    const [state, setState] = useState({ comments: {}, commentsArray: [] });
    const [replyState, setReplyState] = useState(INITIAL_REPLY_STATE);
    const [comment, setComment] = useState(INITIAL_COMMENT_STATE);
    const [deleteConfirmation, setDeleteConfirmation] = useState({ open: false, comment: {} });
    const [disableBtn, setDisableBtn] = useState(false);
    const [currentSelectedMessage, setCurrentSelectedMessage] = useState();
    const [offset, setOffset] = useState(0);
    const [chatTitle, setChatTitle] = useState("");
    const [hasMore, setHasMore] = useState(true);
    const [updatedCount, setUpdatedCount] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);

    const props = useSelector((state) => state)
    const dispatch = useDispatch();
    const loggedInUser = props.auth?.loggedInUser;
    const unSeenMessagesCount = props.inboxSeenMessages?.unSeenCount;
    const limit = 10;

    useEffect(() => {
        if (unSeenMessagesCount > 0 && (loggedInUser.client_type === TEAM_LEAD || loggedInUser.client_type === ADMIN)) {
            handleSeenMessages();
            dispatch(actions.unSeenMessages());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unSeenMessagesCount])

    const handleSeenMessages = async () => {
        await seenMessages(props.inboxSeenMessages?.unseenMessages);
    }
    
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    useEffect(() => {
        if (isMobile) {
            document.getElementById("chatSectionId").style.display = "none";
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile])

    const handleOk = async () => {
        if (isModalOpen.status === 'Re-open') {
            handleInboxMessage(isModalOpen.id, isModalOpen.status);
        } else {
            let obj = {  title: addTitle,  latestMessage: addMessage, status: 'ACTIVE', }
            const data = await storeInboxMessage(obj);
            if (data.status) {
                successToast(data.message);
                getChatInboxMessages();
            } else {
                errorToast(data.error);
            }
        }
        setIsModalOpen(false);
    }

    const handleInboxMessage = async (id, status) => {
        let obj = {
            id,
            status,
        }
        if (status === 'Re-open') {
            obj = {
                ...obj,
                latestMessage: addMessage,
            }
        }
        const data = await handleInboxMessageStatus(obj);
        if (data.status) {
            setState({ comments: {}, commentsArray: [] });
            setChatTitle("");
            successToast(data.message);
            getChatInboxMessages();
        } else {
            errorToast(data.error);
        }
    }

    function uniqueComments(comments) {
        return _.uniqBy(comments, 'note_id');
    }
    const getChatInboxMessages = async () => {
        let filters = {
            limit: limit,
            page: page - 1,
            title: filterMessages.message_title,
            status: status,
        }
        if (filterMessages.view_as) {
            filters = {
                ...filters,
                view_as: filterMessages.view_as
            }
        }
        const response = await getInboxMessages(filters);
        if (response.status) {
            setMessages(response.data.messages);
            setRowsCount(response.data.count);
            setIsLoading(false);
        } else {
            setMessages([]);
            setIsLoading(false);
        }
    }

    const handleCancel = () => {
    setIsModalOpen(false);
    }

    const clearFields = () => {
    setAddTitle("");
    setAddMessage("");
    }

    const getChatById = async (calledBy = "noCall", id, offset) => {
        setIsLoading(true);
        let obj = {
            id
        };
        if (calledBy === 'scrollCalled') {
            obj = {
                offset,
                currentSelectedMessage
            }
        }
        const data = await getChatByMessageId(calledBy, obj);
        const groupByChat = data.data.groupByChat;
        const commentArray = data.data.comments;
        const count = data.data.count;
        if (offset + commentArray.length === count) {
            setHasMore(false);
        }
        setOffset(offset + commentArray.length);
        setState(prev => ({
            ...prev,
            comments: updateCommentGroups(prev.comments, groupByChat) ,
            commentsArray: uniqueComments( prev.commentsArray.concat(commentArray)),

        }));
        setIsLoading(false);
    }
    function updateCommentGroups(prevComments, newComments) {
		let newTempComments = {};
		if (Object.keys(prevComments).length === 0) {
			newTempComments = newComments;
		} else {
			newTempComments = mergeTwoObjects(prevComments, newComments);
		}

		return newTempComments;
	}

	function mergeTwoObjects(object1, object2) {
		for (let x in object1) {
			for (let y in object2) {
				if (x === y) {
					object1[x] = object1[x].concat(object2[y]);
					delete(object2[y]);
				}
			}
		}

		if (Object.keys(object2).length > 0) {
			for (let y in object2) {
				object1[y] = object2[y];
			}
		}

		return object1;
	}

    const mapComments = (comments, commentsArray) => {
        return Object.keys(comments)?.map(objKey => {
            return comments[objKey]?.map((cmnt, key) => {
                let myComment = cmnt.note_by_id === loggedInUser.client_id;
                let annotationData;
                if (cmnt.note_type && cmnt.note_type === "annotation") {
                    annotationData = JSON.parse(cmnt.note);
                }
                let temp = commentsArray?.find(element => element.note_id === cmnt.note_parent_id);
                return (
                    <div className={{ display: 'flex', width: '100%' }}>
                    {
                        key === 0 &&
                            <div className={styles.chatDateDiv}>
                                <span className={styles.chatDate}>{new Date(objKey).getDate()} {moment(objKey).format('MMM')} {new Date(objKey).getFullYear()}</span>
                            </div>
                    }
                    <div className={styles.optionAndCommentContainer}>
                        <div className={myComment ? styles.rowWrapRight : styles.rowWrapLeft}>
                            <div>
                                {!myComment && cmnt.profile_img ?
                                    <img src={Profile} alt="avatar"
                                        className={styles.roundImgStyle}
                                        style={{ pointerEvents: 'none', userSelect: "none", verticalAlign: "middle", width: "36px", height: "36px", borderRadius: "50%", marginTop: "2.9rem" }} />
                                    : <Avatar className={styles.avatarStyle}>
                                        {cmnt.firstname || cmnt.lastname ? `${cmnt.firstname.toUpperCase().charAt(0)}${cmnt.lastname.toUpperCase().charAt(0)}` : 'N/A'}
                                    </Avatar>
                                }
                            </div>
                            <div className={styles.commentAndTimeContainer}>
                                {!myComment && <span className={styles.clientName}><b>{cmnt.firstname} {cmnt.lastname}</b></span>}
                                <div className={styles.commentMessage}>
                                    <div className={styles.chatMessage}>
                                        {temp?.note && <>
                                            <FontAwesomeIcon style={{ color: '#616C76', marginRight: '4px' }} icon={faReply} />
                                        <span>Replied on {(temp.note).substring(0, 15) + '...'}</span><br />
                                        </>}
                                        {
                                            annotationData && annotationData?.data !== "" ? annotationData.data.text :
                                                cmnt.note
                                        }
                                        <div className={styles.editAndDeleteIconWrapper}>
                                            <Tooltip placement="bottom" title={'Reply'}>
                                                <FontAwesomeIcon className={styles.replyIcon}
                                                    onClick={() => {
                                                        document.getElementById("submitBtn").focus();
                                                        setReplyState(prev => ({ ...prev, showReplyField: true, parentId: cmnt.note_id, oldComment: cmnt.note }));
                                                    }} icon={faReply} />
                                            </Tooltip>
                                            {
                                                (loggedInUser.client_id === cmnt.note_by_id || (loggedInUser.client_type === ADMIN && cmnt.client_type !== CUSTOMER)) ?
                                                    <>
                                                        <Tooltip placement="bottom" title={'Edit'}>
                                                            <EditOutlined key={'comment-edit-key-id-' + cmnt.note_id}
                                                                onClick={() => {
                                                                    let comment = cmnt.note;
                                                                    comment = cmnt.note;
                                                                    setComment({id: cmnt.note_id, data: comment, oldComment: cmnt.note});
                                                                }} className={styles.editIcon} />
                                                        </Tooltip>
                                                        <Tooltip placement="bottom" title={'Delete'}>
                                                            <DeleteOutlined key={'comment-delete-key-id-' + cmnt.note_id}
                                                                onClick={() => setDeleteConfirmation({ open: true, comment: cmnt })} className={styles.deleteIcon} />
                                                        </Tooltip>
                                                    </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    {/* <p>It's more important to me that you get started on the NEXT videos</p> */}
                                    <span>{moment(cmnt.date_added).format('LT')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                  )
            })
          
        });
    }

    // when user press enter the comment will send
    function keyPressHandler(e, calledBy = 'main') {
        if (!e.shiftKey && e.key === 'Enter') {
            e.preventDefault();
            if (replyState.showReplyField) {
                send('reply');
            } else {
                if (comment.id > 0) {
                    editComment();
                } else {
                    send(calledBy);
                }
            }
        }
        e.persist();
    }

    async function send(calledBy = 'main') {
        let note = calledBy === "reply" ? replyState.comment : comment.data;

        if (!note || note === '') {
            return;
        }
        if (disableBtn) {
            return infoToast("A new comment request has been already made for this comment");
        }

        infoToast("Creating a new comment request");
        setDisableBtn(true);

        let obj = {
            chat_id: currentSelectedMessage,
            note: note
        }
        if (calledBy === "reply") {
            obj = {
                ...obj,
                noteParentId: replyState.parentId,
            }
        }
        const data = await storeChatMessage(obj);
        setUpdatedCount(data.count);
        setState(prev => {
            let newList;
            if (calledBy === "reply") {
                let comments = state.comments;
                let newComment = {
                    note_id: data.id,
                    note: note,
                    date_added: convertLocalToServerTime(new Date()),
                    note_by_id: loggedInUser.client_id,
                    firstname: loggedInUser.firstname,
                    lastname: loggedInUser.lastname,
                    note_parent_id: replyState.parentId
                };
                comments = filterComments(comments, newComment, "reply");
                return { ...prev, comments: comments };
            } else {
                let comments = state.comments;
                let checkDate = moment().format("YYYY-MM-DD")
                newList = {
                    note_id: data.id,
                    note: note,
                    date_added: convertLocalToServerTime(new Date()),
                    note_by_id: loggedInUser.client_id,
                    firstname: loggedInUser.firstname,
                    lastname: loggedInUser.lastname,
                };
                let newArray = [];
                let obj;
                let flag = false;
                // eslint-disable-next-line no-unused-expressions
                Object.keys(comments)?.map(objKey => {
                    if (objKey === checkDate) {
                        flag = true;
                        comments[objKey].unshift(newList);
                    }
                })
                let dateAdded = newList.date_added;
                if (!flag) { 
                    newArray.push(newList);
                     obj ={
                        [dateAdded]: newArray
                    }
                }
                return { ...prev, comments: {...obj, ...comments} };
            }
        });
        setComment(INITIAL_COMMENT_STATE);
        setReplyState(INITIAL_REPLY_STATE);
        successToast("Comment is added successfully");
        setDisableBtn(false);
    }

    async function handleDeleteComment(comment) {
        if (!comment) {
            return;
        }
        let obj = {
            id: comment.note_id,
            chat_id: currentSelectedMessage,
        }
        const data = await deleteComment(obj);
        getChatById('noCall', currentSelectedMessage, offset);
        setUpdatedCount(data.count);
        successToast(data.message);
    }

    async function editComment() {
        let note = comment.data;
        let id = comment.id;
        let chatId = currentSelectedMessage;

        if (!note || note === '') {
            return;
        }
        if (disableBtn) {
            return infoToast("A new edit comment request has been already made for this comment");
        }
        infoToast("Creating a new edit comment request");
        setDisableBtn(true);

        let obj = {
            note,
            id,
            chatId
        }
        const data = await editChatComment(obj);
        if (data.status) {
            let comments = state.comments;
            comments = filterComments(comments, { noteId: id, note: note }, "edit");
            setState(prev => ({
                ...prev,
                comments: comments
            }));
            setComment(INITIAL_COMMENT_STATE);
            successToast(data.message ?? "Comment edited successfully");
            getChatInboxMessages();
        }
        else {
            errorToast("Unable to edit comment, try again later");
        }
        setDisableBtn(false);
    }

    function filterComments(comments, newComment, type) {
        let flag = false;
        // eslint-disable-next-line no-unused-expressions
        Object.keys(comments)?.map(objKey => {
            if (type === "reply" && !flag) {
                // eslint-disable-next-line no-unused-expressions
                let result = comments[objKey]?.filter(cmnt => cmnt.note_id === newComment.note_parent_id);
                if (result.length > 0) {
                    flag = true;
                    comments[objKey].unshift(newComment);
                }
            } else if (type === "edit") {
                // eslint-disable-next-line no-unused-expressions
                comments[objKey]?.filter(comment => comment.note_id === newComment.noteId).map(comment => comment.note = newComment.note);
            }
        })
        return comments;
    }

    function loopLoadComments(calledBy = "scrollCalled") {
        getChatById('scrollCalled', currentSelectedMessage, offset);
    }

    const cardClick = () => {
        document.getElementById("chatSectionId").style.display = "block";
    }

    return (
        <Layout className={styles.CardDetailsLayout}>
            <ConfirmationDialog
                onNegative={() => { setDeleteConfirmation({ open: false }) }}
                onPositive={() => handleDeleteComment(deleteConfirmation.comment)}
                confirmationHeader="Confirm"
                confirmationDialogId={"confirm-move"}
                confirmation={deleteConfirmation.open}
                confirmationText={"Are you sure you want to delete this comment?"}
            />
            <Typography.Title className={styles.title}>
                Inbox
            </Typography.Title>
            <div className={styles.wrapper}> 
                <div className={styles.tabsContainer}>
                    <Tabs tabBarStyle={{color: '#ab322d'}} onChange={e => { setStatus(e); setChatTitle(""); setFilterMessages({ message_title: "" }); setOffset(0); setState({ comments: {}, commentsArray: [] });}}>
                        <Tabs.TabPane tab="Active" key="ACTIVE">
                            <MessageList
                                status={status}
                                filterMessages={filterMessages}
                                setFilterMessages={setFilterMessages}
                                setIsModalOpen={setIsModalOpen}
                                messages={messages}
                                isLoading={isLoading}
                                rowsCount={rowsCount}
                                limit={limit}
                                page={page}
                                setPage={setPage}
                                setState={setState}
                                setCurrentSelectedMessage={setCurrentSelectedMessage}
                                getChatById={getChatById}
                                setChatTitle={setChatTitle}
                                setHasMore={setHasMore}
                                setMessages={setMessages}
                                setIsLoading={setIsLoading}
                                getChatInboxMessages={getChatInboxMessages}
                                setAddTitle={setAddTitle}
                                handleInboxMessage={handleInboxMessage}
                                currentSelectedMessage={currentSelectedMessage}
                                updatedCount={updatedCount}
                                cardClick={cardClick}
                                isMobile={isMobile}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Archived" key="RESOLVED">
                            <MessageList
                                status={status}
                                filterMessages={filterMessages}
                                setFilterMessages={setFilterMessages}
                                setIsModalOpen={setIsModalOpen}
                                messages={messages}
                                isLoading={isLoading}
                                rowsCount={rowsCount}
                                limit={limit}
                                page={page}
                                setPage={setPage}
                                setState={setState}
                                setCurrentSelectedMessage={setCurrentSelectedMessage}
                                getChatById={getChatById}
                                setChatTitle={setChatTitle}
                                setHasMore={setHasMore}
                                setMessages={setMessages}
                                setIsLoading={setIsLoading}
                                getChatInboxMessages={getChatInboxMessages}
                                setAddTitle={setAddTitle}
                                handleInboxMessage={handleInboxMessage}
                                currentSelectedMessage={currentSelectedMessage}
                                updatedCount={updatedCount}
                                cardClick={cardClick}
                                isMobile={isMobile}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
                <div id="chatSectionId" className={styles.chatSectionContainer}>
                    <Button type="link" className={`${styles.greyFontColor} ${styles.arrowIconAndTextWrapper} ${styles.deskMode}`} onClick={() => document.getElementById("chatSectionId").style.display = "none"}>
                        <img src={BackArrowIcon} alt="BackArrowIcon" /> Back
                    </Button>
                    <Typography.Title className={styles.title}>
                        {chatTitle}
                    </Typography.Title>
                    {
                        state.commentsArray.length > 0 &&
                            <ChatSection
                                state={state}
                                teamState={[]}
                                chatView={{value: 'inboxChat'}}
                                loopLoadComments={loopLoadComments}
                                mapComments={mapComments}
                                isLoading={isLoading}
                                replyState={replyState}
                                setReplyState={setReplyState}
                                INITIAL_REPLY_STATE={INITIAL_REPLY_STATE}
                                comment={comment}
                                setComment={setComment}
                                video={""}
                                keyPressHandler={keyPressHandler}
                                editComment={editComment}
                                send={send}
                                disableBtn={disableBtn}
                                hasMore={hasMore}
                                chatSectionType={"inboxChat"}
                            />
                    }
                    {messages.length > 0 && state.commentsArray.length === 0 && !isLoading &&
                        <Card className={styles.cards}>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No message selected"} />
                        </Card>
                    }
                </div>
            </div>
            <AddMessageModal
                title="New message"
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                clearFields={clearFields}
                addTitle={addTitle}
                addMessage={addMessage}
                setAddTitle={setAddTitle}
                setAddMessage={setAddMessage}
            />
        </Layout>
    )
}

export default Inbox;
