import React, { useEffect, useState } from 'react';
import { Container, Button } from "react-materialize";
import styles from './YTAnalyticsTable.module.scss';
import { GoogleOAuthProvider } from '@react-oauth/google';
import CustomLoginButton from "../../CustomerLoginButton/CustomLoginButton";
import { Select, Spin } from 'antd';
import { errorToast, successToast } from '../../../api/Util';
import { deleteTokens, getYTAnalytics } from '../../../services/YTTokenService';
import { AiOutlineFall, AiOutlineRise } from 'react-icons/ai';

const YTAnalyticsTable = ({ showGoogleLogin, setShowGoogleLogin, ytAnalyticsData, loggedInUser, customerId }) => {
    const [YTData, setYTData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [selectedDays, setSelectedDays] = useState("7days");
    const options = [
        {
            value: '7days',
            label: 'Time Period: Last 7 Days',
        },
        {
            value: '30days',
            label: 'Time Period: Last 30 days',
        },
        {
            value: '6months',
            label: 'Time Period: Last 6 months',
        },
        {
            value: '9months',
            label: 'Time Period: All (Last 9 months)',
        },
    ];

    const getAnalyticsData = async (value) => {
        setSelectedDays(value);
        setIsLoading(true);
        const response = await getYTAnalytics(value, loggedInUser.client_type === "customer" ? loggedInUser.client_id : customerId);
        if (response.status) {
            setIsLoading(false);
            setYTData(response.data);
        } else {
            setIsLoading(false);
            errorToast(response.error)
        }
    };

    useEffect(() => {
        getAnalyticsData("7days");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInUser, customerId])

    const disconnectLoginSession = async () => {
        setIsLoading(true);
        const response = await deleteTokens();
        if (response.status) {
            setIsLoading(false);
            setYTData({});
            setShowGoogleLogin(true);
            successToast(response.data.message);
        } else {
            setIsLoading(false);
            errorToast(response.error)
        }
    }

    const showGraph = (newData, newDataCount, oldData, oldDataCount) => {
        let newAnalytics = newData/newDataCount;
        let oldAnalytics = oldData/oldDataCount;
        if (isNaN(newAnalytics)) {
            newAnalytics = 0;
        }
        if (isNaN(oldAnalytics)) {
            oldAnalytics = 0;
        }
        
        if (newAnalytics > oldAnalytics) {
            return <AiOutlineRise className={styles.riseGraph} />
        } else if (newAnalytics < oldAnalytics) {
            return <AiOutlineFall className={styles.fallGraph} />
        } else {
            return null;
        }
    }

    return (
        <Container id="customer-tabs-container" className={styles.container}>
            <div className={styles.outerDivWrapper}>
                <div className={isLoading ? styles.overlay : styles.overlayHide}>
                    <Spin className={styles.loader} size="medium" spinning={isLoading} />
                </div>
                <p className={styles.channelStatsHeading}>Channel Stats</p>
                <Select
                    defaultValue="7days"
                    onChange={getAnalyticsData}
                    options={options}
                    disabled={showGoogleLogin}
                    className={styles.selectBox}
                    />
                    <div className={styles.divWrapper}>
                        <ul className={styles.ulHeadings}>
                            <li>Views</li>
                            <li>Subscribers Gained</li>
                            <li>Subscribers Lost</li>
                            <li>Estimated Minutes Watched</li>
                            <li>Average View Duration (In Seconds)</li>
                            <li>Average View Percentage</li>
                        </ul>
                        <div className={styles.iconAndAnalyticsWrapper}>
                            <ul className={styles.ulHeadings}>
                                <li>{YTData?.newAnalytics?.views || 0}</li>
                                <li>{YTData?.newAnalytics?.subscribersGained || 0}</li>
                                <li>{YTData?.newAnalytics?.subscribersLost || 0}</li>
                                <li>{YTData?.newAnalytics?.estimatedMinutesWatched || 0}</li>
                                <li>{((YTData?.newAnalytics?.averageViewDuration/YTData?.newAnalytics?.averageViewDurationCount) || 0).toFixed(2)}</li>
                                <li>{<span>{((YTData?.newAnalytics?.averageViewPercentage/YTData?.newAnalytics?.averageViewPercentageCount) || 0).toFixed(2)}%</span>}</li>
                            </ul>
                            {
                                selectedDays !== "9months" ?
                                    <ul className={styles.ulHeadings}>
                                        <li className={styles.iconAndListIconWrapper}>
                                            {YTData?.oldAnalytics?.views || 0}
                                            {(YTData?.newAnalytics?.views > YTData?.oldAnalytics?.views) ? <AiOutlineRise className={styles.riseGraph} />
                                            : (YTData?.newAnalytics?.views < YTData?.oldAnalytics?.views) ? <AiOutlineFall className={styles.fallGraph} /> : null}
                                        </li>
                                        <li className={styles.iconAndListIconWrapper}>
                                            {YTData?.oldAnalytics?.subscribersGained || 0}
                                            {(YTData?.newAnalytics?.subscribersGained > YTData?.oldAnalytics?.subscribersGained) ? <AiOutlineRise className={styles.riseGraph} />
                                            : (YTData?.newAnalytics?.subscribersGained < YTData?.oldAnalytics?.subscribersGained) ? <AiOutlineFall className={styles.fallGraph} /> : null}
                                        </li>
                                        <li className={styles.iconAndListIconWrapper}>
                                            {YTData?.oldAnalytics?.subscribersLost || 0}
                                            {(YTData?.newAnalytics?.subscribersLost > YTData?.oldAnalytics?.subscribersLost) ? <AiOutlineRise className={styles.riseGraph} />
                                            : (YTData?.newAnalytics?.subscribersLost < YTData?.oldAnalytics?.subscribersLost) ? <AiOutlineFall className={styles.fallGraph} /> : null}
                                        </li>
                                        <li className={styles.iconAndListIconWrapper}>
                                            {YTData?.oldAnalytics?.estimatedMinutesWatched || 0}
                                            {(YTData?.newAnalytics?.estimatedMinutesWatched > YTData?.oldAnalytics?.estimatedMinutesWatched) ? <AiOutlineRise className={styles.riseGraph} />
                                            : (YTData?.newAnalytics?.estimatedMinutesWatched < YTData?.oldAnalytics?.estimatedMinutesWatched) ? <AiOutlineFall className={styles.fallGraph} /> : null}</li>
                                        <li className={styles.iconAndListIconWrapper}>
                                            {((YTData?.oldAnalytics?.averageViewDuration/YTData?.oldAnalytics?.averageViewDurationCount) || 0).toFixed(2)}
                                            {
                                                showGraph(
                                                    YTData?.newAnalytics?.averageViewDuration,
                                                    YTData?.newAnalytics?.averageViewDurationCount,
                                                    YTData?.oldAnalytics?.averageViewDuration,
                                                    YTData?.oldAnalytics?.averageViewDurationCount
                                                    )
                                            }
                                        </li>
                                        
                                        <li className={styles.iconAndListIconWrapper}>
                                            {<span>{((YTData?.oldAnalytics?.averageViewPercentage/YTData?.oldAnalytics?.averageViewPercentageCount) || 0).toFixed(2)}%</span>}
                                            {
                                                showGraph(
                                                    YTData?.newAnalytics?.averageViewPercentage,
                                                    YTData?.newAnalytics?.averageViewPercentageCount,
                                                    YTData?.oldAnalytics?.averageViewPercentage,
                                                    YTData?.oldAnalytics?.averageViewPercentageCount
                                                    )
                                            }
                                        </li>
                                    </ul>
                            : null
                            }
                        </div>
                    </div>
                        {
                            showGoogleLogin && loggedInUser.client_type === "admin" ?
                                <div className={styles.loginButtonDiv}><span>No analytics found as customer not logged in</span></div>
                                    : showGoogleLogin && loggedInUser.client_type === "customer" ?
                                    <div className={styles.loginButtonDiv}>
                                        <span>Sign In to get analytics data</span>
                                        <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
                                            <CustomLoginButton
                                                showGoogleLogin={showGoogleLogin}
                                                setShowGoogleLogin={setShowGoogleLogin}
                                                setYTData={setYTData}
                                                setIsLoading={setIsLoading}
                                                />
                                        </GoogleOAuthProvider>
                                    </div> : loggedInUser.client_type === "customer" ? <div className={styles.disconnectBtnDiv}>
                                                <Button node="button" waves="green" className={`${styles.disconnectButton} btn-primary`} onClick={() => disconnectLoginSession()}>
                                                    Disconnect
                                                </Button>
                                            </div> : null
                        }

            </div>
        </Container>
    );
}

export default YTAnalyticsTable;
